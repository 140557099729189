import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { toggleSidebar, logout, navlink } from "../redux/actions/sidebarActions";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import './logo.scss';
import * as myConstClass from '../constant.js';
import {
  Row,
  Col,
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
  Form,
  Input,
  Button,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBellSlash,
  faBuilding,
  faEnvelopeOpen,
  faComments,
  faChartPie,
  faCogs,
  faCog,
  faArrowAltCircleRight,
  faExclamationTriangle,
  faUser,
  faShoppingCart
} from "@fortawesome/free-solid-svg-icons";
import {
  Translator,
  T,
  TF,
  LanguageList,
  Config
} from "react-translator-component";

Config.list = {
  KH: {
    text: "Khmer",
    file: require("../locale/KH.js")
  },
  en: {
    text: "English",
    file: require("../locale/en.js")

  },
  ch: {
    text: "Chinese",
    file: require("../locale/ch.js")

  },
};
//localStorage.removeItem("lang");
Config.default = localStorage.getItem("lang");

const NavbarDropdown = ({
  children,
  count,
  header,
  footer,
  icon,
  active,
  indicator
}) => (
  <UncontrolledDropdown nav inNavbar className="ml-lg-1" active={active}>
    <DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
      <FontAwesomeIcon icon={icon} className="align-middle" />
      {indicator ? <span className="indicator" /> : ""}
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-lg py-0">
      <div className="dropdown-menu-header position-relative">
        {count} {header}
      </div>
      <ListGroup>{children}</ListGroup>
      <DropdownItem header className="dropdown-menu-footer">
        <span className="text-muted">{footer}</span>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const NavbarDropdownItem = ({ icon, title, description, time, spacing }) => (
  <ListGroupItem>
    <Row noGutters className="align-items-center">
      <Col xs={2}>{icon}</Col>
      <Col xs={10} className={spacing ? "pl-2" : null}>
        <div className="text-dark">{title}</div>
        <div className="text-muted small mt-1">{description}</div>
        <div className="text-muted small mt-1">{time}</div>
      </Col>
    </Row>
  </ListGroupItem>
);

const NavbarToggle = connect(store => ({
  app: store.app
}))(({ dispatch }) => {
  return (
    <span
      className="sidebar-toggle d-flex mr-2"
      onClick={() => {
        dispatch(toggleSidebar());
      }}
    >
      <i className="hamburger align-self-center" />







    </span>
  );
});

const NavbarSearch = connect(store => ({
  sidebar: store.sidebar
}))(({ sidebar }) => {
  return (
    <Form inline>
      {/* <Input
        type="text"
        placeholder="Search projects..."
        aria-label="Search"
        className={classNames(
          "form-control-lite",
          !sidebar.isOnRight || "text-right"
        )}

      /> */}


    </Form>
  );
});






class Navbarnew extends React.Component {
  constructor(props) {
    super(props);
    this.edit = this.edit.bind(this);
    this.googleTranslateElementInit = this.googleTranslateElementInit.bind(this);
    this.state = {};
    this.state = {
      empData: [],
      msgcount: '',
      ordercount: '',
      complaintcount: '',
      leavecount: '',
      documentcount: '',
      pro_package: '',
      unseencount: '',
    };
  }



  componentDidMount() {
    //window.location.reload(false);





    window.googleTranslateElementInit = this.googleTranslateElementInit
    const google = window.google;
    const script = document.createElement("script");
    script.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    script.async = true;
    document.body.appendChild(script);
    var admin_id = localStorage.getItem('uname');
    let login_id = localStorage.getItem('login_id');
    var login_type = localStorage.getItem('login_type');


    //alert(localStorage.getItem("lang"));




















    fetch(myConstClass.BASE_URL + 'user/getunreadunseenCount/' + admin_id + '/' + login_id + '/' + login_type)
      .then(response => response.json())
      .then(response => {

        this.setState({ unseencount: response })
        //localStorage.setItem('mcount',response);
        //lert(response);
      }).catch(err => console.log(err))











    // var mcount= localStorage.getItem('mcount');
    // var ocount= localStorage.getItem('ocount');
    fetch(myConstClass.BASE_URL + 'user/getunreadMessagesCountOld/' + admin_id + '/' + login_id + '/' + login_type)
      .then(response => response.json())
      .then(response => {

        //localStorage.setItem('mcount',response);
        this.setState({ msgcount: response })

      }).catch(err => console.log(err))





    fetch(myConstClass.BASE_URL + 'user/getunreadOrderCount/' + admin_id + '/' + login_type)
      .then(response => response.json())
      .then(response => {

        //const notifications = [];
        //this.setState({leave_types: response})

        //alert(response);
        //alert(response);

        this.setState({ ordercount: response })
        //localStorage.setItem('ocount',response);
        //alert(mcount);
        /*    toastr.info(
                            'Message',
                            "You have a new message",
                            this.options
                          ) */



      }).catch(err => console.log(err))
    fetch(myConstClass.BASE_URL + 'user/getunreadComplaintCount/' + admin_id + '/' + login_id + '/' + login_type)
      .then(response => response.json())
      .then(response => {

        this.setState({ complaintcount: response })
        //localStorage.setItem('mcount',response);
        //lert(response);
      }).catch(err => console.log(err))


    //this.interval = setInterval(() => this.tick(), 1000);



    fetch(myConstClass.BASE_URL + 'user/getunreadLeaveCount/' + admin_id + '/' + login_id + '/' + login_type)
      .then(response => response.json())
      .then(response => {
        this.setState({ leavecount: response })
      }).catch(err => console.log(err))


    fetch(myConstClass.BASE_URL + 'user/getunreadDocumentCount/' + admin_id + '/' + login_id + '/' + login_type)
      .then(response => response.json())
      .then(response => {
        this.setState({ documentcount: response })


      }).catch(err => console.log(err))



  }


  tick() {

    // alert('hii');
    var admin_id = localStorage.getItem('uname');
    var login_type = localStorage.getItem('login_type');
    var login_id = localStorage.getItem('login_id');


    fetch(myConstClass.BASE_URL + 'user/getunreadunseenCount/' + admin_id + '/' + login_id + '/' + login_type)
      .then(response => response.json())
      .then(response => {

        this.setState({ unseencount: response })
        //localStorage.setItem('mcount',response);
        //lert(response);
      }).catch(err => console.log(err))










    fetch(myConstClass.BASE_URL + 'user/getunreadMessagesCountOld/' + admin_id + '/' + login_id + '/' + login_type)
      .then(response => response.json())
      .then(response => {

        this.setState({ msgcount: response })
        //localStorage.setItem('mcount',response);
        //lert(response);
      }).catch(err => console.log(err))


    fetch(myConstClass.BASE_URL + 'user/getunreadComplaintCount/' + admin_id + '/' + login_id + '/' + login_type)
      .then(response => response.json())
      .then(response => {

        this.setState({ complaintcount: response })
        //localStorage.setItem('mcount',response);
        //lert(response);
      }).catch(err => console.log(err))




    fetch(myConstClass.BASE_URL + 'user/getunreadLeaveCount/' + admin_id + '/' + login_id + '/' + login_type)
      .then(response => response.json())
      .then(response => {

        this.setState({ leavecount: response })
        //localStorage.setItem('mcount',response);
        //lert(response);
      }).catch(err => console.log(err))











    fetch(myConstClass.BASE_URL + 'user/getunreadOrderCount/' + admin_id + '/' + login_type)
      .then(response => response.json())
      .then(response => {

        //const notifications = [];
        //this.setState({leave_types: response})

        //alert(response);
        //alert(response);

        this.setState({ ordercount: response })
        //localStorage.setItem('ocount',response);
        //alert(mcount);
        /*    toastr.info(
                           'Message',
                           "You have a new message",
                           this.options
                         ) */



      }).catch(err => console.log(err))










  }

  googleTranslateElementInit() {
    /* eslint-disable no-new */
    const google = window.google;
    // new google.translate.TranslateElement({pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.SIMPLE, autoDisplay: false, multilanguagePage: true}, 'google_translate_element');
    new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_elementnav');
    new window.google.translate.TranslateElement({ pageLanguage: 'pt', layout: google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_elementnav')
  }

  change_dashboard(type) {
    let file_ten_path;

    //alert('hii');



    localStorage.setItem('login_type', 'sub_admin');
    localStorage.setItem('is_subadmin', '0');
    if (type == '1') {
      localStorage.setItem('is_sub_admin_with_payroll', '1');
    }
    else {
      localStorage.setItem('is_sub_admin_with_payroll', '2');
    }

    // var tenant_url = localStorage.getItem('url'); 
    // file_ten_path = tenant_url+'dashboard';
    // window.location.href = file_ten_path; 

    window.location.href = '/' + myConstClass.TENANT_PAGE_PATH + 'dashboard';

  }






  edit(e) {
    //alert(e);
    var add_msg = localStorage.getItem('view_msg');
    var login_type = localStorage.getItem('login_type');

    if (e == 'complaint') {

      var empleave_add = localStorage.getItem('comnot');


      if (empleave_add == '0' &&  login_type!=='hr_admin' || empleave_add == null && login_type!=='hr_admin' || login_type=='emp' || login_type=='security') {
        // alert('hii')
        toastr.error(
          TF("{0}", T("Error")),
          add_msg,
        )
        return;
      }

      fetch(myConstClass.BASE_URL + 'user/getunreadunseenCountcomplaint')
        .then(response => response.json())
        .then(response => {

        }).catch(err => console.log(err))
      window.location.href = '/' + myConstClass.TENANT_PAGE_PATH + 'complaints/listing';

    }

    if (e == 'document' ) {

      var empleave_add = localStorage.getItem('docnot');


      if (empleave_add == '0'  && login_type!=='hr_admin' || empleave_add == null && login_type!=='hr_admin'  || login_type=='emp' || login_type=='security') {
        // alert('hii')
        toastr.error(
          TF("{0}", T("Error")),
          add_msg,
        )
        return;
      }

      fetch(myConstClass.BASE_URL + 'user/getunreadDocumentCount')
        .then(response => response.json())
        .then(response => {

        }).catch(err => console.log(err))
      window.location.href = '/' + myConstClass.TENANT_PAGE_PATH + 'admin_staff/listingdocs';

    }


    if (e == 'msg') {

      var empleavenot = localStorage.getItem('msgnot');
      //alert(empleave_add);
      if (empleavenot == '0' && login_type!=='hr_admin' || empleavenot == null && login_type!=='hr_admin' || login_type=='emp' || login_type=='security') {
        // alert('hii')
        toastr.error(
          TF("{0}", T("Error")),
          add_msg,
        )
        return;
      }





      fetch(myConstClass.BASE_URL + 'user/getunreadunseenCountmsg')
        .then(response => response.json())
        .then(response => {

        }).catch(err => console.log(err))
      window.location.href = '/' + myConstClass.TENANT_PAGE_PATH + 'empmsg/listing';
    }

    if (e == 'order') {

      var empleavenot = localStorage.getItem('ordernot');
      //alert(empleave_add);
      if (empleavenot == '0' && login_type!=='hr_admin' || empleavenot == null && login_type!=='hr_admin' || login_type=='emp' || login_type=='security') {
        // alert('hii')
        toastr.error(
          TF("{0}", T("Error")),
          add_msg,
        )
        return;
      }







      window.location.href = '/' + myConstClass.TENANT_PAGE_PATH + 'order/listing';
    }

    if (e == 'leave') {


      var empleavenot = localStorage.getItem('leavenot');
      //alert(empleave_add);
      if (empleavenot == '0' && login_type!=='hr_admin' || empleavenot == null && login_type!=='hr_admin' || login_type=='emp' || login_type=='security') {
        // alert('hii')
        toastr.error(
          TF("{0}", T("Error")),
          add_msg,
        )
        return;
      }

      fetch(myConstClass.BASE_URL + 'user/getunreadunseenCountleave')
        .then(response => response.json())
        .then(response => {

        }).catch(err => console.log(err))











      window.location.href = '/' + myConstClass.TENANT_PAGE_PATH + 'leaves/listing';
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { sidebar, pro_package } = this.props;
    const { msgcount, ordercount, complaintcount } = this.state;
    var msg_count;
    var admin_id = localStorage.getItem('uname');
    let login_id = localStorage.getItem('login_id');
    var login_type = localStorage.getItem('login_type');
    var is_google_transaltor = localStorage.getItem('is_google_transaltor');
    var is_subadmin = localStorage.getItem('is_subadmin');
    var is_payroll_privilage = localStorage.getItem('is_payroll_privilage');
    var mcount = localStorage.getItem('mcount');
    var ocount = localStorage.getItem('ocount');
    //alert(is_google_transaltor);
    let balance_div_urlnew;
    if (is_subadmin == '1' && login_type == 'emp') {
      //  balance_div_urlnew=<a style={{margin:"0rem 1.50rem 1.50rem 0rem",border: "#6f42c1 1px solid",color: "#6f42c1",padding: "0.10rem 0.90rem 0.30rem 0.90rem",textDecoration: 'none',borderRadius:'0.2rem',cursor:'Pointer'}} class="mr-1 mb-1 btn btn-outline-secondary" onClick={() =>this.change_dashboard()}  >Login</a>;




      balance_div_urlnew = <Button className="button_change_dashboard" onClick={() => this.change_dashboard(1)}  >{TF("{0}", T("Change"))} {TF("{0}", T("Dashboard"))}
      </Button>;

    }
    if (is_payroll_privilage != '0' && login_type == 'emp') {
      //  balance_div_urlnew=<a style={{margin:"0rem 1.50rem 1.50rem 0rem",border: "#6f42c1 1px solid",color: "#6f42c1",padding: "0.10rem 0.90rem 0.30rem 0.90rem",textDecoration: 'none',borderRadius:'0.2rem',cursor:'Pointer'}} class="mr-1 mb-1 btn btn-outline-secondary" onClick={() =>this.change_dashboard()}  >Login</a>;


      //var is_subadmin = localStorage.setItem('is_subadmin', '1');

      balance_div_urlnew = <Button className="button_change_dashboard" onClick={() => this.change_dashboard(2)}  >{TF("{0}", T("Change"))} {TF("{0}", T("Dashboard"))}
      </Button>;







    }
    else {
      balance_div_urlnew = '';
    }



    let admin_path, tenpath;
    if (login_type == 'hr_admin') {
      tenpath = 'myprofile/Add';
    } else {
      tenpath = 'employee/add';
    }


    admin_path = '/';

    return (
      <Navbar expand className="navbar-theme">
        {!sidebar.isOnRight ? (
          <React.Fragment>
            <NavbarToggle />

            <NavbarSearch />

            <Collapse navbar>

              <Nav className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"} navbar>

                {balance_div_urlnew}
                {(is_google_transaltor == '1') ?

                  <div id="google_translate_elementnav"></div>
                  :
                  <div>
                    <LanguageList Language={localStorage.getItem("lang")} style={{ display: 'none' }} />
                    <label style={{ color: 'white' }}>{TF("{0}", T("Select"))} {TF("{0}", T("Language"))}</label> <select //style={{margintop:'10px'}}
                      className="custom-language-list"
                      value={localStorage.getItem("lang")}
                      // onChange={this.changelang}
                      onChange={


                        e => {
                          localStorage.setItem('lang', e.target.value);

                          localStorage.setItem('change', true);

















                          // alert(e.target.value);
                          this.setState({ language: e.target.value })
                          // this.setState({ change: true})

                          window.location.reload();

                          //window.location.reload();




                          if (localStorage.getItem("change") == true) {
                            //alert();
                            //  if( !localStorage.getItem('firstLoad') )
                            //  {
                            //  localStorage['firstLoad'] = true;
                            window.location.reload();
                            localStorage.setItem('change', false);
                          }
                          //    else

                          //      localStorage.removeItem('firstLoad');
                          //  }







                          // window.location.reload(false);
                          //window.location.reload(false);

                        }

                      }
                    >
                      {Object.keys(Config.list).map(key => (
                        <option key={key} value={key}>
                          {Config.list[key].text}
                        </option>
                      ))}
                    </select>

                  </div>
                }

                <UncontrolledDropdown nav inNavbar className="ml-lg-1">

                  {/* <div  id="google_translate_element"></div> */}
                  <DropdownToggle nav caret>

                    <FontAwesomeIcon icon={faBell} className="align-middle" />
                    {/* <span class="indicator"></span> */}
                    {/* <span class="float-right badge badge-error"> </span> */}
                    {/* {this.state.complaintcount != 0 && this.state.complaintcount || this.state.msgcount != 0 && this.state.msgcount || this.state.leavecount != 0 && this.state.leavecount != null ? */}


                    {this.state.unseencount.length != 0 ?
                      // <span class="indicatorno">
                      <span class="float-right badge badge-error">


                        {this.state.unseencount.length}


                      </span>


                      // </span>
                      : ''}

                  </DropdownToggle>
                  <DropdownMenu right>

                    {this.state.documentcount != 0 && this.state.documentcount != null && login_type == 'hr_admin' ||
                      this.state.documentcount != 0 && this.state.documentcount != null && login_type == 'sub_admin'
                      ? (
                        <Link >
                          <DropdownItem onClick={() => {
                            this.edit('document');
                          }}>
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              fixedWidth
                              className="mr-2 align-middle"
                            />
                            {this.state.documentcount} {T("Documents Expired in 30 days")}
                          </DropdownItem>
                        </Link>
                      ) :
                      <Link >
                        <DropdownItem onClick={() => {
                          this.edit('document');
                        }}>
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          {T("No")} {T("Documents Expired in 30 days")}
                        </DropdownItem>
                      </Link>
                    }





























                    {this.state.documentcount != 0 && this.state.documentcount != null && login_type == 'hr_admin' ||
                      this.state.documentcount != 0 && this.state.documentcount != null && login_type == 'sub_admin' ? (
                      <Link >
                        <DropdownItem onClick={() => {
                          this.edit('complaint');
                        }}>
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          {this.state.complaintcount} New Complaints
                        </DropdownItem>
                      </Link>
                    ) :
                      <Link >
                        <DropdownItem onClick={() => {
                          this.edit('complaint');
                        }}>
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          No New Complaints
                        </DropdownItem>
                      </Link>
                    }



                    {this.state.msgcount != 0 && this.state.msgcount != null && login_type == 'hr_admin' ||
                      this.state.documentcount != 0 && this.state.documentcount != null && login_type == 'sub_admin' ? (
                      <Link >
                        <DropdownItem onClick={() => {
                          this.edit('msg');
                        }}>
                          <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          {this.state.msgcount} New Messages Arrived
                        </DropdownItem>
                      </Link>
                    ) :
                      <Link >
                        <DropdownItem onClick={() => {
                          this.edit('msg');
                        }}>
                          <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          No Messages Arrived
                        </DropdownItem>
                      </Link>
                    }


                    {this.state.leavecount != 0 && this.state.leavecount != null && login_type == 'hr_admin' ||
                      this.state.documentcount != 0 && this.state.documentcount != null && login_type == 'sub_admin' ? (
                      <Link >
                        <DropdownItem onClick={() => {
                          this.edit('leave');
                        }}>
                          <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          {this.state.leavecount}  applied Leaves today
                        </DropdownItem>
                      </Link>
                    ) :
                      <Link >
                        <DropdownItem onClick={() => {
                          this.edit('leave');
                        }}>
                          <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          No applied Leaves today
                        </DropdownItem>
                      </Link>
                    }









                  </DropdownMenu>
                </UncontrolledDropdown>





















                {/* {this.state.complaintcount != 0 && this.state.complaintcount != null && login_type == 'hr_admin' ? (

<UncontrolledDropdown nav inNavbar className="ml-lg-1" >
<DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
  <FontAwesomeIcon icon={faExclamationTriangle} className="align-middle" onClick={() =>this.edit('complaint')} />
          <span class="float-right badge badge-error">
      
          {this.state.complaintcount}</span>
</DropdownToggle>

</UncontrolledDropdown>
 
    ):this.state.complaintcount == 0 || this.state.complaintcount == null && login_type == 'hr_admin'?(

<UncontrolledDropdown nav inNavbar className="ml-lg-1" >
<DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
  <FontAwesomeIcon icon={faExclamationTriangle} className="align-middle" onClick={() =>this.edit('complaint')} />
         
</DropdownToggle>

</UncontrolledDropdown>


    ):''
}

{this.state.msgcount != 0 && this.state.msgcount != null && login_type == 'hr_admin' ? (
  <UncontrolledDropdown nav inNavbar className="ml-lg-1" >
<DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
  <FontAwesomeIcon icon={faEnvelopeOpen} className="align-middle" onClick={() =>this.edit('msg')} />
          <span class="float-right badge badge-error">
      
          {this.state.msgcount}</span>
</DropdownToggle>

</UncontrolledDropdown>
 ):this.state.msgcount == 0 || this.state.msgcount == null && login_type == 'hr_admin'?(
<UncontrolledDropdown nav inNavbar className="ml-lg-1" >
<DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
  <FontAwesomeIcon icon={faEnvelopeOpen} className="align-middle" onClick={() =>this.edit('msg')} />
          
</DropdownToggle>

</UncontrolledDropdown>

):''
} */}

                {this.state.ordercount != 0 && this.state.ordercount != null && login_type == 'hr_admin' ? (
                  <UncontrolledDropdown nav inNavbar className="ml-lg-1" >
                    <DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
                      <FontAwesomeIcon icon={faShoppingCart} className="align-middle" onClick={() => this.edit('order')} />
                      <span class="float-right badge badge-error">

                        {this.state.ordercount}</span>
                    </DropdownToggle>

                  </UncontrolledDropdown>
                ) : this.state.ordercount == 0 || this.state.ordercount == null && login_type == 'hr_admin' ? (
                  <UncontrolledDropdown nav inNavbar className="ml-lg-1" >
                    <DropdownToggle nav className="nav-icon dropdown-toggle position-relative">
                      <FontAwesomeIcon icon={faShoppingCart} className="align-middle" onClick={() => this.edit('order')} />

                    </DropdownToggle>

                  </UncontrolledDropdown>
                ) : ''
                }



                {/* for google translator */}

































                <UncontrolledDropdown nav inNavbar className="ml-lg-1">

                  {/* <div  id="google_translate_element"></div> */}
                  <DropdownToggle nav caret>

                    <FontAwesomeIcon icon={faCog} className="align-middle" />
                  </DropdownToggle>
                  <DropdownMenu right>

                    {login_type == 'hr_admin' ? (


                      // if(login_type == 'hr_admin'){
                      <Link to={{
                        pathname: '/' + myConstClass.TENANT_PAGE_PATH + 'myprofile/Add',
                        state: {
                          _id: { admin_id },
                        }
                      }} >  <DropdownItem  >
                          <FontAwesomeIcon
                            icon={faArrowAltCircleRight}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          {T("My Profile")}
                        </DropdownItem>
                      </Link>
                    ) : (
                      <Link to={{
                        pathname: '/' + myConstClass.TENANT_PAGE_PATH + 'employee/profile',
                        state: {
                          _id: { login_id },
                        }
                      }}>  <DropdownItem  >
                          <FontAwesomeIcon
                            icon={faArrowAltCircleRight}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          {T("My Profile")}
                        </DropdownItem>
                      </Link>
                    )}

                    <Link to={admin_path}>
                      <DropdownItem onClick={() => {
                        logout();
                      }}>
                        <FontAwesomeIcon
                          icon={faArrowAltCircleRight}
                          fixedWidth
                          className="mr-2 align-middle"
                        />

                        {T("Sign out")}
                      </DropdownItem>
                    </Link>



                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>

          </React.Fragment>
        ) : (
          <React.Fragment>
            <Collapse navbar>
              <Nav className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"} navbar>

                {mcount != 0 && mcount != null && login_type == 'hr_admin' ? (

                  <NavbarDropdown
                    //header="New Messages"
                    // footer="Show all messages"
                    icon={faEnvelopeOpen}
                    // count={mcount}
                    // style={{'marginBottom':'5px'}}
                    indicator
                    active
                  >


                    {/* {messages.map((item, key) => {
        return (
          <NavbarDropdownItem
            key={key}
            icon={
              <img
                className="avatar img-fluid rounded-circle"
                src={item.avatar}
                alt={item.name}
              />
            }
            title={item.name}
            description={item.description}
            time={item.time}
            spacing
          />
        );
      })}*/}
                  </NavbarDropdown>) : mcount == 0 || mcount == null && login_type == 'hr_admin' ? (
                    <NavbarDropdown
                      //  header="New Messages"
                      // footer="Show all messages"
                      icon={faEnvelopeOpen}
                      active
                    // style={{'marginBottom':'5px'}}
                    >
                      {/* {messages.map((item, key) => {
        return (
          <NavbarDropdownItem
            key={key}
            icon={
              <img
                className="avatar img-fluid rounded-circle"
                src={item.avatar}
                alt={item.name}
              />
            }
            title={item.name}
            description={item.description}
            time={item.time}
            spacing
          />
        );
      })}*/}
                    </NavbarDropdown>

                  ) : ''
                }
                {ocount != 0 && ocount != null && login_type == 'hr_admin' ? (
                  <NavbarDropdown
                    //header="New Orders"
                    // footer="Show all Orders"
                    icon={faBell}
                    //count={ocount}
                    indicator

                  >
                    {/* {notifications.map((item, key) => {
        let icon = (
          <FontAwesomeIcon icon={faEnvelopeOpen} className="text-warning" />
        );

        if (item.type === "important") {
          icon = <FontAwesomeIcon icon={faBell} className="text-danger" />;
        }

        if (item.type === "login") {
          icon = (
            <FontAwesomeIcon icon={faBuilding} className="text-primary" />
          );
        }

        if (item.type === "request") {
          icon = (
            <FontAwesomeIcon icon={faBellSlash} className="text-success" />
          );
        }

        return (
          <NavbarDropdownItem
            key={key}
            icon={icon}
            title={item.title}
            description={item.description}
            time={item.time}
          />
        );
      })}*/}
                  </NavbarDropdown>) : ocount == 0 || ocount == null && login_type == 'hr_admin' ? (

                    <NavbarDropdown
                      // header="New Orders"

                      icon={faBell}
                      // count={ocount}
                      active
                    >
                      {/* {notifications.map((item, key) => {
        let icon = (
          <FontAwesomeIcon icon={faEnvelopeOpen} className="text-warning" />
        );

        if (item.type === "important") {
          icon = <FontAwesomeIcon icon={faBell} className="text-danger" />;
        }

        if (item.type === "login") {
          icon = (
            <FontAwesomeIcon icon={faBuilding} className="text-primary" />
          );
        }

        if (item.type === "request") {
          icon = (
            <FontAwesomeIcon icon={faBellSlash} className="text-success" />
          );
        }

        return (
          <NavbarDropdownItem
            key={key}
            icon={icon}
            title={item.title}
            description={item.description}
            time={item.time}
          />
        );
      })}*/}
                    </NavbarDropdown>














                  ) : ''
                }



                {/* for google translator */}


                <UncontrolledDropdown nav inNavbar className="ml-lg-1">
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon icon={faCog} className="align-middle" />
                  </DropdownToggle>
                  <DropdownMenu right>

                    {login_type == 'hr_admin' ? (
                      // if(login_type == 'hr_admin'){
                      <Link to={{
                        pathname: '/' + myConstClass.TENANT_PAGE_PATH + 'myprofile/Add',
                        state: {
                          _id: { admin_id },
                        }
                      }} >  <DropdownItem  >
                          <FontAwesomeIcon
                            icon={faArrowAltCircleRight}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          {T("My Profile")}

                        </DropdownItem>
                      </Link>
                    ) : (
                      <Link to={{
                        pathname: '/' + myConstClass.TENANT_PAGE_PATH + 'employee/profile',
                        state: {
                          _id: { login_id },
                        }
                      }}>  <DropdownItem  >
                          <FontAwesomeIcon
                            icon={faArrowAltCircleRight}
                            fixedWidth
                            className="mr-2 align-middle"
                          />
                          {T("My Profile")}
                        </DropdownItem>
                      </Link>
                    )}

                    <Link to={admin_path}>
                      <DropdownItem onClick={() => {
                        logout();
                      }}>
                        <FontAwesomeIcon
                          icon={faArrowAltCircleRight}
                          fixedWidth
                          className="mr-2 align-middle"
                        />
                        {T("Sign out")}
                      </DropdownItem>
                    </Link>



                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>

            <NavbarSearch />
            <NavbarToggle />
            {/* <div  id="google_translate_element"></div> */}
          </React.Fragment>
        )}
      </Navbar>
    );








  }
}






















// const NavbarComponent = ({ sidebar }) => {
//   return (
//     <Navbar expand className="navbar-theme">
//       {!sidebar.isOnRight ? (
//         <React.Fragment>
//           <NavbarToggle />
//           <NavbarSearch />
//           <NavbarDropdowns />
//         </React.Fragment>
//       ) : (
//         <React.Fragment>
//           <NavbarDropdowns />
//           <NavbarSearch />
//           <NavbarToggle />
//         </React.Fragment>
//       )}
//     </Navbar>
//   );
// };

export default connect(store => ({
  sidebar: store.sidebar
}))(Navbarnew);
