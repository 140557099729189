import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Badge, Collapse } from "reactstrap";
import * as myConstClass from '../constant.js';
import IosPulse from "react-ionicons/lib/IosPulse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from 'jquery';
import routes from "../routes/index";
import './logo.scss';
import {
  Translator,
  T,
  TF,
  LanguageList,
  Config
} from "react-translator-component";


// import avatar from "../assets/img/avatars/avatar.jpg";
// const avatar = `http://localhost/workzone/backend/img/profile.png`; 
// const logo = `http://localhost/workzone/backend/img/clock_new.png`;
// const zone_logo = `http://localhost/workzone/backend/img/Work-Zone-Plus-logo.png`;

// const avatar = `https://alpha.workzoneplus.com/backend/img/profile.png`; 
// const logo = `https://alpha.workzoneplus.com/backend/img/clock_new.png`;
// const zone_logo = `https://alpha.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;

const logo = `https://beta.workzoneplus.com/backend/img/clock_new.png`;

//localStorage.clear("lang");
Config.default = localStorage.getItem("lang");
Config.list = {
  default: localStorage.getItem("lang"),
  KH: {
    text: "Khemer",
    file: require("../locale/KH.js")
  },
  en: {
    text: "English",
    file: require("../locale/en.js")

  },
  ch: {
    text: "Chinese",
    file: require("../locale/ch.js")

  },
};

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon,
    isOpen,
    children,
    onClick,
    location,
    to
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <FontAwesomeIcon
            icon={icon}
            fixedWidth
            className="align-middle mr-2"
          />{" "}
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {icon ? (
            <React.Fragment>
              <FontAwesomeIcon
                icon={icon}
                fixedWidth
                className="align-middle mr-2"
              />{" "}
              <span className="align-middle">{name}</span>
            </React.Fragment>
          ) : (
            name
          )}{" "}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} pill className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state = {
      empData: [],
      user_id: '',
    };
  }

  toggle = index => {
    // Collapse all elements


    //alert('hiii');
    var login_id = localStorage.getItem('login_id');
    var login_type = localStorage.getItem('login_type');
    var pro_package = localStorage.getItem('package');
    var is_super_user = localStorage.getItem('is_super_user');
    this.state.pro_package = pro_package;

    var pro_zone_logo_logo;
    if (pro_package == '1') {
      pro_zone_logo_logo = myConstClass.SITE_URL + `img/Work-Zone-Pro-logo11.png`;
      $("#pro_logo").attr("src", pro_zone_logo_logo);
    }
    else {
      pro_zone_logo_logo = myConstClass.SITE_URL + `img/Work-Zone-Plus-logo.png`;
      $("#pro_logo").attr("src", pro_zone_logo_logo);
    }




















    // alert(pro_package);
    if (login_type == 'hr_admin' && is_super_user == '0' || login_type == 'super_admin') {
      fetch(myConstClass.BASE_URL + 'user/getProfileInfo/' + login_id)
        .then(response => response.json())
        .then(response => { console.log(response); this.setState({ empData: response }); })
        //  alert(empData);
        .catch(err => console.log(err))
    }
    else if (login_type == 'sub_admin' && is_super_user != '0') {
      fetch(myConstClass.BASE_URL + 'user/getSuperAdminStaffInfo/' + is_super_user)
        .then(response => response.json())
        .then(response => { console.log(response); this.setState({ empData: response }); })
        //  alert(empData);
        .catch(err => console.log(err))
    }


    else {
      fetch(myConstClass.BASE_URL + 'user/getUserInfoE/' + login_id)
        .then(response => response.json())
        .then(response => { console.log(response); this.setState({ empData: response }); })
        //  alert(empData);
        .catch(err => console.log(err))


    }






    // if(pathName==ten_path+'/dashboard')
    // {
    //  const isActive = false;
    // // const isOpen = route.open;
    // // const isHome = route.containsHome && pathName === "/" ? true : false;
    // /// alert(isActive);
    //  this.setState(() => ({
    //    [index]: isActive //|| isOpen  // || isHome
    //  }));
    // }
    // let ten_path;
    // var url = window.location.pathname;

    // var str = url.split("/");
    // ten_path = '/'+str[1]+'/'+ str[2]; 
    // //alert(ten_path+'/guests');
    //  /* Open collapse element that matches current url */
    //  const pathName = this.props.location.pathname;



    // if(pathName==ten_path+'/dashboard')
    // {
    //  alert('hii');
    //  const isActive = false;
    // // const isOpen = route.open;
    // // const isHome = route.containsHome && pathName === "/" ? true : false;
    // /// alert(isActive);
    //  this.setState(() => ({
    //    [index]: isActive //|| isOpen  // || isHome
    //  }));
    // }

    Object.keys(this.state).forEach(
      item =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false
        }))
    );

    // Toggle selected element
    this.setState(state => ({
      [index]: !state[index]
    }));
  };

  componentWillMount() {
    var pro_package = localStorage.getItem('package');
    this.state.pro_package = pro_package;
    //var pro_package= localStorage.getItem('package');
    // var is_super_user= localStorage.getItem('is_super_user');
    // this.state.pro_package = pro_package;
    //alert(TF("{0}",T("Language")));























    var pro_zone_logo_logo;
    if (pro_package == '1') {
      pro_zone_logo_logo = myConstClass.SITE_URL + `img/Work-Zone-Pro-logo11.png`;
      $("#pro_logo").attr("src", pro_zone_logo_logo);
    }
    else {
      pro_zone_logo_logo = myConstClass.SITE_URL + `img/Work-Zone-Plus-logo.png`;
      $("#pro_logo").attr("src", pro_zone_logo_logo);
    }
    // alert('hii');
    let ten_path;
    var url = window.location.pathname;

    var str = url.split("/");
    ten_path = '/' + str[1] + '/' + str[2];
    //alert(ten_path+'/guests');
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;
    // alert(pathName);

    routes.forEach((route, index) => {
      //  route.chidren;

      //  alert(route);
      //    alert(route.open);






      route.name = T(route.name);









      if (route.path == ten_path + '/guests') {
        for (let i = 0; i < route.children.length; i++) {
          var child = route.children[i];
          child.name = T(child.name);

        }
        if (pathName.indexOf("guests") > -1 || pathName.indexOf("incident") > -1)
        //if(pathName==ten_path+'/guests/*' || pathName==ten_path+'/incident/*')
        {

          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen  // || isHome
          }));
        }
      }


      if (route.path == ten_path + '/employees') {

        for (let i = 0; i < route.children.length; i++) {
          var child = route.children[i];
          child.name = T(child.name);

        }

      }

      if (route.path == ten_path + '/projects') {

        for (let i = 0; i < route.children.length; i++) {
          var child = route.children[i];
          child.name = T(child.name);

        }
        //route.chidren.name=TF("{0}",T(route.chidren.name));
        // alert('hii');
        // alert(pathName.indexOf("employee/"));

        // if(pathName.indexOf("employee") > -1)
        //   {
        //     alert(pathName.indexOf("employee/"));
        //   }
        //  if(pathName==ten_path+'/leave_type/listing' || pathName==ten_path+'/leaves/listing')
        if (pathName.indexOf("employee/") > -1 || pathName.indexOf("complaints") > -1
          
          || pathName.indexOf("admin_staff") > -1
        ) {

          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          //const isHome = route.containsHome && pathName === "/" ? true : false;
          /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
        }
      }
      //alert(route.path);

      //  if(route.path==ten_path+'/employees')
      //  {
      //   // alert('hii');
      //      if(pathName==ten_path+'/employee/listing' || pathName==ten_path+'/employee_groups/listing' || pathName==ten_path+'/complaints/listing')
      //      {

      //       //alert('hii1');
      //       //const isActive = pathName.indexOf(route.path) === 0;
      //       const isActive = true;
      //       const isOpen = route.open;
      //      // const isHome = route.containsHome && pathName === "/" ? true : false;
      //      /// alert(isActive);
      //       this.setState(() => ({
      //         [index]: isActive || isOpen //|| isHome
      //       }));
      //      }
      //  }

      //  if(route.path==ten_path+'/admin_staff')
      //  {
      //  //  alert(ten_path+'/guests');
      //      if(pathName==ten_path+'/admin_staff/listing' || pathName==ten_path+'/admin_staff/listingsecurity')
      //      {

      //       //alert('hii1');
      //       //const isActive = pathName.indexOf(route.path) === 0;
      //       const isActive = true;
      //       const isOpen = route.open;
      //      // const isHome = route.containsHome && pathName === "/" ? true : false;
      //      /// alert(isActive);
      //       this.setState(() => ({
      //         [index]: isActive || isOpen  // || isHome
      //       }));
      //      }
      //  }


      if (route.path == ten_path + '/settings') {
        // alert('hii');
        for (let i = 0; i < route.children.length; i++) {
          var child = route.children[i];
          child.name = T(child.name);

        }

        if (pathName.indexOf("organization") > -1 || pathName.indexOf("department") > -1 || pathName.indexOf("holidays") > -1
          || pathName.indexOf("points") > -1 || pathName.indexOf("saroles") > -1
          || pathName.indexOf("leave_type") > -1 || pathName.indexOf("adminapp") > -1 || pathName.indexOf("assets") > -1
          || pathName.indexOf("allownce_type") > -1 || pathName.indexOf("allownce_code") > -1 || pathName.indexOf("note_code") > -1
          || pathName.indexOf("document_type") > -1 || pathName.indexOf("employee_groups") > -1
        )

        //   if(pathName==ten_path+'/organization/listing' || pathName==ten_path+'/department/listing' || pathName==ten_path+'/holidays/listing'
        //  || pathName==ten_path+'/points/listing'|| pathName==ten_path+'/job_descripation/listing' || pathName==ten_path+'/saroles/listing'
        //  )
        {

          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
        }
      }




      if (route.path == ten_path + '/payroll') {
        // alert('hii');
        for (let i = 0; i < route.children.length; i++) {
          var child = route.children[i];
          child.name = T(child.name);

        }
        if (pathName.indexOf("tax_brackets") > -1 || pathName.indexOf("payroll_line_entry") > -1 || pathName.indexOf("payslip") > -1
          || pathName.indexOf("paycode") > -1
        )

        //   if(pathName==ten_path+'/organization/listing' || pathName==ten_path+'/department/listing' || pathName==ten_path+'/holidays/listing'
        //  || pathName==ten_path+'/points/listing'|| pathName==ten_path+'/job_descripation/listing' || pathName==ten_path+'/saroles/listing'
        //  )
        {

          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
        }
      }




      if (route.path == ten_path + '/reports') {
        // alert('hii');
        for (let i = 0; i < route.children.length; i++) {
          var child = route.children[i];
          child.name = T(child.name);

        }
        if (pathName.indexOf("reports") > -1
        )

        //   if(pathName==ten_path+'/organization/listing' || pathName==ten_path+'/department/listing' || pathName==ten_path+'/holidays/listing'
        //  || pathName==ten_path+'/points/listing'|| pathName==ten_path+'/job_descripation/listing' || pathName==ten_path+'/saroles/listing'
        //  )
        {

          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
        }
      }









      if (route.path == ten_path + '/career') {
        for (let i = 0; i < route.children.length; i++) {
          var child = route.children[i];
          child.name = T(child.name);

        }
        if (pathName.indexOf("jobpost") > -1 || pathName.indexOf("careers") > -1 ||
          pathName.indexOf("jobdisclouser") > -1 || pathName.indexOf("job_descripation") > -1 || pathName.indexOf("job_type") > -1
          || pathName.indexOf("job_title") > -1 || pathName.indexOf("job_id") > -1
        )

        //   if(pathName==ten_path+'/organization/listing' || pathName==ten_path+'/department/listing' || pathName==ten_path+'/holidays/listing'
        //  || pathName==ten_path+'/points/listing'|| pathName==ten_path+'/job_descripation/listing' || pathName==ten_path+'/saroles/listing'
        //  )
        {

          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
        }
      }
















      if (route.path == '/super_admin/index.php/settings') {
        for (let i = 0; i < route.children.length; i++) {
          var child = route.children[i];
          child.name = T(child.name);

        }
        if (pathName == '/super_admin/index.php/organization/listing'
        ) {

          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
        }
      }
      if (route.path == ten_path + '/shops') {
        for (let i = 0; i < route.children.length; i++) {
          var child = route.children[i];
          child.name = T(child.name);

        }
        if (pathName.indexOf("productcategory") > -1 || pathName.indexOf("product") > -1 || pathName.indexOf("order") > -1
          || pathName.indexOf("banner") > -1
        ) {

          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
        }
      }

      if (route.path == ten_path + '/course') {
        for (let i = 0; i < route.children.length; i++) {
          var child = route.children[i];
          child.name = T(child.name);

        }
        if (pathName.indexOf("course") > -1 || pathName.indexOf("chaptors") > -1
        ) {

          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen //|| isHome
          }));
        }
      }


      if (route.path == ten_path + '/projects') {
        // for (let i = 0; i < route.children.length; i++) {
        //   var child = route.children[i];
        //   child.name = T(child.name);

        // }
        if (pathName.indexOf("projects") > -1 || pathName.indexOf("projecttasks") > -1
        ) {

          //alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          const isHome = route.containsHome && pathName === "/" ? true : false;
          /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen || isHome
          }));
        }
      }


      if (route.path == ten_path + '/shifts') {
        for (let i = 0; i < route.children.length; i++) {
          var child = route.children[i];
          child.name = T(child.name);

        } if (pathName.indexOf("shifts") > -1 || pathName.indexOf("shift_schedular") > -1
        || pathName.indexOf("attendance") > -1 || pathName.indexOf("absent") > -1 || pathName.indexOf("leaves") > -1
        )

        // if(pathName==ten_path+'/shifts/listing' || pathName==ten_path+'/shift_schedular/listing' || pathName==ten_path+'/shift_schedular/Importschedular'
        //    )
        {

          //  alert('hii1');
          //const isActive = pathName.indexOf(route.path) === 0;
          const isActive = true;
          const isOpen = route.open;
          const isHome = route.containsHome && pathName === "/" ? true : false;
          /// alert(isActive);
          this.setState(() => ({
            [index]: isActive || isOpen || isHome
          }));
        }
      }















    });





    //  const isActive = pathName.indexOf(route.path) === 0;

  }

  componentDidMount() {


    var pro_package = localStorage.getItem('package');
    var is_super_user = localStorage.getItem('is_super_user');
    this.state.pro_package = pro_package;

    var pro_zone_logo_logo;
    if (pro_package == '1') {
      pro_zone_logo_logo = myConstClass.SITE_URL + `img/Work-Zone-Pro-logo11.png`;
      $("#pro_logo").attr("src", pro_zone_logo_logo);
    }
    else {
      pro_zone_logo_logo = myConstClass.SITE_URL + `img/Work-Zone-Plus-logo.png`;
      $("#pro_logo").attr("src", pro_zone_logo_logo);
    }



    let ten_path;
    var url = window.location.pathname;

    var str = url.split("/");
    ten_path = '/' + str[1] + '/' + str[2];
    var login_id = localStorage.getItem('login_id');
    var login_type = localStorage.getItem('login_type');
    var pro_package = localStorage.getItem('package');
    this.state.pro_package = pro_package;
    
    var is_super_user = localStorage.getItem('is_super_user');



    if (login_type == 'hr_admin' && is_super_user == '0' || login_type == 'super_admin') {
      fetch(myConstClass.BASE_URL + 'user/getProfileInfo/' + login_id)
        .then(response => response.json())
        .then(response => { console.log(response); this.setState({ empData: response }); })
        //  alert(empData);
        .catch(err => console.log(err))
    }
    else if (login_type == 'sub_admin' && is_super_user != '0') {
      fetch(myConstClass.BASE_URL + 'user/getSuperAdminStaffInfo/' + is_super_user)
        .then(response => response.json())
        .then(response => { console.log(response); this.setState({ empData: response }); })
        //  alert(empData);
        .catch(err => console.log(err))
    }


    else {
      fetch(myConstClass.BASE_URL + 'user/getUserInfoE/' + login_id)
        .then(response => response.json())
        .then(response => { console.log(response); this.setState({ empData: response }); })
        //  alert(empData);
        .catch(err => console.log(err))


    }
  }

  render() {
    const { sidebar, pro_package } = this.props;
    var admin_id = localStorage.getItem('uname');
    const { empData } = this.state;
    let profile_img, profile_name;

    let c_main;
    let pro_zone_logo_logo;
    if (empData) {
      if (empData.image == '' || empData.image == null && empData.image != '') {
        profile_img = myConstClass.DOCS_URL + `img/profile.png`;
        profile_name = empData.first_name + ' ' + empData.last_name;
      } else {
        profile_img = myConstClass.DOCS_URL + `uploads/profile_images_hr/` + admin_id + `/` + empData.image;
        profile_name = empData.first_name + ' ' + empData.last_name;
        }
    } else {
      profile_img = myConstClass.DOCS_URL + `img/profile.png`;
      profile_name = 'Name';
    }

    if (pro_package == '1') {
      pro_zone_logo_logo = myConstClass.SITE_URL + `img/Work-Zone-Pro-logo11.png`;
    }
    else {
      pro_zone_logo_logo = myConstClass.SITE_URL + `img/Work-Zone-Plus-logo.png`;
    }











    var admin_id = localStorage.getItem('uname');

    return (
      <nav
        className={classNames(
          "sidebar",
          sidebar.isOpen || "toggled",
          !sidebar.isOnRight || "sidebar-right"
        )}
      >
        <div className="sidebar-content">
          <div class="ScrollStyle_sidebar" id="vertical_scroll_sidebar">


            <Link to={'/' + myConstClass.TENANT_PAGE_PATH + "dashboard"} > <img class="dashboar-plus-logo top_logo" src={logo} style={{ width: '40px', height: '40px' }} />  <img class="dashboar-plus-logo" src={pro_zone_logo_logo} id="pro_logo" style={{ width: '187px', height: '46px' }} />



            </Link>
  <div className="sidebar-user">
              <img
                src={profile_img}
                className="img-fluid rounded-circle mb-2"
                alt="Linda Miller"
              />
              <div className="font-weight-bold">{profile_name}</div>
            </div>
            <ul className="sidebar-nav">
              {routes.map((category, index) => {
               if (admin_id == 0) {
                  if (category.path == 'x') {

                    c_main = '';
                  } else {


                    c_main = category.children ? (
                      <SidebarCategory
                        name={category.name}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={this.state[index]}
                        onClick={() => this.toggle(index)}
                      >

                        {category.children.map((route, index) => (
                          //  route.path!='x' ? 

                          //                       (  
                          <SidebarItem
                            key={index}
                            name={route.name}
                            to={route.path}
                            badgeColor={route.badgeColor}
                            badgeText={route.badgeText}
                          />
                          //   ) :
                          // ''



                        ))}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem
                        name={category.name}
                        to={category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                      />
                    );
                  }

                } else {
                  if (category.path == 'x') {
                    c_main = '';
                  } else {
                    c_main = category.children ? (
                      <SidebarCategory
                        name={category.name}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={this.state[index]}
                        onClick={() => this.toggle(index)}
                      >

                        {category.children.map((route, index) => (
                         
                          <SidebarItem
                            key={index}
                            name={route.name}
                            to={route.path}
                            badgeColor={route.badgeColor}
                            badgeText={route.badgeText}
                          />
                      
                        ))}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem
                        name={category.name}
                        to={category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                      />
                    );
                  }
                   }
                return (
                  <React.Fragment key={index}>
                    {category.header ? (
                      <li className="sidebar-header">{category.header}</li>
                    ) : null}

                    {c_main}
                  </React.Fragment>
                );
              })}
            </ul><br />
          
          </div>

        </div>

      </nav>


    );
  }
}

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar
  }))(Sidebar)
);
