import async from "../components/Async";
import {
  faHome,
  faMapMarkerAlt,
  faMoneyBill,
  faTable,
  faUserCircle,
  faClock,
  faIdCard,
  faSignInAlt,
  faImages,
  faList,
  faVolumeUp,
  faUserTie,
  faUserPlus,
  faUserFriends,
  faLandmark,
  faFolderOpen,
  faUserSlash,
  faMobile,
  faCommentAlt,
  faExclamationTriangle,
  faProjectDiagram,faBook,faAward,
  faPrint, faTrophy, faTv, faCartPlus, faCalendarDay, faBusinessTime, faFileAlt, faChalkboardTeacher, faBlog, faCompressArrowsAlt, faTruck

} from "@fortawesome/free-solid-svg-icons";


import * as myConstClass from '../constant.js';
import { faCog, } from "@fortawesome/free-solid-svg-icons";

// import Landing from "../pages/careers/careers";
import Landing from "../pages/careers/Landing";
import LandingCareer from "../pages/careers/careerpublic";
import LandingCareerCompany from "../pages/careers/Careernew";

//localStorage.clear();


import {
  Translator,
  T,
  TF,
  LanguageList,
  Config
} from "react-translator-component";

Config.list = {
  KH: {
    text: "Khemer",
    file: require("../locale/KH.js")
  },
  en: {
    text: "English",
    file: require("../locale/en.js")

  },
  ch: {
    text: "Chinese",
    file: require("../locale/ch.js")

  },
};
Config.default = localStorage.getItem("lang");


// Pages

//alert(localStorage.getItem("lang"));
// Documentation
// const Documentation = async(() => import("../pages/docs/Documentation"));

// Dashboards
const Default = async(() => import("../pages/dashboards/Default"));

// Forms

// Tables
//alert("index");
//alert(localStorage.getItem('signup'));
//localStorage.getItem('signup')
//Signup Request
const SListing = async(() => import("../pages/signup/Listing"));
const SView = async(() => import("../pages/signup/view"));
const SMsg = async(() => import("../pages/signup/message"));

//HR Admin
const HRListing = async(() => import("../pages/hradmin/Listing"));
const HRAdd = async(() => import("../pages/hradmin/Validation"));

//for employee
const EListing = async(() => import("../pages/employee/Listing"));
const EAdd = async(() => import("../pages/employee/Validation"));
const EProfile = async(() => import("../pages/employee/profileinfo"));
const EProfilee = async(() => import("../pages/employee/profile"));

const EListingDocs = async(() => import("../pages/employee/ListingDocs"));
const EListingAssts = async(() => import("../pages/employee/ListingAssts"));
const EListingPoints = async(() => import("../pages/employee/ListingPoints"));
const EListingPromotions = async(() => import("../pages/employee/morepromotions"));
const Acesscode = async(() => import("../pages/employee/Acesscode"));
const JobInfo = async(() => import("../pages/employee/Jobinfo"));



//for employee bulk import
const EbAdd = async(() => import("../pages/employee_bulk_import/Import"));
const EbAddPayrollNote = async(() => import("../pages/employee_bulk_import/ImportPayrollNote"));
const EhAdd = async(() => import("../pages/holiday_bulk_import/Import"));
const ETLeaves = async(() => import("../pages/leaves/Listingtemp"));
const EDLeaves = async(() => import("../pages/leaves/ListingDel"));
const ADLeaves = async(() => import("../pages/attendance/ListingDel"));
const ODLeaves = async(() => import("../pages/organization/ListingDel"));
const DDLeaves = async(() => import("../pages/department/ListingDel"));
const EbSettAdd = async(() => import("../pages/employee_bulk_import/ImportSettlement"));
const EbSettAddAll = async(() => import("../pages/employee_bulk_import/ImportSettlementAll"));
const ImTask = async(() => import("../pages/employee_bulk_import/ImportTasks"));
//for organization
const OListing = async(() => import("../pages/organization/Listing"));
const OAdd = async(() => import("../pages/organization/Validation"));

//for Attendance
const AListing = async(() => import("../pages/attendance/Listing"));
const AAdd = async(() => import("../pages/attendance/Validation"));
const AView = async(() => import("../pages/attendance/view"));
const BView = async(() => import("../pages/attendance/checkinout"));
const BSView = async(() => import("../pages/attendance/checkinoutsecurity"));
const BSSView = async(() => import("../pages/attendance/ListingChecinout"));
//
const AbsenceListing = async(() => import("../pages/absent/Listing"));




const BSVieww = async(() => import("../pages/attendance/ListingCheckoutSecurity"));
const GListing = async(() => import("../pages/guests/Listing"));
const GView = async(() => import("../pages/guests/view"));
const GCheckinout = async(() => import("../pages/guests/Validation"));



//for Leaves
const LListing = async(() => import("../pages/leaves/Listing"));
const LAdd = async(() => import("../pages/leaves/Validation"));


//for assets
const AssListing = async(() => import("../pages/assets/Listing"));
const AssAdd = async(() => import("../pages/assets/Validation"));


//for Announcements
const AnListing = async(() => import("../pages/announcements/Listing"));
const AnAdd = async(() => import("../pages/announcements/Validation"));

//for adminapp

const AdListing = async(() => import("../pages/adminapp/Listing"));
const AdAdd = async(() => import("../pages/adminapp/Validation"));


//for org gropups in super
const OrgGrpListing = async(() => import("../pages/org_group/Listing"));
const OrgGrpAdd = async(() => import("../pages/org_group/Validation"));

//for document type
const DocListing = async(() => import("../pages/document_type/Listing"));
//const SettEmpListing = async(() => import("../pages/document_type/ListingEmpSet"));
const DocAdd = async(() => import("../pages/document_type/Validation"));


const NoteEmpListing = async(() => import("../pages/document_type/ListingEmpNote"));
const SettEmpListing = async(() => import("../pages/document_type/ListingEmpSet"));
const DocEmpListing = async(() => import("../pages/document_type/ListingEmp"));

//for Announcements
const ALLoListing = async(() => import("../pages/allownce_type/Listing"));
const ALLoAdd = async(() => import("../pages/allownce_type/Validation"));


//for category type
const CattypeListing = async(() => import("../pages/category_type/Listing"));
const CattypeAdd = async(() => import("../pages/category_type/Validation"));

////categoery for sub admin
const HrBlogCattypeListing = async(() => import("../pages/category/Listing"));
const HrBlogCattypeAdd = async(() => import("../pages/category/Validation"));


///////sub category for sub admin

const HrBlogSubCattypeListing = async(() => import("../pages/subcategory/Listing"));
const HrBlogSubCattypeAdd = async(() => import("../pages/subcategory/Validation"));
//for complaint type
const CompTypeListing = async(() => import("../pages/complaint_type/Listing"));
const CompTypeAdd = async(() => import("../pages/complaint_type/Validation"));

/////for blog type
const BlogTypeListing = async(() => import("../pages/blog_type/Listing"));
const BlogTypeAdd = async(() => import("../pages/blog_type/Validation"));


//for job_type
const JobTypeListing = async(() => import("../pages/job_type/Listing"));
const JobTypeAdd = async(() => import("../pages/job_type/Validation"));

//for job_title
const JobTitleListing = async(() => import("../pages/job_title/Listing"));
const JobTitleAdd = async(() => import("../pages/job_title/Validation"));

//for job_id
const JobIDListing = async(() => import("../pages/job_id/Listing"));
const JobIDAdd = async(() => import("../pages/job_id/Validation"));

//for annoucement_type
const AnnoucementTypeListing = async(() => import("../pages/annoucement_type/Listing"));
const AnnoucementTypeAdd = async(() => import("../pages/annoucement_type/Validation"));


const CourseTypeListing = async(() => import("../pages/course_type/Listing"));
const CourseTypeAdd = async(() => import("../pages/course_type/Validation"));



//for Allowance Code
const ALLCoListing = async(() => import("../pages/allownce_code/Listing"));
const ALLCoAdd = async(() => import("../pages/allownce_code/Validation"));


//for note code

const NoteListing = async(() => import("../pages/note_code/Listing"));
const NoteAdd = async(() => import("../pages/note_code/Validation"));

//for tax code
const TaxListing = async(() => import("../pages/tax_code/Listing"));
const TaxAdd = async(() => import("../pages/tax_code/Validation"));


const PAListing = async(() => import("../pages/paycode/Listing"));
const PAAdd = async(() => import("../pages/paycode/Validation"));

//for Prormotions
const PListing = async(() => import("../pages/promotions/Listing"));
const PAdd = async(() => import("../pages/promotions/Validation"));


//for Prormotions
const SLListing = async(() => import("../pages/logs/Listing"));

//for ad more promotions by akshay
const BListing = async(() => import("../pages/morepromotions/Listing"));
const BAdd = async(() => import("../pages/morepromotions/Validation"));

//for public holiday 
const PublicHolidayListing = async(() => import("../pages/holidays/Listing"));
const PublicHolidayAdd = async(() => import("../pages/holidays/Validation"));

//for emp groups
const empGroupListing = async(() => import("../pages/employee_groups/Listing"));
const empGroupAdd = async(() => import("../pages/employee_groups/Validation"));

//for complaints 
const complaintsListing = async(() => import("../pages/complaints/Listing"));
const complaintsAdd = async(() => import("../pages/complaints/Validation"));
const ComplaintScoreListing = async(() => import("../pages/complaints/ListingComment"));




const blogsListing = async(() => import("../pages/blog/Listing"));
const blogsAdd = async(() => import("../pages/blog/Validation"));
const blogsCommentListing = async(() => import("../pages/blog/ListingComment"));
const blogsCommentAdd = async(() => import("../pages/blog/ValidationComment"));

const rolesListing = async(() => import("../pages/roles/Listing"));
const rolesAdd = async(() => import("../pages/roles/Validation"));


const sarolesListing = async(() => import("../pages/saroles/Listing"));
const sarolesAdd = async(() => import("../pages/saroles/Validation"));


//for master department
const MasterDepartmentListing = async(() => import("../pages/department/Listing"));
const MasterDepartmentAdd = async(() => import("../pages/department/Validation"));


const PayrollPrivilageListing = async(() => import("../pages/payrollprivalage/Listing"));
const PayrollPrivilageAdd = async(() => import("../pages/payrollprivalage/Validation"));

//my profile
const Mprofile = async(() => import("../pages/myprofile/Add"));



//shifts
const shiftListing = async(() => import("../pages/shifts/Listing"));
const shiftAdd = async(() => import("../pages/shifts/Validation"));

//tax bracekt
const taxBracketListing = async(() => import("../pages/tax_brackets/Listing"));
const taxBracketAdd = async(() => import("../pages/tax_brackets/Validation"));

//shift schedular
const shiftSchedularImport = async(() => import("../pages/shift_schedular/Importschedular"));
const shiftSchedularList = async(() => import("../pages/shift_schedular/Listing"));
const shiftSchedularListHistory = async(() => import("../pages/shift_schedular/ListingHistory"));
const shiftSchedularAdd = async(() => import("../pages/shift_schedular/Validation"));
//payroll line entry
const payrollLineEnrtyAdd = async(() => import("../pages/payroll_line_entry/Validation"));
const payrollLineEnrtyAddAdmin = async(() => import("../pages/payroll_line_entry/Validationadmin"));
const payrollLineEntryList = async(() => import("../pages/payroll_line_entry/Listing"));
const payrollLineEntryListAdmin = async(() => import("../pages/payroll_line_entry/Listingadmin"));
const payrollLineEntryListTest = async(() => import("../pages/payroll_line_entry/ListingTest"));
const payslipImport = async(() => import("../pages/payroll_line_entry/Import_payslip_data"));
const payslipImportExport = async(() => import("../pages/payroll_line_entry/Data"));
const payslipImportExportEdit = async(() => import("../pages/payroll_line_entry/DataValidation"));
const payslipImportExportBefore = async(() => import("../pages/payroll_line_entry/DataBeforePayroll"));
const payslipImportExportBeforeNp = async(() => import("../pages/payroll_line_entry/DataBeforePayrollNoticePay"));
const paidInstruction = async(() => import("../pages/payroll_line_entry/ValidationPaid"));
const paidImport = async(() => import("../pages/payroll_line_entry/Import_paid_instruction"));
const payrollreport = async(() => import("../pages/payroll_line_entry/report"));
const allPayrollreport = async(() => import("../pages/payroll_line_entry/allreports"));
const payslippdf = async(() => import("../pages/payslip/Payslip_pdf"));
//const viewPayslip= async(() => import("../pages/payslip/View_payslip"));
//payslip
const payslipList = async(() => import("../pages/payslip/Listing"));
const payslipListClose = async(() => import("../pages/payslip/ListingClose"));
const viewPayslip = async(() => import("../pages/payslip/View_payslip"));
const Payrolltable = async(() => import("../pages/payslip/Payrolltable"));
//constsnts
const leaveTypeListing = async(() => import("../pages/leave_type/Listing"));
const leaveTypeAdd = async(() => import("../pages/leave_type/Validation"));


//for admin staff
const adminStaffListing = async(() => import("../pages/admin_staff/Listing"));
const adminStaffAdd = async(() => import("../pages/admin_staff/Validation"));
const securityStaffAdd = async(() => import("../pages/admin_staff/ValidationSecurity"));
const securityStaffListing = async(() => import("../pages/admin_staff/ListingSecurity"));

//for product category
const ProductcatgoryListing = async(() => import("../pages/productcategory/Listing"));
const ProductcatgoryAdd = async(() => import("../pages/productcategory/Validation"));


const superadminStaffListing = async(() => import("../pages/superadmin_staff/Listing"));
const superadminStaffAdd = async(() => import("../pages/superadmin_staff/Validation"));



//for product 
const ProductListing = async(() => import("../pages/product/Listing"));
const ProductAdd = async(() => import("../pages/product/Validation"));
const ProductShop = async(() => import("../pages/product/shop"));
const CardAdd = async(() => import("../pages/product/shopproducts"));
const CartAdd = async(() => import("../pages/product/cartinfo"));
const ShopAdd = async(() => import("../pages/product/moreinfo"));


const TopproductAdd = async(() => import("../pages/product/shoptopproducts"));
//for order 
const OrderListing = async(() => import("../pages/order/Listing"));
const OrderAdd = async(() => import("../pages/order/Validation"));


//PublicPointtListing
const PublicPointListing = async(() => import("../pages/points/Listing"));
const PublicPointAdd = async(() => import("../pages/points/Validation"));

const PayrollheadersListing = async(() => import("../pages/Payrollheaders/Listing"));
const PayrollheadersAdd = async(() => import("../pages/Payrollheaders/Validation"));

//job description
const JobdescListing = async(() => import("../pages/job_descripation/Listing"));
const Jobdisc = async(() => import("../pages/job_descripation/Validationdis"));
const JobdescAdd = async(() => import("../pages/job_descripation/Validation"));
const JobdescCard = async(() => import("../pages/job_descripation/jpbdesccard"));
const JobpostListing = async(() => import("../pages/jobpost/Listing"));
const JobpostAdd = async(() => import("../pages/jobpost/Validation"));
//const Applyjobs = async(() => import("../pages/careers/Applyjobs"));
const Applist = async(() => import("../pages/jobpost/Applicantlisting"));
const JApplist = async(() => import("../pages/jobpost/JobpostAppListing"));


const IListing = async(() => import("../pages/incident/Listing"));
const IAdd = async(() => import("../pages/incident/Validation"));
const IAddI = async(() => import("../pages/incident/Add"));
const IAddM = async(() => import("../pages/incident/Addmedia"));
const IVidieo = async(() => import("../pages/incident/Vidieo"));

const MsgListing = async(() => import("../pages/empmsg/Chat"));


//for courses and topic
const CourseListing = async(() => import("../pages/course/Listing"));
const CourseAdd = async(() => import("../pages/course/Validation"));

//for notice pay check
const NoticePayCheckListing = async(() => import("../pages/notice_pay_check/Listing"));
const NoticePayCheckAdd = async(() => import("../pages/notice_pay_check/Validation"));

const ChaptorListing = async(() => import("../pages/chaptors/Listing"));
const ChaptorAdd = async(() => import("../pages/chaptors/Validation"));
const TopicAdd = async(() => import("../pages/chaptors/Validationtopic"));
const Vidieo = async(() => import("../pages/chaptors/Vidieo"));
const Videoselfjs = async(() => import("../pages/chaptors/Avidieo"));




const ProjectListing = async(() => import("../pages/projects/Listing"));
const ProjectAdd = async(() => import("../pages/projects/Validation"));
// const TopicAdd = async(() => import("../pages/chaptors/Validationtopic"));
// const Vidieo = async(() => import("../pages/chaptors/Vidieo"));


const ProjecttasksListing = async(() => import("../pages/projectstasks/Listing"));
const ProjecttasksAdd = async(() => import("../pages/projectstasks/Validation"));






//alert(path);

//alert('hii');

// const IVidieo = async(() => import("../pages/incident/Vidieo"));

//for banners
const BAListing = async(() => import("../pages/banner/Listing"));
const BAAdd = async(() => import("../pages/banner/Validation"));


///for reports
const noticePayReport = async(() => import("../pages/reports/noticePayReport"));
const payrollReports = async(() => import("../pages/reports/payrollReport"));
const projectsReports = async(() => import("../pages/reports/projectsReport"));
const staffingReports = async(() => import("../pages/reports/staffingReport"));
const LeaveReports = async(() => import("../pages/reports/leaveReport"));
const EmployeeEvalutionReports = async(() => import("../pages/reports/employeeEvalutionReport"));
const EmployeeEvalutionVation= async(() => import("../pages/reports/Validation"));

var admin_id = localStorage.getItem('uname');
var pro_package = localStorage.getItem('package'); //alert(pro_package);
var login_id = localStorage.getItem('login_id');//alert(login_id);
var login_type = localStorage.getItem('login_type');//alert(login_type);
var login_role = localStorage.getItem('role_modules');
var company_name = localStorage.getItem('company_name');
let storedNames = localStorage.getItem('sub_admin_roles');
localStorage.setItem('edit_msg', 'Do not have Edit Privilege');
localStorage.setItem('add_msg', 'Do not have Add Privilege');
localStorage.setItem('delete_msg', 'Do not have Delete Privilege');
localStorage.setItem('view_msg', 'Do not have Privilege');
var sub_admin_role = localStorage.getItem('sub_admin_role');



//alert(sub_admin_role);


if (localStorage.getItem('change') == true) {
  window.location.reload(false);
  this.setState({ change: false })
}


//alert(localStorage.getItem('is_subadmin'));
// alert(localStorage.getItem('login_type'));
// alert(localStorage.getItem('joburlcomp'));





//alert(storedNames);
fetch(myConstClass.BASE_URL + 'organization/getaddAgainstInfo_Page/' + sub_admin_role)
  .then(response => response.json())
  .then(response => {

    //alert(response);

    localStorage.setItem('emppre_add', response[0]);
    localStorage.setItem('empabs_add', response[2]);
    localStorage.setItem('empann_add', response[7]);
    localStorage.setItem('empblog_add', response[8]);
    localStorage.setItem('empmsg_add', response[10]);
    localStorage.setItem('empapp_add', response[9]);
    localStorage.setItem('emptrain_add', response[14]);
    localStorage.setItem('empjurl_add', response[16]);
    localStorage.setItem('empprom_add', response[17]);
    localStorage.setItem('empplogs_add', response[20]);
    localStorage.setItem('empcheckin_add', response[21]);
    localStorage.setItem('empirep_add', response[22]);
    localStorage.setItem('empleave_add', response[24]);
    localStorage.setItem('empleavet_add', response[23]);
    localStorage.setItem('empemp_add', response[25]);
    localStorage.setItem('empgrp_add', response[26]);
    localStorage.setItem('empcomp_add', response[27]);
    localStorage.setItem('empsubadmin_add', response[28]);
    localStorage.setItem('empsecurity_add', response[29]);
    localStorage.setItem('emporg_add', response[30]);
    localStorage.setItem('empholiday_add', response[31]);
    localStorage.setItem('empdept_add', response[32]);
    localStorage.setItem('emppoint_add', response[33]);
    localStorage.setItem('empjd_add', response[34]);
    localStorage.setItem('emptax_add', response[35]);
    localStorage.setItem('emppayline_add', response[36]);
    localStorage.setItem('emppayslip_add', response[37]);
    localStorage.setItem('emppaycode_add', response[38]);
    localStorage.setItem('empshift_add', response[39]);
    localStorage.setItem('empschduler_add', response[40]);
    localStorage.setItem('empschdulerimp_add', response[41]);
    localStorage.setItem('emppcat_add', response[42]);
    localStorage.setItem('emppro_add', response[43]);
    localStorage.setItem('emporder_add', response[44]);
    localStorage.setItem('empbanner_add', response[45]);
    localStorage.setItem('empcourse_add', response[46]);
    localStorage.setItem('empjobpost_add', response[47]);
    localStorage.setItem('empa_applist_add', response[48]);
    localStorage.setItem('empproject_add', response[49]);
    localStorage.setItem('emptask_add', response[50]);


    localStorage.setItem('emprole_add', response[51]);
    localStorage.setItem('empassets_add', response[52]);
    localStorage.setItem('allowtype_add', response[53]);
    localStorage.setItem('empallocode_add', response[54]);
    localStorage.setItem('empnote_add', response[55]);
    localStorage.setItem('empdocu_add', response[56]);
    localStorage.setItem('doctype_add', response[57]);


    localStorage.setItem('compcat_add', response[59]);
    localStorage.setItem('comptype_add', response[60]);
    localStorage.setItem('blgtype_add', response[61]);
    localStorage.setItem('jbtype_add', response[62]);
    localStorage.setItem('anntype_add', response[63]);

    localStorage.setItem('coursetype_add', response[64]);
    localStorage.setItem('empdoc_add', response[65]);


    //this.setState({is_add:response[25]});
  }).catch(err => console.log(err));



fetch(myConstClass.BASE_URL + 'organization/getmoduleshradminAgainstInfo/' + admin_id)
  .then(response => response.json())
  .then(() => {
    //alert();
    //alert(response);
    //let newArray = response.slice();
    //this.setState({complaint_against: response})


    //alert(response[8]);

  }).catch(err => console.log(err));



var hr_work_plan = localStorage.getItem('hr_work_plan');
var hr_emp_careeer = localStorage.getItem('hr_emp_careeer');
var hr_emp_training = localStorage.getItem('hr_emp_training');
var hr_emp_msg = localStorage.getItem('hr_emp_msg');
var hr_emp_shop = localStorage.getItem('hr_emp_shop');
var hr_emp_promotions = localStorage.getItem('hr_emp_promotions');
var hr_emp_annoucements = localStorage.getItem('hr_emp_annoucements');
var hr_emp_blogs = localStorage.getItem('hr_emp_blogs');
var hr_emp_guests = localStorage.getItem('hr_emp_guests');
var is_super_user = localStorage.getItem('is_super_user');

if (is_super_user == '0') {
  //alert('hii');
  //alert(sub_admin_role);

  if (sub_admin_role == 0) {
    localStorage.setItem('organization_sub_admin', localStorage.getItem('org_id_payroll'));
  }
  else {
    fetch(myConstClass.BASE_URL + 'organization/getrolesInfo/' + sub_admin_role)
      .then(response => response.json())
      .then(response => {

        var string = response.organization_id;

        var new_string = string.replace(',', '-');


        //alert(new_string);


        localStorage.setItem('organization_sub_admin', new_string);
        var stringdept = response.department;

        var new_stringdept = stringdept.replace(',', '-');


        //alert(new_stringdept);


        localStorage.setItem('department_sub_admin', new_stringdept);

        //  this.setState({ text7: response.description });

        //  this.setState({department:[{ value:response.department, label:response.dname }]});

        // this.setState({organization_id:[{ value:response.organization_id, label:response.otitle }]}); 

      }).catch(err => console.log(err));
  }
}


else if (is_super_user != '0') {

  fetch(myConstClass.BASE_URL + 'user/getSuperAdminStaffInfo/' + is_super_user)
    .then(response => response.json())
    .then(response => {

      var string = response.organization_id;

      var new_string = string.replace(',', '-');

      localStorage.setItem('organization_sub_admin', new_string);
      localStorage.setItem('department_sub_admin', '0');

    }).catch(err => console.log(err));
}









//alert(localStorage.getItem('organization_sub_admin'));
//var new_string = string.replace('string','new string');









fetch(myConstClass.BASE_URL + 'organization/geteditAgainstInfo_Page/' + sub_admin_role)
  .then(response => response.json())
  .then(response => {
    //this.setState({complaint_againstadd: response})
    // alert(response[0]);
    //this.setState({is_edit:response[25]});
    localStorage.setItem('emppre_edit', response[0]);
    localStorage.setItem('empabs_edit', response[2]);
    localStorage.setItem('empann_edit', response[7]);
    localStorage.setItem('empblog_edit', response[8]);
    localStorage.setItem('empmsg_edit', response[10]);
    localStorage.setItem('empapp_edit', response[9]);
    localStorage.setItem('emptrain_edit', response[14]);
    localStorage.setItem('empjurl_edit', response[16]);
    localStorage.setItem('empprom_edit', response[17]);

    localStorage.setItem('empplogs_edit', response[20]);
    localStorage.setItem('empcheckin_edit', response[21]);
    localStorage.setItem('empirep_edit', response[22]);
    localStorage.setItem('empleave_edit', response[24]);
    localStorage.setItem('empleavet_edit', response[23]);
    localStorage.setItem('empemp_edit', response[25]);
    localStorage.setItem('empgrp_edit', response[26]);
    localStorage.setItem('empcomp_edit', response[27]);
    localStorage.setItem('empsubadmin_edit', response[28]);


    localStorage.setItem('empsecurity_edit', response[29]);
    localStorage.setItem('emporg_edit', response[30]);
    localStorage.setItem('empholiday_edit', response[31]);
    localStorage.setItem('empdept_edit', response[32]);
    localStorage.setItem('emppoint_edit', response[33]);
    localStorage.setItem('empjd_edit', response[34]);
    localStorage.setItem('emptax_edit', response[35]);
    localStorage.setItem('emppayline_edit', response[36]);
    localStorage.setItem('emppayslip_edit', response[37]);




    localStorage.setItem('emppaycode_edit', response[38]);
    localStorage.setItem('empshift_edit', response[39]);
    localStorage.setItem('empschduler_edit', response[40]);
    localStorage.setItem('empschdulerimp_edit', response[41]);

    localStorage.setItem('emppcat_edit', response[42]);
    localStorage.setItem('emppro_edit', response[43]);
    localStorage.setItem('emporder_edit', response[44]);
    localStorage.setItem('empbanner_edit', response[45]);
    localStorage.setItem('empcourse_edit', response[46]);
    localStorage.setItem('empjobpost_edit', response[47]);

    localStorage.setItem('empa_applist_edit', response[48]);
    localStorage.setItem('empproject_edit', response[49]);
    localStorage.setItem('emptask_edit', response[50]);
    localStorage.setItem('emprole_edit', response[51]);


    localStorage.setItem('empassets_edit', response[52]);
    localStorage.setItem('allowtype_edit', response[53]);
    localStorage.setItem('empallocode_edit', response[54]);
    localStorage.setItem('empnote_edit', response[55]);
    localStorage.setItem('empdocu_edit', response[56]);
    localStorage.setItem('doctype_edit', response[57]);

    localStorage.setItem('compcat_edit', response[59]);
    localStorage.setItem('comptype_edit', response[60]);
    localStorage.setItem('blgtype_edit', response[61]);
    localStorage.setItem('jbtype_edit', response[62]);
    localStorage.setItem('anntype_edit', response[63]);

    localStorage.setItem('coursetype_edit', response[64]);
    localStorage.setItem('empdoc_edit', response[65]);







  }).catch(err => console.log(err));




fetch(myConstClass.BASE_URL + 'organization/getdeleteAgainstInfo_Page/' + sub_admin_role)
  .then(response => response.json())
  .then(response => {
    //this.setState({complaint_againstadd: response})
    // alert(response[0]);
    //this.setState({is_delete:response[0]});

    localStorage.setItem('emppre_delete', response[0]);
    localStorage.setItem('empabs_delete', response[2]);
    localStorage.setItem('empann_delete', response[7]);
    localStorage.setItem('empblog_delete', response[8]);
    localStorage.setItem('empmsg_delete', response[10]);
    localStorage.setItem('empapp_delete', response[9]);
    localStorage.setItem('emptrain_delete', response[14]);
    localStorage.setItem('empjurl_delete', response[16]);
    localStorage.setItem('empprom_delete', response[17]);

    localStorage.setItem('empplogs_delete', response[20]);
    localStorage.setItem('empcheckin_delete', response[21]);
    localStorage.setItem('empirep_delete', response[22]);
    localStorage.setItem('empleave_delete', response[24]);
    localStorage.setItem('empleavet_delete', response[23]);
    localStorage.setItem('empemp_delete', response[25]);
    localStorage.setItem('empgrp_delete', response[26]);
    localStorage.setItem('empcomp_delete', response[27]);
    localStorage.setItem('empsubadmin_delete', response[28]);


    localStorage.setItem('empsecurity_delete', response[29]);
    localStorage.setItem('emporg_delete', response[30]);
    localStorage.setItem('empholiday_delete', response[31]);
    localStorage.setItem('empdept_delete', response[32]);
    localStorage.setItem('emppoint_delete', response[33]);
    localStorage.setItem('empjd_delete', response[34]);
    localStorage.setItem('emptax_delete', response[35]);
    localStorage.setItem('emppayline_delete', response[36]);
    localStorage.setItem('emppayslip_delete', response[37]);




    localStorage.setItem('emppaycode_delete', response[38]);
    localStorage.setItem('empshift_delete', response[39]);
    localStorage.setItem('empschduler_delete', response[40]);
    localStorage.setItem('empschdulerimp_delete', response[41]);

    localStorage.setItem('emppcat_delete', response[42]);
    localStorage.setItem('emppro_delete', response[43]);
    localStorage.setItem('emporder_delete', response[44]);
    localStorage.setItem('empbanner_delete', response[45]);
    localStorage.setItem('empcourse_delete', response[46]);
    localStorage.setItem('empjobpost_delete', response[47]);

    localStorage.setItem('empa_applist_delete', response[48]);
    localStorage.setItem('empproject_delete', response[49]);
    localStorage.setItem('emptask_delete', response[50]);
    localStorage.setItem('emprole_delete', response[51]);

    localStorage.setItem('empassets_delete', response[52]);
    localStorage.setItem('allowtype_delete', response[53]);
    localStorage.setItem('empallocode_delete', response[54]);
    localStorage.setItem('empnote_delete', response[55]);
    localStorage.setItem('empdocu_delete', response[56]);
    localStorage.setItem('doctype_delete', response[57]);


    localStorage.setItem('compcat_delete', response[59]);
    localStorage.setItem('comptype_delete', response[60]);
    localStorage.setItem('blgtype_delete', response[61]);
    localStorage.setItem('jbtype_delete', response[62]);
    localStorage.setItem('anntype_delete', response[63]);

    localStorage.setItem('coursetype_delete', response[64]);
    localStorage.setItem('empdoc_delete', response[65]);






  }).catch(err => console.log(err));
















//alert(storedNames);


var is_sub_admin_with_payroll = localStorage.getItem('is_sub_admin_with_payroll');

let myArraymodules;
let easstes = 0,
  eallowancwtype = 0,
  eallowancecode = 0,
  enotecode = 0, empdoc = 0, doctype = 0,
  ep = 0, ea = 0, eann = 0, eapp = 0, eblog = 0, emsg = 0, ejurl = 0, eprom = 0, elogs = 0, echeck = 0, eincident = 0, eleavetype = 0, eleave = 0, eemp = 0, egrp = 0, ecmp = 0, esubadmin = 0,

  esec = 0, eorg = 0,
  eholiday = 0, edept = 0, epoint = 0, ejd = 0, ebracket = 0, epayroll = 0, epayslip = 0, epaycode = 0, eshift = 0, escheduler = 0, eshiftreport = 0, eprocat = 0,
  epro = 0, eorder = 0, ebanner = 0,
  ecourse = 0, ejobpost = 0, eapplist = 0, eprojects = 0, etasks = 0, eroles = 0, empgrp = 0, compcat = 0, comptype = 0, bltype = 0, jbtype = 0, anntype = 0, coutype = 0,
  empdocum = 0, schhistory = 0;



if (is_sub_admin_with_payroll == '1') {

  if (storedNames != null) {

    if (storedNames != '0') {
      myArraymodules = storedNames.split(",");

    }


    //alert(myArraymodules[50]);

    //alert(myArraymodules[0]);

    if (myArraymodules != undefined) {

      if (myArraymodules[0] == '0') {

        ep = '0';
      }
      else {
        ep = '1';
        localStorage.setItem('presnse_view', '1');
      }
      //alert(ep);
      if (myArraymodules[2] == '0') {
        ea = '0';
      }
      else {
        ea = '1';
      }


      if (myArraymodules[7] == '0') {
        eann = '0';
      }
      else {
        eann = '1';
      }

      if (myArraymodules[9] == '0') {
        eapp = '0';
      }
      else {
        eapp = '1';
      }
      //alert(eapp);


      if (myArraymodules[8] == '0') {
        eblog = '0';
      }
      else {
        eblog = '1';
      }
      ///alert(eblog);
      localStorage.setItem('msgnot', myArraymodules[10]);
      if (myArraymodules[10] == '0') {
        emsg = '0';
      }
      else {
        emsg = '1';
      }

      if (myArraymodules[16] == '0') {
        ejurl = '0';
      }
      else {
        ejurl = '1';
      }

      if (myArraymodules[17] == '0') {
        eprom = '0';
      }
      else {
        eprom = '1';
      }


      if (myArraymodules[20] == '0') {
        elogs = '0';
      }
      else {
        elogs = '1';
      }

      if (myArraymodules[21] == '0') {
        echeck = '0';
      }
      else {
        echeck = '1';
      }

      //alert(echeck);
      if (myArraymodules[22] == '0') {
        eincident = '0';
      }
      else {
        eincident = '1';
      }
      localStorage.setItem('leavenot', myArraymodules[24]);

      if (myArraymodules[24] == '0') {
        eleave = '0';
      }
      else {
        eleave = '1';
        localStorage.setItem('leave_view', '1');
      }


      if (myArraymodules[23] == '0') {
        eleavetype = '0';
      }
      else {
        eleavetype = '1';
      }


      if (myArraymodules[26] == '0') {
        egrp = '0';
      }
      else {
        egrp = '1';
      }


      if (myArraymodules[25] == '0') {
        eemp = '0';
      }
      else {
        eemp = '1';
        localStorage.setItem('empview', '1');
      }
      localStorage.setItem('comnot', myArraymodules[27]);
      if (myArraymodules[27] == '0') {

        ecmp = '0';
      }
      else {
        ecmp = '1';
      }

      if (myArraymodules[28] == '0') {
        esubadmin = '0';
      }
      else {
        esubadmin = '1';
      }

      if (myArraymodules[29] == '0') {
        esec = '0';
      }
      else {
        esec = '1';
      }
      // if(myArraymodules[30]=='f')
      // {
      //   esec='0';
      // }
      // else{
      //   esec='1';
      // }
      if (myArraymodules[30] == '0') {
        eorg = '0';
      }
      else {
        eorg = '1';
        localStorage.setItem('orgview', '1');
      }
      //alert(eorg);

      if (myArraymodules[31] == '0') {
        eholiday = '0';
      }
      else {
        eholiday = '1';
      }
      if (myArraymodules[32] == '0') {
        edept = '0';
      }
      else {
        edept = '1';
      }
      //alert(edept);
      if (myArraymodules[33] == '0') {
        epoint = '0';
      }
      else {
        epoint = '1';
      }
      //alert(epoint);
      if (myArraymodules[34] == '0') {
        ejd = '0';
      }
      else {
        ejd = '1';
      }
      //alert(ejd);

      if (myArraymodules[35] == '0') {
        ebracket = '0';
      }
      else {
        ebracket = '1';
      }
      //alert(ebracket);

      if (myArraymodules[36] == '0') {
        epayroll = '0';
      }
      else {
        epayroll = '1';
      }


      if (myArraymodules[37] == '0') {
        epayslip = '0';
      }
      else {
        epayslip = '1';
      }

      if (myArraymodules[38] == '0') {
        epaycode = '0';
      }
      else {
        epaycode = '1';
      }

      if (myArraymodules[39] == '0') {
        eshift = '0';
      }
      else {
        eshift = '1';
      }

      if (myArraymodules[40] == '0') {
        escheduler = '0';
      }
      else {
        escheduler = '1';
      }


      if (myArraymodules[41] == '0') {
        eshiftreport = '0';
      }
      else {
        eshiftreport = '1';
      }


      if (myArraymodules[42] == '0') {
        eprocat = '0';
      }
      else {
        eprocat = '1';
      }


      if (myArraymodules[43] == '0') {
        epro = '0';
      }
      else {
        epro = '1';
      }
      localStorage.setItem('ordernot', myArraymodules[44]);

      if (myArraymodules[44] == '0') {
        eorder = '0';
      }
      else {
        eorder = '1';
      }


      if (myArraymodules[45] == '0') {
        ebanner = '0';
      }
      else {
        ebanner = '1';
      }


      if (myArraymodules[46] == '0') {
        ecourse = '0';
      }
      else {
        ecourse = '1';
      }
      if (myArraymodules[47] == '0') {
        ejobpost = '0';
      }
      else {
        ejobpost = '1';
      }

      if (myArraymodules[48] == '0') {
        eapplist = '0';
      }
      else {
        eapplist = '1';
      }


      if (myArraymodules[49] == '0') {
        eprojects = '0';
      }
      else {
        eprojects = '1';
      }


      if (myArraymodules[50] == '0') {
        etasks = '0';
      }
      else {
        etasks = '1';
      }
      if (myArraymodules[51] == '0') {
        eroles = '0';
      }
      else {
        eroles = '1';
      }


      if (myArraymodules[52] == '0') {
        easstes = '0';
      }
      else {
        easstes = '1';
      }

      if (myArraymodules[53] == '0') {
        eallowancwtype = '0';
      }
      else {
        eallowancwtype = '1';
      }
      if (myArraymodules[54] == '0') {
        eallowancecode = '0';
      }
      else {
        eallowancecode = '1';
      }
      if (myArraymodules[55] == '0') {
        enotecode = '0';
      }
      else {
        enotecode = '1';
      }



      if (myArraymodules[56] == '0') {
        empdoc = '0';

      }
      else {
        localStorage.setItem('docnot', myArraymodules[56]);
        empdoc = '1';
      }


      if (myArraymodules[57] == '0') {
        doctype = '0';
      }
      else {
        doctype = '1';
      }

      if (myArraymodules[58] == '0') {
        empgrp = '0';
      }
      else {
        empgrp = '1';
      }

      if (myArraymodules[59] == '0') {
        compcat = '0';
      }
      else {
        compcat = '1';
      }


      if (myArraymodules[60] == '0') {
        comptype = '0';
      }
      else {
        comptype = '1';
      }


      if (myArraymodules[61] == '0') {
        bltype = '0';
      }
      else {
        bltype = '1';
      }
      if (myArraymodules[62] == '0') {
        jbtype = '0';
      }
      else {
        jbtype = '1';
      }

      if (myArraymodules[63] == '0') {
        anntype = '0';
      }
      else {
        anntype = '1';
      }

      if (myArraymodules[64] == '0') {
        coutype = '0';
      }
      else {
        coutype = '1';
      }
      //alert(eroles);
      if (myArraymodules[65] == '0') {
        empdocum = '0';
      }
      else {
        empdocum = '1';
      }
      if (myArraymodules[66] == '0') {
        schhistory = '0';
      }
      else {
        schhistory = '1';
      }

    }



  }
  else {
  }

}
if (is_sub_admin_with_payroll == '2') {
  // alert(is_sub_admin_with_payroll);
  epayroll = '1';
  epayslip = '1';
}

//alert(etasks)
//const myArraymodules = storedNames.split(",");

// alert(myArraymodules[0]);

// let url;
let ten_path;
let file_ten_path;
let dashboardR;

let PromotionSuper, SettingsSuper, HRAdminSuper, RolesSuper, SystemLogsSuper, AdminStaffSuper;

if (window.location.href) {
  var str2 = window.location.href.split("/");
  if (str2.length <= 4 && str2[3] != 'super_admin') {
    ten_path = '/pages/auth/page404';
    file_ten_path = "";
    dashboardR = "/auth/404";
  } else {
    var url = window.location.pathname;

    var str = url.split("/");

    if (login_role != null) {


      var module_seen = login_role.split("_");

      // alert(module_seen);


      if (module_seen.includes("4")) {
        PromotionSuper = '1';

      }
      else {
        PromotionSuper = '0';
      }





      if (module_seen.includes("1")) {
        SettingsSuper = '1';

      }
      else {
        SettingsSuper = '0';
      }

      if (module_seen.includes("2")) {
        HRAdminSuper = '1';

      }
      else {
        HRAdminSuper = '0';
      }


      if (module_seen.includes("3")) {

      }
      else {
      }



      if (module_seen.includes("5")) {
        RolesSuper = '1';

      }
      else {
        RolesSuper = '0';
      }


      //SystemLogsSuper,AdminStaffSuper
      if (module_seen.includes("6")) {
        SystemLogsSuper = '1';

      }
      else {
        SystemLogsSuper = '0';
      }




      if (module_seen.includes("7")) {
        AdminStaffSuper = '1';

      }
      else {
        AdminStaffSuper = '0';
      }






      //alert(PromotionSuper);







    }
    if (str[1] && str[1] != 'undefined' && str[2] && str[2] != 'undefined') {
      ten_path = str[1] + '/' + str[2];
      file_ten_path = "/" + ten_path;
      //alert(login_type);
      if (login_type == 'emp') {
        //alert('hii');
        dashboardR = "/" + ten_path + "/employee/profileinfo";

      }
      else if (login_type == 'security') {
        //alert('hii');
        if (hr_emp_guests == 'true') {
          dashboardR = "/" + ten_path + "/guests/listing";

        }
        else {
          dashboardR = "/" + ten_path + "/attendance/ListingCheckoutSecurity";

        }

      }
      else {
        dashboardR = "/" + ten_path + "/dashboard";
      }

    } else if (str[1] && str[1] != 'undefined') {
      ten_path = str[1];
      file_ten_path = "/" + ten_path;
      dashboardR = "/" + ten_path + "/dashboard";
    }
  }

}
//alert(dashboardR);


// if(str[3] && str[4] && str[4] != 'undefined' && str[3] != 'undefined' ){  alert(1);
//   ten_path = str[3]+'/'+ str[4]; 
//   file_ten_path  = "/"+ten_path ;
//   dashboardR = "/"+ten_path+"/dashboard";
// } else if(str[3]){ alert(2);
//   ten_path = str[3];
//   file_ten_path  = "/"+ten_path ;
//   dashboardR = "/dashboard";
// } else { alert(3);
//   ten_path = '';
//   file_ten_path='';
//   dashboardR = "/dashboard";
// }    


// Routes


const landingRoutesNew = {
  path: "/career/:id",
  name: 'Job Frontend Url',
  icon: faHome,
  target: '_blank',
  component: LandingCareer,
  children: null
};
/*const landingRoutesNewCompany = {            
  path: "/careercompany/:id",
  name:'Job Frontend Url',
   icon: faHome,
   target:'_blank',
  component: LandingCareerCompany,
  children: null
};*/

const landingRoutesNewCompany = {
  path: "/careercompany/:id",
  name: 'Career',
  icon: faHome,
  target: '_blank',
  component: LandingCareerCompany,
  children: null
};
let landingRoutesNewCompanyEmp;
if (login_type == 'emp' && hr_emp_careeer == 'true') {
  landingRoutesNewCompanyEmp = {
    path: "/careercompany/" + company_name,
    name: 'Career',
    icon: faHome,
    target: '_blank',
    component: LandingCareerCompany,
    children: null
  };
}
else {
  landingRoutesNewCompanyEmp = {
    path: "x",
  };
}
let landingRoutes;
if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0 && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin'

  || ejurl == '1' && login_type == 'sub_admin'
) {
  landingRoutes = {
    path: file_ten_path + "/careers",
    name: 'Job Frontend Url',
    icon: faHome,
    target: '_blank',
    component: Landing,
    children: null
  };

} else {
  landingRoutes = {
    path: "x",
  };

}


let dashboardRoutes;
if (login_type != 'emp' && login_type != 'security') {
  dashboardRoutes = {
    path: dashboardR,
    name: 'Dashboard',
    //  header: "Main",
    icon: faHome,
    component: Default,
    children: null
  };
}
else if (login_type == 'security') {

  if (hr_emp_guests == 'true') {
    dashboardRoutes = {
      path: file_ten_path + "/guests/listing",
      name: 'Check In Out',
      icon: faUserFriends,
      // header: "Main",
      component: GListing,
      children: null
    };
  }
  else {
    dashboardRoutes = {
      path: file_ten_path + "/attendance/ListingCheckoutSecurity",
      name: 'Employee List',
      icon: faImages,
      component: BSVieww,
      children: null

    };

  }

}
else if (login_type == 'emp') {
  dashboardRoutes = {
    path: file_ten_path + "/employee/profileinfo",
    name: 'My Profile',
    header: "Main",
    icon: faUserTie,
    component: EProfile,
    children: null
  };
  // else{




  // dashboardRoutes = {
  //   path:dashboardR,
  //   name: "Dashboard",
  //   header: "Main",
  //   icon: faHome,
  //   component: Default,
  //   children: null
  // };
}


//for signup req and manage employee modules according to  admin 
//if super admin
// const signupRoutes = {
//   path: "/signup/listing",
//   name: "Signup Requests",
//   icon: faIdCard,
//   component: SListing,
//   children: null
// };

let signupRoutes;
/*if(admin_id ==1 && login_type!='super_admin' || login_type!='emp'){
  signupRoutes ={
    path: "x",
  };
} else if(admin_id ==null){
  
} else {
  signupRoutes ={
    path: file_ten_path+"/signup/listing",
    name: "Signup Requests",
    icon:faUserPlus,
    component: SListing,
    children: null
  };
}
*/


//alert("in index");
//alert(localStorage.getItem('9'));
if (admin_id == 1 && login_id == 1 || login_type == 'super_admin' && admin_id == 1) {

  signupRoutes = {
    path: file_ten_path + "/signup/listing",
    name: 'Signup Requests',
    icon: faUserPlus,
    component: SListing,
    children: null
  };
}
else {
  signupRoutes = {
    path: "x",
  };
}





















// const signupRoutes = (admin_id !=0 ) ? {
//   path: "/dashboard",
//   name: "",
//   icon:'',
//   component: Default,
//   children: null
// }:{
//   path: "/signup/listing",
//   name: "Signup Requests",
//   icon:faIdCard,
//   component: SListing,
//   children: null
// };

let logs;
if (login_type == 'hr_admin' && hr_emp_annoucements == 'true' || login_type == 'sub_admin' && elogs == '1' && hr_emp_annoucements == 'true' || login_type == 'super_admin' && admin_id == 1 && login_id == 1
  || login_type == 'hr_admin' && hr_emp_annoucements == 'true' && pro_package == '0'
) {
  logs = {
    path: file_ten_path + "/logs/listing",
    name: 'System Logs',
    icon: faFolderOpen,
    component: SLListing,
    children: null
  };
}
else {
  logs = {
    path: "x",
  }
}



let profile;
if (login_type == 'emp') {
  profile = {
    path: file_ten_path + "/employee/add",
    name: 'My Profile',
    icon: faUserTie,
    component: EListing,
    children: null

  };
}
else {
  profile = {
    path: "x",
  }
}















// if(admin_id !=0){
// logs =  {
//   path:  file_ten_path+"/logs/listing",
//   name: "System Logs",
//   icon:faFolderOpen,
//   component: SLListing,
//   children: null
// };
// } else if(admin_id == null){
//   logs =  {
//     path:  file_ten_path+"/logs/listing",
//     name: "",
//     icon:'',
//     component: Default,
//     children: null
//   };
// } else {
// logs = {
//   path:  file_ten_path+"/logs/listing",
//   name: "System Logs",
//   icon:faFolderOpen,
//   component: SLListing,
//   children: null
// };
// }
// const logs = (admin_id !=0 ) ? {
//   path: "/dashboard",
//   name: "",
//   icon:'',
//   component: Default,
//   children: null
// }:{
//   path: "/logs/listing",
//   name: "System Logs",
//   icon:faIdCard,
//   component: SLListing,
//   children: null
// };

//if hr admin

let empRoutes;








var arrayemployee = [];

if (login_type == 'sub_admin' && eemp == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arrayemployee.push(
    {
      path: file_ten_path + "/employee/listing",
      name: 'Employee',
      icon: faUserFriends,
      component: EListing,
      children: null
    });
}

if (login_type == 'sub_admin' && ecmp == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arrayemployee.push(
    {
      path: file_ten_path + "/complaints/listing",
      name: 'Comments',
      icon: faVolumeUp,
      component: complaintsListing,
      children: null
    });
}




if (login_type == 'sub_admin' && ep == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arrayemployee.push(
    {
      path: file_ten_path + "/attendance/listing",
      name: 'Present',
      icon: faClock,
      component: AListing,
      children: null
    }
  );
}



if (login_type == 'sub_admin' && ea == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arrayemployee.push(
    {
      path: file_ten_path + "/absent/listing",
      name: 'Absent',
      component: AbsenceListing,
      icon: faUserSlash,
      children: null
    }
  );
}



if (login_type == 'sub_admin' && eleave == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arrayemployee.push(
    {
      path: file_ten_path + "/leaves/listing",
      name: 'Leave',
      icon: faCalendarDay,
      component: LListing,
    }
  );
}



if (login_type == 'sub_admin' && esubadmin == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arrayemployee.push(
    {
      path: file_ten_path + "/admin_staff/listing",
      //  path:  file_ten_path+"/admin_staff/listing",
      name: 'Sub Admin',
      icon: faUserFriends,
      component: adminStaffListing,
      children: null
    }
  );
}



if (login_type == 'sub_admin' && esec == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arrayemployee.push(
    {
      path: file_ten_path + "/admin_staff/listingsecurity",
      name: 'Security',
      icon: faUserFriends,
      component: securityStaffListing,
      children: null
    }
  );
}

if (login_type == 'sub_admin' && empdoc == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arrayemployee.push(
    {
      path: file_ten_path + "/admin_staff/listingdocs",
      name: 'Documents',
      icon: faUserFriends,
      component: DocEmpListing,
      children: null
    }
  );
}











if (login_type == 'sub_admin' && arrayemployee != '') {
  //for employee 
  empRoutes = {
    path: file_ten_path + "/employees",
    name: 'Employee',
    icon: faUserFriends,
    children: arrayemployee,
  };
}

else if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0 && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin') {
  //for employee 
  empRoutes = {
    path: file_ten_path + "/employees",
    name: 'Employee',
    icon: faUserFriends,
    children: [
      {
        path: file_ten_path + "/employee/listing",
        name: 'Employee',
        // icon: faUserFriends,
        component: EListing,
        //  children: null
      },

      {
        path: file_ten_path + "/complaints/listing",
        name: 'Comments',
        icon: faVolumeUp,
        component: complaintsListing,
        children: null
      },

      {
        path: file_ten_path + "/admin_staff/listing",
        //  path:  file_ten_path+"/admin_staff/listing",
        name: 'Sub Admin',
        icon: faUserFriends,
        component: adminStaffListing,
        children: null
      },
      {
        path: file_ten_path + "/admin_staff/listingsecurity",
        name: 'Security',
        icon: faUserFriends,
        component: securityStaffListing,
        children: null
      },
      {
        path: file_ten_path + "/admin_staff/listingdocs",
        name: 'Documents',
        icon: faUserFriends,
        component: DocEmpListing,
        children: null
      },
      {
        path: file_ten_path + "/employee/importsettlementall",
        name: "Settlement Import",
        icon: faUserFriends,
        component: EbSettAddAll,
        children: null
      }
      ,

      {
        path: file_ten_path + "/employee/listingsettlement",
        name: "Settlement",
        icon: faUserFriends,
        component: SettEmpListing,

        children: null
      },
      // {
      //   path: file_ten_path + "/project/ImportTask",
      //   name: "Task Import",
      //   icon: faUserFriends,
      //   component: ImTask,

      //   children: null
      // }
      
      // ,

      {
        path: file_ten_path + "/employee/listingnote",
        name: "Note",
        icon: faUserFriends,
        component: NoteEmpListing,

        children: null
      }











    ]
  };
} else if (admin_id != 1 && login_type != 'super_admin' && pro_package == 0 && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin') {
  empRoutes = {
    path: file_ten_path + "/employees",
    name: 'Employee',
    icon: faUserFriends,
    children: [
      {
        path: file_ten_path + "/employee/listing",
        name: 'Employee',
        icon: faUserFriends,
        component: EListing,
        children: null
      },
      {
        path: file_ten_path + "/leaves/listing",
        name: 'Leave',
        icon: faCalendarDay,
        component: LListing,
      },
      {
        path: file_ten_path + "/admin_staff/listingdocs",
        name: 'Sub Admin',
        icon: faUserFriends,
        component: DocEmpListing,
        children: null
      }
    ]
  };
}
else if (login_type == 'emp' && pro_package != 1 && login_type != 'sub_admin') {
  empRoutes = {
    path: file_ten_path + "/complaints/listing",
    name: 'Comments',
    icon: faVolumeUp,
    component: complaintsListing,
    children: null
  };
}

else {
  empRoutes = {
    path: "x",
  }
}



let ComplaintRoutes;

if (login_type == 'emp') {
  ComplaintRoutes = {
    path: file_ten_path + "/complaints/listing",
    name: 'Comments',
    icon: faExclamationTriangle,
    component: complaintsListing,
    children: null
  };
}

else {
  ComplaintRoutes = {
    path: "x",
  }
}


























//for settings
let SettingRoutes;
let SettingRoutesEmp;
if (login_type == 'emp' && hr_emp_careeer == 'true') {
  SettingRoutesEmp = {
    path: file_ten_path + "/job_descripation/listing",
    name: 'Job Description',
    icon: faBusinessTime,
    component: JobdescListing,
    children: null
    /*  {
        path: file_ten_path+"/department/listing",
         name:TF("{0}", T("Master Department")),
        icon: faVolumeUp,
        component:  MasterDepartmentListing,
        children: null
      }, */
    // ]
  };
}

//let leaveRoutes;

else {
  SettingRoutesEmp = {
    path: "x",
  };
}









//org,dept,jd,roles

if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0 && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin') {
  //alert('hii');
  SettingRoutes = {
    path: file_ten_path + "/settings",
    name: 'Settings',
    icon: faCog,
    children: [
      {
        path: file_ten_path + "/organization/listing",

        name: 'Organization',
        icon: faLandmark,
        component: OListing,
        children: null
      },


      {
        path: file_ten_path + "/payrollheaders/listing",

        name: 'Payroll Headers',
        icon: faLandmark,
        component: PayrollheadersListing,
        children: null
      },


      {
        path: file_ten_path + "/holidays/listing",
        name: 'Public Holiday',
        icon: faVolumeUp,
        component: PublicHolidayListing,
        children: null
      },
      {
        path: file_ten_path + "/department/listing",
        name: 'Master Department',
        icon: faVolumeUp,
        component: MasterDepartmentListing,
        children: null
      },
      {
        path: file_ten_path + "/points/listing",
        name: 'Point Declaration',
        icon: faVolumeUp,
        component: PublicPointListing,
        children: null
      },

      {

        path: file_ten_path + "/saroles/listing",
        name: 'User Role',
        icon: faBlog,
        component: sarolesListing,
        children: null,

      },

      {
        path: file_ten_path + "/leave_type/listing",
        name: 'Leave Types',
        icon: faTable,
        component: leaveTypeListing,
        children: null
      },
      {
        path: file_ten_path + "/adminapp/listing",
        name: 'Admin Apps',
        icon: faMobile,
        component: AdListing,
        children: null
      }
      ,
      {
        path: file_ten_path + "/assets/listing",
        name: 'Assets',
        icon: faBlog,
        component: AssListing,
        children: null,
      },
      {
        path: file_ten_path + "/allownce_type/listing",
        name: 'Settlement Type',
        icon: faBlog,
        component: ALLoListing,
        children: null,
      },
      {
        path: file_ten_path + "/allownce_code/listing",
        name: 'Settlement Code',
        icon: faBlog,
        component: ALLCoListing,
        children: null,
      },
      {
        path: file_ten_path + "/note_code/listing",
        name: 'Note Code',
        icon: faBlog,
        component: NoteListing,
        children: null,
      },
      {
        path: file_ten_path + "/document_type/listing",
        name: 'Document Types',
        icon: faBlog,
        component: DocListing,
        children: null,
      },
      {
        path: file_ten_path + "/employee_groups/listing",
        name: 'Employee Groups',
        //  icon: faVolumeUp,
        component: empGroupListing,
        //  children: null
      },
      {
        path: file_ten_path + "/category_type/listing",
        name: 'Comment Category',
        icon: faBlog,
        component: CattypeListing,
        children: null,
      },
      {
        path: file_ten_path + "/complaint_type/listing",
        name: 'Comment Type',
        icon: faBlog,
        component: CompTypeListing,
        children: null,
      },
      {
        path: file_ten_path + "/blog_type/listing",
        name: 'Blog Type',
        icon: faBlog,
        component: BlogTypeListing,
        children: null,
      },

      {
        path: file_ten_path + "/annoucement_type/listing",
        name: 'Annoucement Type',
        icon: faBlog,
        component: AnnoucementTypeListing,
        children: null,
      },

      {
        path: file_ten_path + "/course_type/listing",
        name: 'Course Type',
        icon: faBlog,
        component: CourseTypeListing,
        children: null,
      },
      {
        path: file_ten_path + "/payrollprivilage/listing",
        name: 'Payroll Privilage',
        icon: faBlog,
        component: PayrollPrivilageListing,
        children: null,
      },
      {
        path: file_ten_path + "/noticepaycheck/listing",
        name: 'Notice Pay Check',
        icon: faBlog,
        component: NoticePayCheckListing,
        children: null,
      },
    ]
  };
} else if (admin_id != 1 && login_type != 'super_admin' && pro_package == 0 && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin') {
  SettingRoutes = {
    path: file_ten_path + "/settings",
    name: 'Settings',
    icon: faCog,
    children: [
      {
        path: file_ten_path + "/organization/listing",
        name: 'Organization',
        icon: faLandmark,
        component: OListing,
        children: null
      },
      {
        path: file_ten_path + "/department/listing",
        name: 'Security',
        icon: faVolumeUp,
        component: MasterDepartmentListing,
        children: null
      },
      {
        path: file_ten_path + "/holidays/listing",
        name: 'Public Holiday',
        icon: faVolumeUp,
        component: PublicHolidayListing,
        children: null
      },
      {
        path: file_ten_path + "/leave_type/listing",
        name: 'Leave Types',
        icon: faTable,
        component: leaveTypeListing,
        children: null
      },
      {
        path: file_ten_path + "/assets/listing",
        name: 'User Role',
        icon: faBlog,
        component: AssListing,
        children: null,
      },
      {
        path: file_ten_path + "/allownce_type/listing",
        name: 'Settlement Type',
        icon: faBlog,
        component: ALLoListing,
        children: null,
      },
      {
        path: file_ten_path + "/allownce_code/listing",
        name: 'Leave Types',
        icon: faBlog,
        component: ALLCoListing,
        children: null,
      },
      {
        path: file_ten_path + "/note_code/listing",
        name: 'Admin Apps',
        icon: faBlog,
        component: NoteListing,
        children: null,
      },
      {
        path: file_ten_path + "/document_type/listing",
        name: 'Document Types',
        icon: faBlog,
        component: DocListing,
        children: null,
      },
      {
        path: file_ten_path + "/employee_groups/listing",
        name: 'Employee Groups',
        //  icon: faVolumeUp,
        component: empGroupListing,
        //  children: null
      },
    ]
  };
} else if (admin_id == 1 && login_id == 1 || login_type == 'super_admin' && login_type != 'emp' && SettingsSuper == '1' && login_id != 1 && login_type != 'security' && login_type != 'sub_admin') {
  SettingRoutes = {
    path: file_ten_path + "/settings",
    name: 'Settings',
    icon: faCog,
    children: [
      {
        path: file_ten_path + "/organization/listing",
        name: 'Organization',
        icon: faLandmark,
        component: OListing,
        children: null
      },
      {
        path: file_ten_path + "/adminapp/listing",
        name: 'Admin Apps',
        icon: faMobile,
        component: AdListing,
        children: null
      }
      , {
        path: file_ten_path + "/payroll_line_entry/adminlisting",
        name: "Payroll Line Entry Admin",
        icon: faMobile,
        component: payrollLineEntryListAdmin,
        children: null
      },
      {
        path: file_ten_path + "/category/listing",
        name: 'Category',
        icon: faBlog,
        component: HrBlogCattypeListing,
        children: null,
      },
      {
        path: file_ten_path + "/subcategory/listing",
        name: 'Sub Category',
        icon: faBlog,
        component: HrBlogSubCattypeListing,
        children: null,
      },
      // {
      //   path: file_ten_path + "/org_group/listing",
      //   name: 'Hr Blog',
      //   icon: faBlog,
      //   component: OrgGrpListing,
      //   children: null,
      // },
    ]
  };
}


else if (login_type == 'emp') {
  SettingRoutes = {
    path: file_ten_path + "/holidays/listing",
    name: 'Public Holiday',
    icon: faVolumeUp,
    component: PublicHolidayListing,
    children: null

  };
}





else {
  SettingRoutes = {
    path: "x",
  }
}






var arraysettings = [];


if (login_type == 'sub_admin' && eorg == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arraysettings.push(
    {
      path: file_ten_path + "/organization/listing",
      name: 'Organization',
      icon: faLandmark,
      component: OListing,
      children: null
    }
  );
}



if (login_type == 'sub_admin' && eholiday == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arraysettings.push(
    {
      path: file_ten_path + "/holidays/listing",
      name: 'Public Holiday',
      icon: faVolumeUp,
      component: PublicHolidayListing,
      children: null
    }
  );
}

if (login_type == 'sub_admin' && edept == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arraysettings.push(
    {
      path: file_ten_path + "/department/listing",
      name: 'Master Department',
      icon: faVolumeUp,
      component: MasterDepartmentListing,
      children: null
    }
  );
}

if (login_type == 'sub_admin' && epoint == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arraysettings.push(
    {
      path: file_ten_path + "/points/listing",
      name: 'Point Declaration',
      icon: faVolumeUp,
      component: PublicPointListing,
      children: null
    }
  );
}



if (login_type == 'sub_admin' && eroles == '1') {
  arraysettings.push(
    {
      path: file_ten_path + "/saroles/listing",
      name: 'User Role',
      icon: faBlog,
      component: sarolesListing,
      children: null,
    }
  );
}
if (login_type == 'sub_admin' && eleavetype == '1') {
  arraysettings.push(
    {
      path: file_ten_path + "/leave_type/listing",
      name: 'Leave Types',
      icon: faTable,
      component: leaveTypeListing,
      children: null
    }
  );
}

if (login_type == 'sub_admin' && eapp == '1') {
  arraysettings.push(
    {
      path: file_ten_path + "/adminapp/listing",
      name: localStorage.getItem('49'),
      icon: faMobile,
      component: AdListing,
      children: null
    }
  );
}


if (login_type == 'sub_admin' && easstes == '1') {
  arraysettings.push(
    {
      path: file_ten_path + "/assets/listing",
      name: localStorage.getItem('50'),
      icon: faBlog,
      component: AssListing,
      children: null,
    }
  );
}


if (login_type == 'sub_admin' && eallowancwtype == '1') {
  arraysettings.push(
    {
      path: file_ten_path + "/allownce_type/listing",
      name: 'Settlement Type',
      icon: faBlog,
      component: ALLoListing,
      children: null,
    }
  );
}


if (login_type == 'sub_admin' && eallowancecode == '1') {
  arraysettings.push(
    {
      path: file_ten_path + "/allownce_code/listing",
      name: 'Settlement Code',
      icon: faBlog,
      component: ALLCoListing,
      children: null,
    }
  );
}

if (login_type == 'sub_admin' && enotecode == '1') {
  arraysettings.push(
    {
      path: file_ten_path + "/note_code/listing",
      name: localStorage.getItem('53'),
      icon: faBlog,
      component: NoteListing,
      children: null,
    }
  );
}


if (login_type == 'sub_admin' && doctype == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arraysettings.push(
    {
      path: file_ten_path + "/document_type/listing",
      name: 'Document Types',
      icon: faLandmark,
      component: DocListing,
      children: null
    }
  );
}

if (login_type == 'sub_admin' && egrp == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arraysettings.push(
    {
      path: file_ten_path + "/employee_groups/listing",
      name: 'Employee Groups',
      icon: faVolumeUp,
      component: empGroupListing,
      children: null
    }
  );
}


if (login_type == 'sub_admin' && empgrp == '1') {

  arraysettings.push(
    {
      path: file_ten_path + "/employee_groups/listing",
      name: 'Employee Groups',
      //  icon: faVolumeUp,
      component: empGroupListing,
      //  children: null
    }
  );
}


if (login_type == 'sub_admin' && compcat == '1') {

  arraysettings.push(
    {
      path: file_ten_path + "/category_type/listing",
      name: 'Comment Category',
      icon: faBlog,
      component: CattypeListing,
      children: null,
    }
  );
}



if (login_type == 'sub_admin' && comptype == '1') {

  arraysettings.push(
    {
      path: file_ten_path + "/complaint_type/listing",
      name: 'Comment Type',
      icon: faBlog,
      component: CattypeListing,
      children: null,
    }
  );
}


if (login_type == 'sub_admin' && bltype == '1') {

  arraysettings.push(
    {
      path: file_ten_path + "/blog_type/listing",
      name: 'Blog Type',
      icon: faBlog,
      component: BlogTypeListing,
      children: null,
    }
  );
}




if (login_type == 'sub_admin' && anntype == '1') {

  arraysettings.push(
    {
      path: file_ten_path + "/annoucement_type/listing",
      name: localStorage.getItem('60'),
      icon: faBlog,
      component: AnnoucementTypeListing,
      children: null,
    }
  );
}

if (login_type == 'sub_admin' && coutype == '1') {

  arraysettings.push(
    {
      path: file_ten_path + "/course_type/listing",
      name: localStorage.getItem('61'),
      icon: faBlog,
      component: CourseTypeListing,
      children: null,
    }
  );
}

if (login_type == 'sub_admin' && arraysettings != '') {
  SettingRoutes = {
    path: file_ten_path + "/settings",
    name: 'Settings',
    icon: faCog,
    children: arraysettings,
  };
}

else if (login_type == 'sub_admin' && eorg == '0' && epoint == '0' && eholiday == '0' && edept == '0' && eapp == '0' && easstes == '0' &&
  eallowancwtype == '0' && eallowancecode == '0' && enotecode == '0' && egrp == '0' && doctype == '0') {
  SettingRoutes = {
    path: "x",
  }
}




//alert(login_type);

















































// const empRoutes = {
//   path: "/employee/listing",
//   name: "Employee",
//   icon: faUserFriends,
//   component:  EListing,
//   children: null
//   };

const empRoutes1 = {
  path: "/employee/listing",
  name: 'Employee',
  icon: faUserFriends,
  component: EListing,
  children: [
    {
      path: file_ten_path + "/employee/add",
      // name: "Add",
      component: EAdd
    },
    {
      path: file_ten_path + "/employee/profile",
      // name: "Add",
      component: EProfilee
    },
  ]
};

//for employee bulk import
// const empBulkRoutes = {
//   path: "/employee_bulk_import/validation",
//   name: "Employee Bulk Import",
//   icon: faUserFriends,
//   component:  EbAdd,
//   children: null
//   };

let empBulkRoutes;
if (admin_id != 1 || login_type != 'super_admin') {
  empBulkRoutes = {
    path: file_ten_path + "/employee_bulk_import/import",
    name: 'Import',
    icon: faUserFriends,
    component: EbAdd,
    children: null
  };
} else if (admin_id == null) {
  empBulkRoutes = {
    path: file_ten_path + "/employee_bulk_import/import",
    name: "",
    icon: '',
    component: Default,
    children: null
  };
} else {
  empBulkRoutes = {
    path: file_ten_path + "/employee_bulk_import/import",
    name: "",
    icon: '',
    children: null
  }
}

let empPayrollNoteBulkRoutes;
empPayrollNoteBulkRoutes = {
  path: file_ten_path + "/payroll_note_import/import",
  name: 'Import',
  icon: faUserFriends,
  component: EbAddPayrollNote,
  children: null
};

let empSettlementBulkRoutes;
if (admin_id != 1 || login_type != 'super_admin') {
  empSettlementBulkRoutes = {
    path: file_ten_path + "/employee_bulk_import/importsettlement",
    name:"Import Settlement",
    icon: faUserFriends,
    component: EbSettAdd,
    children: null
  };
} else if (admin_id == null) {
  empSettlementBulkRoutes = {
    path: file_ten_path + "/employee_bulk_import/importsettlement",
    name: "",
    icon: '',
    component: Default,
    children: null
  };
} else {
  empSettlementBulkRoutes = {
    path: file_ten_path + "/employee_bulk_import/importsettlement",
    name: "",
    icon: '',
    children: null
  }
}


let empSettlementBulkRoutesAll;
if (admin_id != 1 || login_type != 'super_admin') {
  empSettlementBulkRoutesAll = {
    path: file_ten_path + "/employee/importsettlementall",
    name:"Import Settlement",
    icon: faUserFriends,
    component: EbSettAddAll,
    children: null
  };
} else if (admin_id == null) {
  empSettlementBulkRoutesAll = {
    path: file_ten_path + "/employee/importsettlementall",
    name: "",
    icon: '',
    component: Default,
    children: null
  };
} else {
  empSettlementBulkRoutesAll = {
    path: file_ten_path + "/employee/importsettlementall",
    name: "",
    icon: '',
    children: null
  }
}

let holidayBulkRoutes;
if (admin_id != 1 || login_type != 'super_admin') {
  holidayBulkRoutes = {
    path: file_ten_path + "/holiday_bulk_import/import",
    name:"Import Settlement",
    icon: faUserFriends,
    component: EhAdd,
    children: null
  };
} else if (admin_id == null) {
  holidayBulkRoutes = {
    path: file_ten_path + "/holiday_bulk_import/import",
    name: "",
    icon: '',
    component: Default,
    children: null
  };
} else {
  holidayBulkRoutes = {
    path: file_ten_path + "/holiday_bulk_import/import",
    name: "",
    icon: '',
    children: null
  }
}
















const jobdisRoutes = {
  path: file_ten_path + "/jobdisclouser",

  name: 'Job Disclosure',
  icon: faLandmark,
  component: Jobdisc,
  children: null
};

const payrollHeadersRoutes = {
  path: file_ten_path + "/payrollheaders/listing",
  name: 'Payroll Headers',
  icon: faLandmark,
  component: PayrollheadersListing,
  children: null
};

const payrollHeadersRoutes1 = {
  path: file_ten_path + "/payrollheaders/add",
  name: 'Payroll Headers',
  icon: faLandmark,
  component: PayrollheadersAdd,
  children: null
};


const orgRoutes = {
  path: file_ten_path + "/organization/listing",
  name: localStorage.getItem("22"),
  icon: faLandmark,
  component: OListing,
  children: null
};

const orgRoutes1 = {
  path: "/organization/listing",
  name: TF("{0}", T("Organization")),
  icon: faLandmark,
  component: OListing,
  children: [
    {
      path: file_ten_path + "/organization/add",
      // name: "Add",
      component: OAdd
    },
    {
      path: file_ten_path + "/organization/dlisting",
      // name: "Add",
      component: ODLeaves
    },
  ]
};



let attendanceRoutes;
/* if(admin_id !=1 && login_type!='super_admin' && login_type!='emp' && login_type!='security' && login_type!='sub_admin' || ep=='1' && login_type=='sub_admin'){
   attendanceRoutes = {
    path: file_ten_path+"/attendance/listing",
    name: "Present",
    icon: faClock,
    component:  AListing,
    children: null
    };

 } 
else 
 
 
*/

if (admin_id != 1 && login_type != 'super_admin' && login_type == 'emp' && login_type != 'security' && login_type != 'sub_admin') {
  attendanceRoutes = {
    path: file_ten_path + "/attendance/listing",
    name: 'My Attendance',
    icon: faClock,
    component: AListing,
    children: null
  };

}

else {
  attendanceRoutes = {
    path: "x",
  };

}



let guestRoutes;
//    if(admin_id !=1 && login_type!='super_admin' && login_type!='emp'){
//      guestRoutes = {
//       path: file_ten_path+"/guests/listing",
//       name: "Guests",
//       icon: faUserFriends,
//       component:GListing,
//       children: null
//       };

//    } 
//  else {
//      guestRoutes = {
//       path: "x",
//       };

//    } 

//alert(eincident);alert(echeck);
if (login_type == 'sub_admin' && echeck == '1' && eincident == '1' && hr_emp_guests == 'true') {
  //alert('1');
  guestRoutes = {
    path: file_ten_path + "/guests",
    name: 'Visitor',
    icon: faUserFriends,
    children: [
      {
        path: file_ten_path + "/guests/listing",
        name: 'Check In Out',
        icon: faUserFriends,
        component: GListing,
        children: null
      },
      {
        path: file_ten_path + "/incident/listing",
        name: 'Incident Report',
        icon: faImages,
        component: IListing,
        children: null
      },

    ]
  };


}
else if (login_type == 'sub_admin' && echeck == '1' && hr_emp_guests == 'true') {
  // alert('2');
  // alert(echeck);alert(hr_emp_guests);alert(eincident);

  guestRoutes = {
    path: file_ten_path + "/guests",
    name: 'Visitor',
    icon: faUserFriends,
    children: [
      {
        path: file_ten_path + "/guests/listing",
        name: 'Check In Out',
        icon: faUserFriends,
        component: GListing,
        children: null
      },
      //   {
      // path: file_ten_path+"/incident/listing",
      // name: "Incident Report",
      // icon:faImages,
      // component: IListing,
      // children: null
      //   },

    ]
  };


}
else if (login_type == 'sub_admin' && eincident == '1' && hr_emp_guests == 'true') {
  //  alert('3');
  guestRoutes = {
    path: file_ten_path + "/guests",
    name: 'Visitor',
    icon: faUserFriends,
    children: [
      // {
      //   path: file_ten_path+"/guests/listing",
      //   name: "Check In Out",
      //   icon: faUserFriends,
      //   component:GListing,
      //   children: null
      // } ,
      {
        path: file_ten_path + "/incident/listing",
        name: 'Incident Report',
        icon: faImages,
        component: IListing,
        children: null
      },

    ]
  };


}

else if (admin_id != 1 && login_type != 'super_admin' && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin' && hr_emp_guests == 'true'

  || login_type == 'hr_admin' && hr_emp_guests == 'true' && pro_package == '0') {
  //alert('4');

  guestRoutes = {
    path: file_ten_path + "/guests",
    name: 'Visitor',
    icon: faUserFriends,
    children: [
      {
        path: file_ten_path + "/guests/listing",
        name: 'Check In Out',
        icon: faUserFriends,
        component: GListing,
        //children: null
        children: null
      },
      {
        path: file_ten_path + "/incident/listing",
        name: 'Incident Report',
        // icon:faImages,
        component: IListing,
        // children: null
      },

    ]
  };


}
else {
  // alert("5");
  guestRoutes = {
    path: "x",
  };

}


let workplanroutes;


var arrayworkplan = [];

if (login_type == 'sub_admin' && eprojects == '1' && hr_work_plan == 'true') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arrayworkplan.push({
    path: file_ten_path + "/projects/listing",

    name: 'Projects',
    icon: faUserFriends,
    component: ProjectListing,
    children: null
  });
}

// else
// {
//   workplanroutestask = {
//    path: "x",
//    };

// }

// if (login_type == 'sub_admin' && etasks == '1' && hr_work_plan == 'true') {
//   // workplanroutesprojects =



//   arrayworkplan.push({
//     path: file_ten_path + "/projecttasks/listing",
//     name: 'Project Task',
//     icon: faImages,
//     component: ProjecttasksListing,
//     children: null
//   });







// }

// else
// {
//   workplanroutesprojects = {
//    path: "x",
//    };

// }


//alert(hr_work_plan);



let reportRoutes;

if (login_type == 'hr_admin' || login_type == 'sub_admin' ) {

  reportRoutes = {
    path: file_ten_path + "/reports",
    name: 'Reports',
    icon: faBook,
    //children: arrayworkplan,
    children: [
      // {
      //   path: file_ten_path + "/reports/payrollReport",
      //   name: 'Payrol Reports',
      //   icon: faUserFriends,
      //   component: payrollReports,
      //   children: null
      // },
      {
        path: file_ten_path + "/reports/payrollReport",
        name: 'Payroll Reports',
        icon: faUserFriends,
        component: payrollReports,
        children: null
      },
      {
        path: file_ten_path + "/reports/noticePayReport",
        name: 'Notice Pay Reports',
        icon: faUserFriends,
        component: noticePayReport,
        children: null
      },
      {
        path: file_ten_path + "/reports/staffingReport",
        name: 'Staff IN OUT Reports',
        icon: faUserFriends,
        component: staffingReports,
        children: null
      },
      {
        path: file_ten_path + "/reports/leaveReport",
        name: 'Leave Reports',
        icon: faUserFriends,
        component: LeaveReports,
        children: null
      },
    ]
  };

}
else {
  reportRoutes = {
    path: "x",
  };

}









if (login_type == 'sub_admin' && hr_work_plan == 'true' && arrayworkplan != '') {

  workplanroutes = {
    path: file_ten_path + "/projects",
    name: 'Work Plan',
    icon: faProjectDiagram,
    children: arrayworkplan,
  };


}

else if (login_type != 'emp' && login_type != 'security' && login_type != 'super_admin' && login_type != 'sub_admin' 
&& hr_work_plan == 'true' || login_type == 'hr_admin' && hr_work_plan == 'true' && pro_package == '0') {

  workplanroutes = {
    path: file_ten_path + "/projects",
    name: 'Work Plan/KPI',
    icon: faProjectDiagram,
    component: ProjectListing,
    //children: null
    children: [
      {
        path: file_ten_path + "/projects/listing",
        name: 'Work Plan',
      //  icon: faUserFriends,
        component: ProjectListing,
        children: null
      }
      , {
        path: file_ten_path + "/projects/projectsReport",
        name: 'KPI Project',
        component: projectsReports,
        children: null
      },
      
   

    ]
  };


}
else if (login_type == 'emp') {

  workplanroutes = {
    path: file_ten_path + "/projects",
    name: 'Work Plan/KPI',
    icon: faProjectDiagram,
    component: ProjectListing,
    //children: null
    children: [
      {
        path: file_ten_path + "/projects/listing",
        name: 'Work Plan',
      //  icon: faUserFriends,
        component: ProjectListing,
        children: null
      }
      // , {
      //   path: file_ten_path + "/projects/projectsReport",
      //   name: 'KPI Project',
      //   component: projectsReports,
      //   children: null
      // },
      
   

    ]
  };


}
else {
  workplanroutes = {
    path: "x",
  };

}

let employeeEvalutionReport;

if (login_type == 'hr_admin' || login_type == 'sub_admin' ) {

  employeeEvalutionReport = {
    path: file_ten_path + "/evalutions/employeeEvalution",
    name: 'Employee Evalution',
    component: EmployeeEvalutionReports,
    icon: faAward,
    // component: ProjectListing,
    //children: null
    children:null
  };


}
else {
  employeeEvalutionReport = {
    path: "x",
  };

}



const workpjectroutes1 = {
  path: file_ten_path + "/projects/listing",
  name: 'Check In Out',
  icon: faUserFriends,
  component: ProjectListing,
  children: [
    // {
    //     path: file_ten_path+"/guests/view",
    //    // name: "Add",
    //     component: GView
    //   },
    {
      path: file_ten_path + "/projects/add",
      // name: "Add",
      component: ProjectAdd
    },
    {
      path: file_ten_path + "/project/ImportTask",
      name: "Task Import",
      icon: faUserFriends,
      component: ImTask,

      children: null
    }
    
    // {
    //   path: file_ten_path+"/attendance/checkinoutsecurity",
    //  // name: "Add",
    //  component: BSView,
    // },
    // {
    //   path: file_ten_path+"/attendance/morechecinout",
    //  // name: "Add",
    //  component: BSSView,
    // },







  ]
};

const workreport1 = {
  path: file_ten_path + "/evalutions/employeeEvalution",
  name: 'Employee Evalution Report',
  component: EmployeeEvalutionReports,
  children: [
    // {
    //     path: file_ten_path+"/guests/view",
    //    // name: "Add",
    //     component: GView
    //   },
    {
      path: file_ten_path + "/repots/add",
      // name: "Add",
      component: EmployeeEvalutionVation
    },
  ]
};

const workpjectroutestasks1 = {
  path: file_ten_path + "/projectstasks/listing",
  name: 'Project Task',
  icon: faUserFriends,
  component: ProjecttasksListing,
  children: [
    // {
    //     path: file_ten_path+"/guests/view",
    //    // name: "Add",
    //     component: GView
    //   },
    {
      path: file_ten_path + "/projectstasks/add",
      // name: "Add",
      component: ProjecttasksAdd
    },
    // {
    //   path: file_ten_path+"/attendance/checkinoutsecurity",
    //  // name: "Add",
    //  component: BSView,
    // },
    // {
    //   path: file_ten_path+"/attendance/morechecinout",
    //  // name: "Add",
    //  component: BSSView,
    // },







  ]
};












// const attendanceRoutes = {
//   path: "/attendance/listing",
//   name: "Attendance",
//   icon: faClock,
//   component:  AListing,
//   children: [
//     {
//       path: "/attendance/listing",
//       name: "Attendance",
//       component:  AListing,
//       children: [
//         {
//           path: "/attendance/add/:id",
//          // name: "Add",
//           component: AAdd
//         },
//         {
//           path: "/attendance/view/:id",
//          // name: "Add",
//           component: AView
//         },
//       ]
//     },
//     {
//       path: "/absent/listing",
//       name: "Attendance Absence",
//       component:  AbsenceListing,
//       children: [
//         {
//           path: "/attendance/add/:id",
//          // name: "Add",
//           component: AAdd
//         },
//         {
//           path: "/attendance/view/:id",
//          // name: "Add",
//           component: AView
//         }
//       ]
//     }
//   ]
//   };

const attendanceRoutes1 = {
  path: file_ten_path + "/attendance/listing",
  name: 'Employee Presense',
  icon: faClock,
  component: AListing,
  children: [
    {
      path: file_ten_path + "/attendance/add",
      // name: "Add",
      component: AAdd
    },
    {
      path: file_ten_path + "/attendance/view",
      // name: "Add",
      component: AView
    },
    {
      path: file_ten_path + "/attendance/dlisting",
      // name: "Add",
      component: ADLeaves
    },
  ]
};
const guestRoutes1 = {
  path: file_ten_path + "/guests/listing",
  name: 'Check In Out',
  icon: faUserFriends,
  component: GListing,
  children: [
    {
      path: file_ten_path + "/guests/view",
      // name: "Add",
      component: GView
    },
    {
      path: file_ten_path + "/guests/checkinout",
      // name: "Add",
      component: GCheckinout
    },
    {
      path: file_ten_path + "/attendance/checkinoutsecurity",
      // name: "Add",
      component: BSView,
    },
    {
      path: file_ten_path + "/attendance/morechecinout",
      // name: "Add",
      component: BSSView,
    },







  ]
};

//for absent employees  listing
let absentRoutes;
if (admin_id != 1 && login_type != 'super_admin' && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin' || ea == '1' && login_type == 'sub_admin') {
  absentRoutes = {
    path: file_ten_path + "/absent/listing",
    name: 'Absent',
    component: AbsenceListing,
    icon: faUserSlash,
    children: null
  };
} else {
  absentRoutes = {
    path: "x",
  };
}

const absentRoutes1 = {
  path: file_ten_path + "/absent/listing",
  name: 'Absent',
  icon: faClock,
  component: AbsenceListing,
  children: [
    {
      path: file_ten_path + "/attendance/add/:id",
      // name: "Add",
      component: AAdd
    },
    {
      path: "/attendance/view/:id",
      // name: "Add",
      component: AView
    },
  ]
};


//for laeaves
let leaveRoutes;

//let leaveRoutes;
// if(login_type=='sub_admin' && eleave=='1' && eleavetype=='1'){
//    leaveRoutes = {
//     //path: file_ten_path+"/leaves/listing",
//     path: file_ten_path+"/leaves",
//     name: "Leave",
//     icon: faCalendarDay,
//     //component:  LListing,
//     //children: null
//     children: [
//       {
//         path: file_ten_path+"/leave_type/listing",
//         name:TF("{0} {1}", T("Leave") , T("Types")),
//         icon: faTable,
//         component:  leaveTypeListing,
//         children: null
//       },

//   {
//     path: file_ten_path+"/leaves/listing",
//     name: "Leave",
//     icon: faCalendarDay,
//     component:  LListing,
//     //children: null
//       },
//     ]
//     };
// } 
// else if(login_type=='sub_admin' && eleavetype=='1'){
//   leaveRoutes = {
//     //path: file_ten_path+"/leaves/listing",
//     path: file_ten_path+"/leaves",
//     name: "Leave",
//     icon: faCalendarDay,
//     //component:  LListing,
//     //children: null
//     children: [
//       {
//         path: file_ten_path+"/leave_type/listing",
//         name:TF("{0} {1}", T("Leave") , T("Types")),
//         icon: faTable,
//         component:  leaveTypeListing,
//         children: null
//       },
//     ]
//     };
// }
// else if(login_type=='sub_admin' && eleave=='1'){
//   leaveRoutes = {
//     //path: file_ten_path+"/leaves/listing",
//     path: file_ten_path+"/leaves",
//     name: "Leave",
//     icon: faCalendarDay,
//     //component:  LListing,
//     //children: null
//     children: [
//       {
//     path: file_ten_path+"/leaves/listing",
//     name: "Leave",
//     icon: faCalendarDay,
//     component:  LListing,
//     //children: null
//       },
//     ]
//     };
// }





















// else if(admin_id !=1 && login_type!='super_admin' && login_type!='emp' && login_type!='security' && login_type!='sub_admin'){
//    leaveRoutes = {
//     //path: file_ten_path+"/leaves/listing",
//     path: file_ten_path+"/leaves",
//     name: "Leave",
//     icon: faCalendarDay,
//     //component:  LListing,
//     //children: null
//     children: [
//       {
//         path: file_ten_path+"/leave_type/listing",
//         name:TF("{0} {1}", T("Leave") , T("Types")),
//         icon: faTable,
//         component:  leaveTypeListing,
//         children: null
//       },

//   {
//     path: file_ten_path+"/leaves/listing",
//     name: "Leave",
//     icon: faCalendarDay,
//     component:  LListing,
//     //children: null
//       },
//     ]
//     };
// } 
// else
if (login_type == 'emp') {
  leaveRoutes = {
    path: file_ten_path + "/leaves/listing",
    name: 'My Leaves',
    icon: faCalendarDay,
    component: LListing,
    children: null
  };
}
else {
  leaveRoutes = {
    path: "x",
  };
}


const leaveRoutes1 = {
  path: file_ten_path + "/leaves/listing",
  name: 'My Leaves',
  icon: faCalendarDay,
  component: LListing,
  children: [
    {
      path: file_ten_path + "/leaves/add",
      // name: "Add",
      component: LAdd
    },

    {
      path: file_ten_path + "/leaves/dlisting",
      // name: "Add",
      component: EDLeaves
    },





  ]
};


//for Announcements
let announcementRoutes;
if (admin_id != 1 && login_type != 'super_admin' && login_type != 'security' && login_type != 'sub_admin' || eann == '1' && login_type == 'sub_admin') {
  announcementRoutes = {
    path: file_ten_path + "/announcements/listing",
    name: 'Announcements',
    icon: faVolumeUp,
    component: AnListing,
    children: null
  };
} else {
  announcementRoutes = {
    path: "x",
  };
}


const announcementRoutes1 = {
  path: file_ten_path + "/announcements/listing",
  name: 'Announcements',
  icon: faVolumeUp,
  component: AnListing,
  children: [
    {
      path: file_ten_path + "/announcements/add",
      // name: "Add",
      component: AnAdd
    },
  ]
};






//for Adminapp
/*  let adminappRoutes;
  if(admin_id !=1 || login_type!='super_admin'){ 
    adminappRoutes = {
      path: file_ten_path+"/adminapp/listing",
      name: "Admin App",
      icon: faMobile,
      component:  AdListing,
      children: null
      };
  } else {
    adminappRoutes = {
      path: "x",
      };
  }*/


let promotionRoutes;
if (admin_id != 1 && login_type != 'super_admin' && hr_emp_promotions == 'true' && login_type != "emp" && login_type != 'security' && login_type != 'sub_admin' || eprom == '1' && login_type == 'sub_admin'
  || login_type == 'hr_admin' && hr_emp_promotions == 'true' && pro_package == '0'
) {
  promotionRoutes = {
    path: file_ten_path + "/promotions/listing",
    name: 'Business Promotions',
    icon: faImages,
    component: PListing,
    children: null
  };
} else if (admin_id != 1 && login_type != 'super_admin' && hr_emp_promotions == 'false' && login_type != "emp" || login_type == 'security' || login_type == 'sub_admin') {
  promotionRoutes = {
    path: "x",
  };
}
else if (admin_id == null || login_type == "emp" || login_type == 'super_admin' && admin_id == 1 && PromotionSuper == '0' && login_id != 1 || login_type == 'security' || login_type == 'sub_admin') {
  promotionRoutes = {
    path: "x",
  };
}
else if (login_type == 'super_admin' && admin_id == 1 && PromotionSuper == '1' && login_id != 1) {
  promotionRoutes = {
    path: file_ten_path + "/promotions/listing",
    name: 'Business Promotions',
    icon: faImages,
    component: PListing,
    children: null
  };
}
else {
  promotionRoutes = {
    path: file_ten_path + "/promotions/listing",
    name: 'Business Promotions',
    icon: faImages,
    component: PListing,
    children: null
  };
}
// const promotionRoutes = (admin_id !=0 && admin_id == null) ? {
//   path: "/dashboard",
//   name: "",
//   icon:'',
//   component: Default,
//   children: null
// }:{
//   path: "/promotions/listing",
//   name: "Manage Promotions",
//   icon:faImages,
//   component: PListing,
//   children: null
// };

const promotionRoutes1 = {
  path: file_ten_path + "/promotions/listing",
  name: 'Business Promotions',
  icon: faImages,
  component: PListing,
  children: [
    {
      path: file_ten_path + "/promotions/add",
      // name: "Add",
      component: PAdd
    },
  ]
};






// let adminappRoutes;
// if(admin_id ==1 && login_type!='super_admin'){
//   //alert(login_type);
//   //alert('1');
//   adminappRoutes = {
//       path: file_ten_path+"/adminapp/listing",
//         name: "Admin Apps",
//         icon: faMobile,
//         component:  AdListing,
//         children: null
//         };
//   } 

//  else{
//   adminappRoutes = {
//     path:"x",
//   };
// } 

const adminappRoutes1 = {
  path: file_ten_path + "/adminapp/listing",
  name: localStorage.getItem("83"),
  icon: faVolumeUp,
  component: AdListing,
  children: [
    {
      path: file_ten_path + "/adminapp/add",
      // name: "Add",
      component: AdAdd
    },
  ]
};


const adminappRoutes = {
  path: file_ten_path + "/adminapp/listing",
  name: localStorage.getItem("83"),
  icon: faVolumeUp,
  component: AdListing,
  children: null,
};


const payrollroutes = {
  path: file_ten_path + "/payroll_line_entry/adminlisting",
  name: 'Payroll Line Entry Admin',
  icon: faVolumeUp,
  component: payrollLineEntryListAdmin,
  children: null,
};
const payrollroutes1 = {
  path: file_ten_path + "/payroll_line_entry/adminlisting",
  name: 'Payroll Line Entry Admin',
  icon: faVolumeUp,
  component: payrollLineEntryListAdmin,
  children: [
    {
      path: file_ten_path + "/payroll_line_entry/addadminp",
      // name: "Add",
      component: payrollLineEnrtyAddAdmin
    },
    {
      path: file_ten_path + "/payroll_line_entry/import_payslip_data",
      // name: "Add",
      component: payslipImport

    },

    {
      path: file_ten_path + "/payroll_line_entry/data",
      // name: "Add",
      // component: payslipImportExport
      component: payslipImportExportBefore

    },

    {
      path: file_ten_path + "/payroll_line_entry/datanp",
      // name: "Add",
      // component: payslipImportExport
      component: payslipImportExportBeforeNp

    },

    {
      path: file_ten_path + "/payroll_line_entry/dataprocessedit",
      // name: "Add",
      component: payslipImportExportEdit
      // component: payslipImportExport

    },

    {
      path: file_ten_path + "/payroll_line_entry/dataprocess",
      // name: "Add",
      component: payslipImportExport
      // component: payslipImportExport

    },


    {
      path: file_ten_path + "/payroll_line_entry/reoprt",
      // name: "Add",
      component: payrollreport

    },
    {
      path: file_ten_path + "/payroll_line_entry/allreports",
      // name: "Add",
      component: allPayrollreport

    },

    {
      path: file_ten_path + "/payroll_line_entry/listingtest",
      // name: "Add",
      component: payrollLineEntryListTest

    },

    {
      path: file_ten_path + "/payslip/Payslip_pdf/:id",
      // name: "Add",
      component: payslippdf

    },
    {
      path: file_ten_path + "/payslip/View_payslip/:id/:id2",
      // name: "Add",
      component: viewPayslip

    }
    ,
    {
      path: file_ten_path + "/payslip/Payrolltable",
      // name: "Add",
      component: Payrolltable

    }
    ,
    {
      path: file_ten_path + "/payroll/PaidInstruction",
      // name: "Add",
      component: paidInstruction

    }
    ,
    {
      path: file_ten_path + "/payroll/importpaid",
      // name: "Add",
      component: paidImport

    }
  ]
};





//public holiday menu 
const holidayRoutes = {
  path: file_ten_path + "/holidays/listing",
  name: localStorage.getItem("44"),
  icon: faVolumeUp,
  component: PublicHolidayListing,
  children: null
};
const holidayRoutes1 = {
  path: "/holidays/listing",
  name: localStorage.getItem("44"),
  icon: faVolumeUp,
  component: PublicHolidayListing,
  children: [
    {
      path: file_ten_path + "/holidays/add",
      // name: "Add",
      component: PublicHolidayAdd
    },
  ]
};

//public holiday menu 
const DepartmentRoutes = {
  path: file_ten_path + "/department/listing",
  name: localStorage.getItem("45"),
  icon: faVolumeUp,
  component: MasterDepartmentListing,
  children: null
};
const DepartmentRoutes1 = {
  path: "/department/listing",
  name: localStorage.getItem("45"),
  icon: faVolumeUp,
  component: MasterDepartmentListing,
  children: [
    {
      path: file_ten_path + "/department/add",
      // name: "Add",
      component: MasterDepartmentAdd
    },
    {
      path: file_ten_path + "/department/dlisting",
      // name: "Add",
      component: DDLeaves
    },

  ]
};

// employee group menu 
const empGroupRoutes = {
  path: file_ten_path + "/employee_groups/listing",
  name: localStorage.getItem("55"),
  icon: faVolumeUp,
  component: empGroupListing,
  children: null
};
const empGroupRoutes1 = {
  path: file_ten_path + "/employee_groups/listing",
  name: localStorage.getItem("55"),
  icon: faVolumeUp,
  component: empGroupListing,
  children: [
    {
      path: file_ten_path + "/employee_groups/add",
      // name: "Add",
      component: empGroupAdd
    },
  ]
};


// COMPLAINTS menu 
const complaintsRoutes = {
  path: file_ten_path + "/complaints/listing",
  name: 'Comments',
  icon: faVolumeUp,
  component: complaintsListing,
  children: null
};
const complaintsRoutes1 = {
  path: file_ten_path + "/complaints/listing",
  name: 'Comments',
  icon: faVolumeUp,
  component: complaintsListing,
  children: [
    {
      path: file_ten_path + "/complaints/add",
      // name: "Add",
      component: complaintsAdd
    },
    {
      path: file_ten_path + "/complaints/score",
      // name: "Add",
      component: ComplaintScoreListing
    },
  ]
};












// const promotionRoutes = (admin_id !=0 ) ? {
//   path: "/",
//   name: "",
//   icon: '',
//   component:  '',
//   children: null
//   } : {
//     path: "/promotions/listing",
//     name: "Manage Promotions",
//     icon: faImages,
//     component:  PListing,
//     children: null
//     };

// let promotionRoutes;
// if(admin_id == 0){
// //for Promotions
//  promotionRoutes = {
//   path: "/promotions/listing",
//   name: "Manage Promotions",
//   icon: faImages,
//   component:  PListing,
//   children: null
//   };
// } else {
//   //for Promotions
//  promotionRoutes = {
//   path: "/",
//   name: "",
//   icon: '',
//   component:  '',
//   children: null
//   };
// }















let bannerRoutes;
if (admin_id != 1 && login_type != 'super_admin' && login_type != 'security') {
  bannerRoutes = {
    path: file_ten_path + "/banner/listing",
    name: 'Banners',
    icon: faImages,
    component: BAListing,
    children: null
  };
} else if (admin_id != 1 && login_type != 'super_admin' && login_type != 'security') {
  bannerRoutes = {
    path: "x",
  };
} else if (admin_id == null) {
  bannerRoutes = {
    path: "x",
  };
} else {
  bannerRoutes = {
    path: file_ten_path + "/banner/listing",
    name: 'Banners',
    icon: faImages,
    component: BAListing,
    children: null
  };
}
// const promotionRoutes = (admin_id !=0 && admin_id == null) ? {
//   path: "/dashboard",
//   name: "",
//   icon:'',
//   component: Default,
//   children: null
// }:{
//   path: "/promotions/listing",
//   name: "Manage Promotions",
//   icon:faImages,
//   component: PListing,
//   children: null
// };

const bannerRoutes1 = {
  path: file_ten_path + "/banner/listing",
  name: 'Banners',
  icon: faImages,
  component: BAListing,
  children: [
    {
      path: file_ten_path + "/banner/add",
      // name: "Add",
      component: BAAdd
    },
  ]
};












let empcheckinoutRoutes;
if (login_type == 'security' && hr_emp_guests == 'true') {
  empcheckinoutRoutes = {




    path: file_ten_path + "/attendance/ListingCheckoutSecurity",
    name: 'Employee List',
    icon: faImages,
    component: BSVieww,
    children: null




    // path: file_ten_path+"/attendance/checkinoutsecurity",
    //      name: "Check In Out Of Employee",
    //  icon:faImages,
    //  component: BSView,
    //     children: null
  };
} else {
  empcheckinoutRoutes = {
    path: "x",
  };

}












let incidentRoutes;
if (login_type == 'security' && hr_emp_guests == 'true') {
  incidentRoutes = {
    path: file_ten_path + "/incident/listing",
    name: 'Incident Report',
    icon: faImages,
    component: IListing,
    children: null
  };
} else {
  incidentRoutes = {
    path: "x",
  };

}
let MsgRoutes;
if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0 && login_type != 'emp' && login_type != 'security'
  && login_type != 'sub_admin' && hr_emp_msg == 'true' || emsg == '1' && login_type == 'sub_admin' && hr_emp_msg == 'true' || login_type == 'hr_admin' && hr_emp_msg == 'true' && pro_package == '0'


) {

  MsgRoutes = {
    path: file_ten_path + "/empmsg/listing",
    name: 'Employee Chat',
    icon: faCommentAlt,
    component: MsgListing,
    children: null
  };

}
else if (login_type == 'emp' && pro_package != 0 && hr_emp_msg == 'true') {

  MsgRoutes = {
    path: file_ten_path + "/empmsg/listing",
    name: 'Messages',
    icon: faCommentAlt,
    component: MsgListing,
    children: null
  };

}

else {
  MsgRoutes = {
    path: "x",
  };

}







const incidentRoutes1 = {
  path: file_ten_path + "/incident/listing",
  name: 'Incident Report',
  icon: faImages,
  component: IListing,
  children: [
    {
      path: file_ten_path + "/incident/add",
      // name: "Add",
      component: IAdd
    },
    {
      path: file_ten_path + "/incident/addi",
      // name: "Add",
      component: IAddI
    },
    {
      path: file_ten_path + "/incident/addm",
      // name: "Add",
      component: IAddM
    },
    {
      path: file_ten_path + "/incident/vidieo",
      // name: "Add",
      component: IVidieo
    },
  ]
};

let employetrainingRoutes;
//for courses and topic   



if (login_type == 'sub_admin' && ecourse == '1' && hr_emp_training == 'true') {
  employetrainingRoutes = {
    path: file_ten_path + "/course",
    name: 'Employee Training',
    icon: faChalkboardTeacher,
    children: [
      {
        path: file_ten_path + "/course/listing",
        name: 'Course',
        icon: faChalkboardTeacher,
        component: CourseListing,
        children: null
      },
    ]
  };
}
// else
// {
//   employetrainingRoutes = {
//     path: "x",
//     };

// }
















else if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0 && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin' && hr_emp_training == 'true'

  || login_type == 'hr_admin' && hr_emp_training == 'true' && pro_package == '0'

) {
  employetrainingRoutes = {
    path: file_ten_path + "/course",
    name: 'Employee Training',
    icon: faChalkboardTeacher,
    children: [
      {
        path: file_ten_path + "/course/listing",
        name: 'Course',
        icon: faChalkboardTeacher,
        component: CourseListing,
        children: null
      },
    ]
  };
} else if (login_type == 'emp' && pro_package != 0 && login_type != 'sub_admin' && hr_emp_training == 'true') {



  employetrainingRoutes = {
    //path: file_ten_path+"/pages",
    path: file_ten_path + "/course/listing",
    name: 'Training',
    icon: faChalkboardTeacher,
    component: CourseListing,
    children: null
  };
}

else {
  employetrainingRoutes = {
    path: "x",
  };
}

const employetrainingRoutes1 = {
  path: file_ten_path + "/course/listing",
  name: 'Course',
  icon: faChalkboardTeacher,
  component: CourseListing,
  children: [
    {
      path: file_ten_path + "/course/add",
      // name: "Add",
      component: CourseAdd
    },
    {
      path: file_ten_path + "/chaptors/add",
      // name: "Add",
      component: ChaptorAdd
    },
    {
      path: file_ten_path + "/chaptors/addtopic",
      // name: "Add",
      component: TopicAdd
    },
    {
      path: file_ten_path + "/chaptors/listing",
      // name: "Add",
      component: ChaptorListing
    },
    {
      path: file_ten_path + "/chaptors/vidieo",
      // name: "Add",
      component: Vidieo
    },
    {
      path: file_ten_path + "/application/videoself",
      // name: "Add",
      component: Videoselfjs
    },



    /*{
        path: file_ten_path+"/product/add/:id",
      // name: "Add",
        component: ProductAdd
      },
    {
        path: file_ten_path+"/order/add/:id",
      // name: "Add",
        component: OrderAdd
      },*/
  ]
};
//  const viewRoutes1;

const taxRoutes1 = {
  path: file_ten_path + "payroll_line_entry/taxlisting",
  name: 'Tax Code',
  icon: faBlog,
  component: TaxListing,
  children: [
    {
      path: file_ten_path + "/payroll_line_entry/taxadd",
      // name: "Add",
      component: TaxAdd
    },

  ]
};




let HrblogRoutes;







if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0 && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin'

  || login_type == 'sub_admin' || login_type == 'hr_admin' && pro_package == '0' || login_type == 'super_admin'
) {
  HrblogRoutes = {
    path: file_ten_path + "/org_group/listing",
    name: 'Hr Blog',
    icon: faBlog,
    component: OrgGrpListing,
    children: null,
  };
}

else {
  HrblogRoutes = {

    path: "x",
  };

}

let blogRoutes;
//for courses and topic  

if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0 && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin' && hr_emp_blogs == 'true'

  || eblog == '1' && login_type == 'sub_admin' && hr_emp_blogs == 'true' || login_type == 'hr_admin' && hr_emp_blogs == 'true' && pro_package == '0'
) {
  blogRoutes = {
    path: file_ten_path + "/blog/listing",
    name: 'Blog',
    icon: faBlog,
    component: blogsListing,
    children: null,
  };
}
else if (pro_package != 0 && login_type == 'emp' && hr_emp_blogs == 'true') {
  blogRoutes = {
    path: file_ten_path + "/blog/listing",
    name: 'Organization Blogs',
    icon: faBlog,
    component: blogsListing,
    children: null,
  };
}
else {
  blogRoutes = {

    path: "x",
  };

}




let rolesRoutes;
//for courses and topic  

if (admin_id == 1 && login_id == 1 || login_type == 'super_admin' && admin_id == 1 && RolesSuper == '1') {
  rolesRoutes = {
    path: file_ten_path + "/roles/listing",
    name: localStorage.getItem("95"),
    icon: faBlog,
    component: rolesListing,
    children: null,
  };
}

else {
  rolesRoutes = {
    path: "x",
  };

}


let sarolesRoutes;
//for courses and topic  



if (login_type == 'sub_admin' && eroles == '1') {
  sarolesRoutes = {
    path: file_ten_path + "/saroles/listing",
    name: localStorage.getItem("26"),
    icon: faBlog,
    component: sarolesListing,
    children: null,
  };
}

// else {
//   sarolesRoutes = {
//        path: "x",
//        };

//     }

else if (login_type == 'hr_admin') {
  sarolesRoutes = {
    path: file_ten_path + "/saroles/listing",
    name: localStorage.getItem("26"),
    icon: faBlog,
    component: sarolesListing,
    children: null,
  };
}

else {
  sarolesRoutes = {
    path: "x",
  };

}


let assetsRoutes;
//let assetsRoutes1;
if (login_type == 'hr_admin') {
  assetsRoutes = {
    path: file_ten_path + "/assets/listing",
    name: localStorage.getItem("30"),
    icon: faBlog,
    component: AssListing,
    children: null,
  };
}
else if (login_type == 'sub_admin' && easstes == '1') {
  assetsRoutes = {
    path: file_ten_path + "/assets/listing",
    name: localStorage.getItem("30"),
    icon: faBlog,
    component: AssListing,
    children: null,
  };
}
else {
  assetsRoutes = {
    path: "x",
  };

}
let allowncetypeRoutes;
let documenttypeRoutes;
documenttypeRoutes = {
  path: file_ten_path + "/document_type/listing",
  name: localStorage.getItem("54"),
  icon: faBlog,
  component: DocListing,
  children: null,
};

const documenttypeRoutes1 = {
  path: file_ten_path + "/document_type/listing",
  name: localStorage.getItem("54"),
  icon: faBlog,
  component: DocListing,
  children: [
    {
      path: file_ten_path + "/document_type/add",
      // name: "Add",
      component: DocAdd
    },

  ],
};
//let assetsRoutes1;
if (login_type == 'hr_admin') {
  allowncetypeRoutes = {
    path: file_ten_path + "/allownce_type/listing",
    name: 'Settlement Type',
    icon: faBlog,
    component: ALLoListing,
    children: null,
  };
}
else if (login_type == 'sub_admin' && eallowancwtype == '1') {
  allowncetypeRoutes = {
    path: file_ten_path + "/allownce_type/listing",
    name: 'Settlement Type',
    icon: faBlog,
    component: ALLoListing,
    children: null,
  };
}
else {
  allowncetypeRoutes = {
    path: "x",
  };

}

let categorytypeRoutes;
categorytypeRoutes = {
  path: file_ten_path + "/category_type/listing",
  name: localStorage.getItem("35"),
  icon: faBlog,
  component: CattypeListing,
  children: null,
};
const categorytypeRoutes1 = {
  path: file_ten_path + "/category_type/listing",
  name: localStorage.getItem("35"),
  icon: faBlog,
  component: CattypeListing,
  children: [
    {
      path: file_ten_path + "/category_type/add",
      // name: "Add",
      component: CattypeAdd
    },

  ]
};




let complainttypeRoutes;
complainttypeRoutes = {
  path: file_ten_path + "/complaint_type/listing",
  name: localStorage.getItem("57"),
  icon: faBlog,
  component: CompTypeListing,
  children: null,
};
const complainttypeRoutes1 = {
  path: file_ten_path + "/complaint_type/listing",
  name: localStorage.getItem("57"),
  icon: faBlog,
  component: CompTypeListing,
  children: [
    {
      path: file_ten_path + "/complaint_type/add",
      // name: "Add",
      component: CompTypeAdd
    },

  ]
};

let blogtypeRoutes;
blogtypeRoutes = {
  path: file_ten_path + "/blog_type/listing",
  name: localStorage.getItem("58"),
  icon: faBlog,
  component: BlogTypeListing,
  children: null,
};
const blogtypeRoutes1 = {
  path: file_ten_path + "/blog_type/listing",
  name: localStorage.getItem("58"),
  icon: faBlog,
  component: BlogTypeListing,
  children: [
    {
      path: file_ten_path + "/blog_type/add",
      // name: "Add",
      component: BlogTypeAdd
    },

  ]
};

let categoryRoutes;
categoryRoutes = {
  path: file_ten_path + "/category/listing",
  name: 'Category',
  icon: faBlog,
  component: HrBlogCattypeListing,
  children: null,
};
const categoryRoutes1 = {
  path: file_ten_path + "/category/listing",
  name: 'Category',
  icon: faBlog,
  component: HrBlogCattypeListing,
  children: [
    {
      path: file_ten_path + "/category/add",
      // name: "Add",
      component: HrBlogCattypeAdd
    },

  ]
};

let orgGroupsRoutes;
orgGroupsRoutes = {
  path: file_ten_path + "/org_group/listing",
  name: 'Organization Group',
  icon: faBlog,
  component: OrgGrpListing,
  children: null,
};
const orgGroupsRoutes1 = {
  path: file_ten_path + "/org_group/listing",
  name: 'Organization Group',
  icon: faBlog,
  component: OrgGrpListing,
  children: [
    {
      path: file_ten_path + "/org_group/add",
      // name: "Add",
      component: OrgGrpAdd
    },

  ]
};

let subCategoryRoutes;
subCategoryRoutes = {
  path: file_ten_path + "/subcategory/listing",
  name: 'Sub Category',
  icon: faBlog,
  component: HrBlogSubCattypeListing,
  children: null,
};
const subCategoryRoutes1 = {
  path: file_ten_path + "/subcategory/listing",
  name: 'Sub Category',
  icon: faBlog,
  component: HrBlogSubCattypeListing,
  children: [
    {
      path: file_ten_path + "/subcategory/add",
      // name: "Add",
      component: HrBlogSubCattypeAdd
    },

  ]
};


let coursetypeRoutes;
coursetypeRoutes = {
  path: file_ten_path + "/course_type/listing",
  name: 'Course Type',
  icon: faBlog,
  component: CourseTypeListing,
  children: null,
};
const coursetypeRoutes1 = {
  path: file_ten_path + "/course_type/listing",
  name: 'Course Type',
  icon: faBlog,
  component: CourseTypeListing,
  children: [
    {
      path: file_ten_path + "/course_type/add",
      // name: "Add",
      component: CourseTypeAdd
    },

  ]
};

let PayrollPrivilageRoutes;
PayrollPrivilageRoutes = {
  path: file_ten_path + "/payrollprivilage/listing",
  name: 'Payroll Privilage',
  icon: faBlog,
  component: PayrollPrivilageListing,
  children: null,
};
const PayrollPrivilageRoutes1 = {
  path: file_ten_path + "/payrollprivilage/listing",
  name: 'Payroll Privilage',
  icon: faBlog,
  component: PayrollPrivilageListing,
  children: [
    {
      path: file_ten_path + "/payrollprivilage/add",
      // name: "Add",
      component: PayrollPrivilageAdd
    },

  ]
};
let NoticePayCheckRoutes;
NoticePayCheckRoutes = {
  path: file_ten_path + "/noticepaycheck/listing",
  name: 'Notice Pay Check',
  icon: faBlog,
  component: NoticePayCheckListing,
  children: null,
};
const NoticePayCheckRoutes1 = {
  path: file_ten_path + "/noticepaycheck/listing",
  name: 'Notice Pay Check',
  icon: faBlog,
  component: NoticePayCheckListing,
  children: [
    {
      path: file_ten_path + "/noticepaycheck/add",
      // name: "Add",
      component: NoticePayCheckAdd
    },


  ]
};




let jobtypeRoutes;
jobtypeRoutes = {
  path: file_ten_path + "/job_type/listing",
  name: 'Job Type',
  icon: faBlog,
  component: JobTypeListing,
  children: null,
};



const jobtypeRoutes1 = {
  path: file_ten_path + "/job_type/listing",
  name: 'Job Type',
  icon: faBlog,
  component: JobTypeListing,
  children: [
    {
      path: file_ten_path + "/job_type/add",
      // name: "Add",
      component: JobTypeAdd
    },

  ]
};


let jobtitleRoutes;
jobtitleRoutes = {
  path: file_ten_path + "/job_title/listing",
  name: 'Job Title',
  icon: faBlog,
  component: JobTitleListing,
  children: null,
};

const jobtitleRoutes1 = {
  path: file_ten_path + "/job_title/listing",
  name: 'Job Title',
  icon: faBlog,
  component: JobTitleListing,
  children: [
    {
      path: file_ten_path + "/job_title/add",
      // name: "Add",
      component: JobTitleAdd
    },

  ]
};


let jobidRoutes;
jobidRoutes = {
  path: file_ten_path + "/job_id/listing",
  name: 'Job ID',
  icon: faBlog,
  component: JobIDListing,
  children: null,
};

const jobidRoutes1 = {
  path: file_ten_path + "/job_id/listing",
  name: 'Job ID',
  icon: faBlog,
  component: JobIDListing,
  children: [
    {
      path: file_ten_path + "/job_id/add",
      // name: "Add",
      component: JobIDAdd
    },

  ]
};

let annoucementRoutes;
annoucementRoutes = {
  path: file_ten_path + "/annoucement_type/listing",
  name: localStorage.getItem("39"),
  icon: faBlog,
  component: AnnoucementTypeListing,
  children: null,
};
const annoucementRoutes1 = {
  path: file_ten_path + "/annoucement_type/listing",
  name: localStorage.getItem("39"),
  icon: faBlog,
  component: AnnoucementTypeListing,
  children: [
    {
      path: file_ten_path + "/annoucement_type/add",
      // name: "Add",
      component: AnnoucementTypeAdd
    },

  ]
};



const allowncetypeRoutes1 = {
  path: file_ten_path + "/allownce_type/listing",
  name: 'Settlement Type',
  icon: faBlog,
  component: ALLoListing,
  children: [
    {
      path: file_ten_path + "/allownce_type/add",
      // name: "Add",
      component: ALLoAdd
    },

  ]
};


let allowncecodeRoutes;
if (login_type == 'hr_admin') {
  allowncecodeRoutes = {
    path: file_ten_path + "/allownce_code/listing",
    name: 'Settlement Code',
    icon: faBlog,
    component: ALLCoListing,
    children: null,
  };
}
else if (login_type == 'sub_admin' && eallowancecode == '1') {
  allowncecodeRoutes = {
    path: file_ten_path + "/allownce_code/listing",
    name: localStorage.getItem("32"),
    icon: faBlog,
    component: ALLCoListing,
    children: null,
  };
}
else {
  allowncecodeRoutes = {
    path: "x",
  };

}



const allowncecodeRoutes1 = {
  path: file_ten_path + "/allownce_code/listing",
  name: localStorage.getItem("32"),
  icon: faBlog,
  component: ALLCoListing,
  children: [
    {
      path: file_ten_path + "/allownce_code/add",
      // name: "Add",
      component: ALLCoAdd
    },

  ]
};




let noteRoutes;
if (login_type == 'hr_admin') {
  noteRoutes = {
    path: file_ten_path + "/note_code/listing",
    name: localStorage.getItem("33"),
    icon: faBlog,
    component: NoteListing,
    children: null,
  };
}
else if (login_type == 'sub_admin' && enotecode == '1') {
  noteRoutes = {
    path: file_ten_path + "/note_code/listing",
    name: localStorage.getItem("33"),
    icon: faBlog,
    component: NoteListing,
    children: null,
  };
}
else {
  noteRoutes = {
    path: "x",
  };

}



const noteRoutes1 = {
  path: file_ten_path + "/note_code/listing",
  name: localStorage.getItem("33"),
  icon: faBlog,
  component: NoteListing,
  children: [
    {
      path: file_ten_path + "/note_code/add",
      // name: "Add",
      component: NoteAdd
    },

  ]
};










const assetsRoutes1 = {
  path: file_ten_path + "/assets/listing",
  name: localStorage.getItem("30"),
  icon: faBlog,
  component: AssListing,
  children: [
    {
      path: file_ten_path + "/assets/add",
      // name: "Add",
      component: AssAdd
    },

  ]
};



















const rolesRoutes1 = {
  path: file_ten_path + "/roles/listing",
  name: localStorage.getItem("95"),
  icon: faBlog,
  component: rolesListing,
  children: [
    {
      path: file_ten_path + "/roles/add",
      // name: "Add",
      component: rolesAdd
    },

  ]
};


const sarolesRoutes1 = {
  path: file_ten_path + "/saroles/listing",
  name: localStorage.getItem("26"),
  icon: faBlog,
  component: sarolesListing,
  children: [
    {
      path: file_ten_path + "/saroles/add",
      // name: "Add",
      component: sarolesAdd
    },

  ]
};















const blogRoutes1 = {
  path: file_ten_path + "/blog/listing",
  name: 'Blog',
  icon: faBlog,
  component: blogsListing,
  children: [
    {
      path: file_ten_path + "/blog/add",
      // name: "Add",
      component: blogsAdd
    },
    {
      path: file_ten_path + "/blog/comments",
      // name: "Add",
      component: blogsCommentListing
    },


    {
      path: file_ten_path + "/blog/addcomments",
      // name: "Add",
      component: blogsCommentAdd
    },


















  ]
};































const viewRoutes1 = {
  path: "/signup",

  name: localStorage.getItem("96"),
  icon: faIdCard,
  children: [
    {
      path: file_ten_path + "/signup/view",
      // name: "Add",
      component: SView
    },
    {
      path: file_ten_path + "/signup/message",
      // name: "Add",
      component: SMsg
    },
  ]
};

//   const viewRoutes1 = {
//   path: "/signup",
//   name: "SignUp Requests",
//   icon: faTable,
//   children: [
//     {
//       path: "/signup/view/:id",
//      // name: "Add",
//       component: SView
//     },
//     {
//       path: "/signup/message",
//      // name: "Add",
//       component: SMsg
//     },
//   ]
// } 

// let hradminRoutes;
// if(admin_id == 0){
//   //for Promotions
//   hradminRoutes = {
//     path: "/hradmin/listing",
//     name: "Manage HR Admin",
//     icon:faUserCircle,
//     component: HRListing,
//     children: null
//     };
//   } else {
//     //for Promotions
//     hradminRoutes = {
//     path: "/",
//     name: "",
//     icon: '',
//     component:  '',
//     children: null
//     };
//   }

let hradminRoutes;
if (admin_id != 1 && login_type != 'super_admin' || admin_id == 1 && login_type == 'super_admin' && HRAdminSuper != '1' && login_id != 1) {
  hradminRoutes = {
    path: "x",
  };
} else if (admin_id == null) {
  hradminRoutes = {
    path: "x",
  };
} else {
  hradminRoutes = {
    path: file_ten_path + "/hradmin/listing",
    name: 'Hr Admin',

    icon: faUserTie,
    component: HRListing,
    children: null
  }
}
// const hradminRoutes = (admin_id !=0 ) ? {
//   path: "/dashboard",
//   name: "",
//   icon:'',
//   component: Default,
//   children: null
// }:{
//   path: "/hradmin/listing",
//   name: "Manage HR Admin",
//   icon:faUserCircle,
//   component: HRListing,
//   children: null
// };


const hradminRoutes1 = {
  path: "/hradmin",
  name: 'Hr Admin',
 // name: localStorage.getItem("97"),
  icon: faUserCircle,
  children: [
    {
      path: file_ten_path + "/hradmin/add",
      // name: "Add",
      component: HRAdd
    },
  ]
}





//add more promotions by akshay
let morepromotionRoutes;
if (admin_id != 1 && login_type != 'super_admin') {
  morepromotionRoutes = {
    path: "/morepromotions/listing",
    name: "",
    icon: '',
    component: Default,
    children: null
  };
} else if (admin_id == null) {
  morepromotionRoutes = {
    path: "/morepromotions/listing",
    name: "",
    icon: '',
    component: Default,
    children: null
  };
} else {
  morepromotionRoutes = {
    path: "/morepromotions/listing",
    name: "MorePromotions",
    icon: faImages,
    component: BListing,
    children: null
  };
}

const morepromotionRoutes1 = {
  path: "/morepromotions/listing",
  name: "MorePromotions",
  icon: faImages,
  component: PListing,
  children: [
    {
      path: "/morepromotions/add/:id",
      // name: "Add",
      component: BAdd
    },
  ]
};


const MYprofile1 = {
  path: file_ten_path + "/myprofile/add",
  name: 'My Profile',
  icon: faUserCircle,
  children: [
    {
      path: file_ten_path + "/myprofile/Add",
      // name: "Add",
      component: Mprofile
    },
  ]
}



let shiftsRoutes;
let schedularRoutesAdd;
let employecareerRoutes;
let payrollLineEntryRoutes;
let payrollLineEntryRoutes1;
let payrollRoutes;
let constantsRoutes;
let constantsRoutes1;
let paycodeRoutes1;
let DocsRoutes;
let PointsRoutes;
let AccessRoutes;
let PromotionsEmpRoutes;
let AssetsRoutes;
let ProductShopRoutes;
let TotalworkRoutes;
let joninfoRoutes;
//let jobdisRoutes;

// else 

// {

//   payrollRoutes={
//     path: "x",
//      };

// }



constantsRoutes1 = { 
  path: file_ten_path+"/leave_type/listing",
  name:TF("{0} {1}", T("Leave") , T("Types")),
  icon: faUserCircle,
  children: [
    {
      path: file_ten_path+"/leave_type/Add",
     // name: "Add",
      component: leaveTypeAdd 
    },    
  ]
}









if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0 && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin' && hr_emp_careeer == 'true'

  || login_type == 'hr_admin' && hr_emp_careeer == 'true' && pro_package == '0'


) {
  employecareerRoutes = {
    path: file_ten_path + "/career",
    name: 'Employee Career',
    icon: faTable,
    children: [

      {
        path: file_ten_path + "/jobdisclouser",

        name: 'Job Disclosure',
        icon: faLandmark,
        component: Jobdisc,
        children: null
      },

      {
        path: file_ten_path + "/job_descripation/listing",
        name: 'Job Description',
        icon: faBusinessTime,
        component: JobdescListing,
        children: null
      },
      {
        path: file_ten_path + "/job_type/listing",
        name: 'Job Type',
        icon: faBlog,
        component: JobTypeListing,
        children: null,
      },
      {
        path: file_ten_path + "/job_title/listing",
        name: 'Job Title',
        icon: faBlog,
        component: JobTitleListing,
        children: null,
      },
      {
        path: file_ten_path + "/job_id/listing",
        name: 'Job ID',
        icon: faBlog,
        component: JobIDListing,
        children: null,
      },
      {
        path: file_ten_path + "/jobpost/listing",
        name: 'All Job Post',
        icon: faUserFriends,
        component: JobpostListing,
        children: null
      },
      {
        path: file_ten_path + "/jobpost/Applicantlisting",
        name: 'Application List',
        icon: faUserFriends,
        component: Applist,
        children: null
      },
      {
        path: file_ten_path + "/careers",
        name: 'Job Frontend Url',
        icon: faHome,
        target: '_blank',
        component: Landing,
        children: null
      },

    ]
  };

}
else {
  employecareerRoutes = {
    path: "x",
  };
}

//alert(localStorage.getItem('login_type'));

if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0 && login_type != 'emp' && login_type != 'security') {
  // alert('jhhjhj');
  // shiftsRoutes = {
  //  path: file_ten_path+"/shifts/listing",
  //  name:'Shifts',
  //  icon: faTable,
  //  component:  shiftListing,
  //  children: null
  //  };
  /*import_schedularRoutes = { 
     path: file_ten_path+"/shift_schedular/Importschedular",
     name: "Employee Bulk Import",
     icon:faClock,
     component: shiftSchedularImport,
     children: null
   }; */
  schedularRoutesAdd = {
    path: file_ten_path + "/shift_schedular/add",
    name: 'Work Scheduler',
    icon: faClock,
    component: shiftSchedularAdd,
    children: null
  };





  PointsRoutes = {
    path: "x",
  };

  AccessRoutes = {
    path: "x",
  };



  AssetsRoutes = {
    path: "x",
  };


  ProductShopRoutes = {
    path: "x",
  };


  TotalworkRoutes = {
    path: "x",
  };



  joninfoRoutes = {
    path: file_ten_path + "/employee/jobinfo",
    name: 'Job Info',
    icon: faCompressArrowsAlt,
    component: JobInfo,
    children: null
  };




  shiftsRoutes = {
    path: file_ten_path + "/shifts",
    name: 'Shifts',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/shifts/listing",
        name: 'All Shifts',

        icon: faUserFriends,
        component: shiftListing,
        children: null
      },
      {
        path: file_ten_path + "/shift_schedular/listing",
        name: 'Work Scheduler',
        icon: faClock,
        component: shiftSchedularList,
        children: null
      },

      {
        path: file_ten_path + "/attendance/listing",
        name: 'Present',
        icon: faClock,
        component: AListing,
        children: null
      },


      {
        path: file_ten_path + "/absent/listing",
        name: 'Absent',
        component: AbsenceListing,
        icon: faUserSlash,
        children: null
      }
      ,
      {
        path: file_ten_path + "/leaves/listing",
        name: 'Leave',
        icon: faCalendarDay,
        component: LListing,
      },
      {
        path: file_ten_path + "/leaves/Listingemp",
        name: 'Leave Bank',
        icon: faCalendarDay,
        component: ETLeaves,
      },

















      {
        path: file_ten_path + "/shift_schedular/listinghistory",
        name: 'Work Scheduler History',
        icon: faClock,
        component: shiftSchedularListHistory,
        children: null
      },
      {


        path: file_ten_path + "/shift_schedular/Importschedular",
        name: 'Work Scheduler Import',
        icon: faClock,
        component: shiftSchedularImport,
        children: null



      },










    ]
  };


  payrollRoutes = {
    path: file_ten_path + "/payroll",
    name: 'Payroll',

    icon: faMoneyBill,
    children: [
      {
        path: file_ten_path + "/payroll_line_entry/listing",
        name: 'Payroll Line Entry',
        icon: faFileAlt,
        component: payrollLineEntryList,
        children: null
      },

      {
        path: file_ten_path + "/payslip/listing",
        name: 'Open Payslips',
        icon: faFileAlt,
        component: payslipList,
        children: null
      },
      {
        path: file_ten_path + "/payslip/listingclose",
        name: 'Close Payslips',
        icon: faFileAlt,
        component: payslipListClose,
        children: null
      },
      {
        path: file_ten_path + "/paycode/listing",
        name: 'Pay Codes',
        icon: faTable,
        component: PAListing,
        children: null
      },
      {
        path: file_ten_path + "/payroll_line_entry/taxlisting",
        name: 'Tax Code',
        icon: faBlog,
        component: TaxListing,
        children: null,
      },
      {
        path: file_ten_path + "/tax_brackets/listing",
        name: 'Manage Tax Brackets',
        icon: faTable,
        component: taxBracketListing,
        children: null
      },

      /*{
        path: file_ten_path+"/payslip/Payrolltable",
       // name: "Add",
   name: "Payroll Table",
          icon: faFileAlt,
        component:Payrolltable,
     children: null
        
      },*/
    ]
  };






}


else if (login_type == 'emp' && pro_package != 0 && login_type != 'sub_admin') {
  shiftsRoutes = {
    path: file_ten_path + "/shift_schedular/listing",
    name: 'My Work Schedule',
    icon: faClock,
    component: shiftSchedularList,
    children: null
  };
  schedularRoutesAdd = {
    path: "x",
  };





  payrollRoutes = {
    path: file_ten_path + "/payslip/listing",
    name: 'My Payslips',
    icon: faFileAlt,
    component: payslipList,
    children: null
  };


  AccessRoutes = {
    path: file_ten_path + "/employee/Acesscode",
    name: 'Acess Code',
    icon: faCompressArrowsAlt,
    component: Acesscode,
    children: null
  };

  PointsRoutes = {
    path: file_ten_path + "/employee/ListingPoints",
    name: 'My Points',
    icon: faTrophy,
    component: EListingPoints,
    children: null
  };



  AssetsRoutes = {
    path: file_ten_path + "/employee/ListingAssts",
    name: 'My Assests',
    icon: faTv,
    component: EListingAssts,
    children: null
  };






  TotalworkRoutes = {
    path: file_ten_path + "/attendance/checkinout",
    name: 'Check In Out',
    icon: faMapMarkerAlt,
    component: BView,
    children: null
  };





  //jobdisRoutes = { path: "x", };




  joninfoRoutes = {
    path: "x",
  };
}



else {

  //alert('fg');
  shiftsRoutes = {
    path: "x",
  };
  schedularRoutesAdd = {
    path: "x",
  };
  payrollRoutes = {
    path: "x",
  };

  payrollLineEntryRoutes = {
    path: "x",
  };
  payrollLineEntryRoutes1 = {
    path: "x",
  };
  constantsRoutes = {
    path: "x",
  };
  paycodeRoutes1 = {
    path: "x",
  };


  //jobdisRoutes = { path: "x", };


  joninfoRoutes = {
    path: "x",
  };





  // jobdisRoutes = { path: "x", };


  DocsRoutes = {
    path: "x",
  };
  PointsRoutes = {
    path: "x",
  };
  AccessRoutes = {
    path: "x",
  };
  PromotionsEmpRoutes = {
    path: "x",
  };


  AssetsRoutes = {
    path: "x",
  };

  TotalworkRoutes = {
    path: "x",
  };


}



if (login_type == 'emp' && pro_package != 0 && login_type != 'sub_admin' && hr_emp_shop == 'true'


) {

  ProductShopRoutes = {
    path: file_ten_path + "/product/shop",
    name: 'Shop',
    icon: faCartPlus,
    component: ProductShop,
    children: null
  };


}
else {
  ProductShopRoutes = {
    path: "x",
  };
}

if (login_type == 'emp' && pro_package != 0 && login_type != 'sub_admin' && hr_emp_promotions == 'true') {


  PromotionsEmpRoutes = {
    path: file_ten_path + "/employee/morepromotions",
    name: 'Business Promotions',
    icon: faImages,
    component: EListingPromotions,
    children: null
  };



}
else {
  PromotionsEmpRoutes = {
    path: "x",
  };
}










var shiftsRoutesSub = [];



if (login_type == 'sub_admin' && eshift == '1') {
  shiftsRoutesSub.push({
    path: file_ten_path + "/shifts/listing",
    name: 'All Shifts',
    icon: faUserFriends,
    component: shiftListing,
    children: null
  }
  );
}


if (login_type == 'sub_admin' && escheduler == '1') {
  shiftsRoutesSub.push({
    path: file_ten_path + "/shift_schedular/listing",
    name: 'Work Scheduler',
    icon: faClock,
    component: shiftSchedularList,
    children: null
  });
}


if (login_type == 'sub_admin' && eshiftreport == '1') {
  shiftsRoutesSub.push({
    path: file_ten_path + "/shift_schedular/listing",
    name: 'Work Scheduler',
    icon: faClock,
    component: shiftSchedularList,
    children: null
  });
}



if (login_type == 'sub_admin' && schhistory == '1') {
  shiftsRoutesSub.push({
    path: file_ten_path + "/shift_schedular/listinghistory",
    name: 'Work Scheduler History',
    icon: faClock,
    component: shiftSchedularListHistory,
    children: null
  });
}






if (login_type == 'sub_admin' && shiftsRoutesSub != '') {



  shiftsRoutes = {
    path: file_ten_path + "/pages",
    name: 'Shifts',
    icon: faTable,
    children: shiftsRoutesSub,
  };
}



else if (login_type == 'sub_admin' && eshift == '0' && escheduler == '0' && eshiftreport == '0' && schhistory == '0') {

  shiftsRoutes = {

    path: 'x'
  }

}

















var arrayemployeecaroutes = [];

if (login_type == 'sub_admin' && ejobpost == '1') {
  arrayemployeecaroutes.push({
    path: file_ten_path + "/jobpost/listing",
    name: 'All Job Post',
    icon: faUserFriends,
    component: JobpostListing,
    children: null
  });
}

if (login_type == 'sub_admin' && eapplist == '1') {
  arrayemployeecaroutes.push({
    path: file_ten_path + "/jobpost/Applicantlisting",
    name: 'Application List',
    icon: faUserFriends,
    component: Applist,
    children: null
  });
}


if (login_type == 'sub_admin' && ejurl == '1') {
  arrayemployeecaroutes.push({
    path: file_ten_path + "/careers",
    name: 'Job Frontend Url',
    icon: faHome,
    target: '_blank',
    component: Landing,
    children: null
  });
}

if (login_type == 'sub_admin' && ejd == '1') {
  // workplanroutestask = {
  //   path: file_ten_path+"/projects/listing",
  //     name: "Projects",
  //     icon: faUserFriends,
  //     component:ProjectListing,
  //     children: null
  // }
  arrayemployeecaroutes.push(
    {
      path: file_ten_path + "/job_descripation/listing",
      name: 'Job Description',
      icon: faBusinessTime,
      component: JobdescListing,
      children: null
    }
  );
}



if (login_type == 'sub_admin' && jbtype == '1') {

  arrayemployeecaroutes.push(
    {
      path: file_ten_path + "/job_type/listing",
      name: 'Job Type',
      icon: faBlog,
      component: JobTypeListing,
      children: null,
    }
  );
}

if (login_type == 'sub_admin' && hr_emp_careeer == 'true' && arrayemployeecaroutes != '') {


  employecareerRoutes = {
    path: file_ten_path + "/pages",
    name: 'Employee Career',
    icon: faTable,
    children: arrayemployeecaroutes,
  };
}



else if (login_type == 'sub_admin' && ejobpost == '0' && eapplist == '0' && ejurl == '0') {

  employecareerRoutes = {

    path: 'x'
  }

}


//alert(ebracket);alert(epayroll);alert(epayslip);alert(epaycode);

var arraypayroll = [];
if (login_type == 'sub_admin' && ebracket == '1') {
  arraypayroll.push({
    path: file_ten_path + "/tax_brackets/listing",
    name: 'Manage Tax Brackets',
    icon: faTable,
    component: taxBracketListing,
    children: null
  });
}

if (login_type == 'sub_admin' && epayroll == '1') {
  arraypayroll.push({
    path: file_ten_path + "/payroll_line_entry/listing",
    name: 'Payroll Line Entry',
    icon: faFileAlt,
    component: payrollLineEntryList,
    children: null
  });
}


if (login_type == 'sub_admin' && epayslip == '1') {
  arraypayroll.push({
    path: file_ten_path + "/payslip/listing",
    name: 'Open Payslips',
    icon: faFileAlt,
    component: payslipList,
    children: null
  });
}
if (login_type == 'sub_admin' && epayslip == '1') {
  arraypayroll.push({
    path: file_ten_path + "/payslip/listingclose",
    name: 'Close Payslips',
    icon: faFileAlt,
    component: payslipListClose,
    children: null
  });
}


if (login_type == 'sub_admin' && epaycode == '1') {
  arraypayroll.push({
    path: file_ten_path + "/paycode/listing",
    name: 'Pay Codes',
    icon: faTable,
    component: PAListing,
    children: null
  });
}

if (login_type == 'sub_admin' && arraypayroll != '') {

  //alert("INNN");
  payrollRoutes = {
    path: file_ten_path + "/pages",
    name: 'Payroll',
    icon: faMoneyBill,
    children: arraypayroll,
  };

}


else if (login_type == 'sub_admin' && ebracket == '0' && epayroll == '0' && epayslip == '0' && epaycode == '0') {
  payrollRoutes = {
    path: 'x'
  }

}




















if (login_type == 'emp' && pro_package != 0) {
  DocsRoutes = {
    path: file_ten_path + "/employee/ListingDocs",
    name: 'My Documents',
    icon: faPrint,
    component: EListingDocs,
    children: null
  };
}
else {
  DocsRoutes = {
    path: "x",
  };
}













payrollLineEntryRoutes1 = {
  path: file_ten_path + "/payroll_line_entry/listing",
  name: 'Payroll Line Entry',
  icon: faFileAlt,
  children: [
    {
      path: file_ten_path + "/payroll_line_entry/add",
      // name: "Add",
      component: payrollLineEnrtyAdd
    },
    {
      path: file_ten_path + "/payroll_line_entry/import_payslip_data",
      // name: "Add",
      component: payslipImport

    },

    {
      path: file_ten_path + "/payroll_line_entry/data",
      // name: "Add",
      component: payslipImportExportBefore

    },
    {
      path: file_ten_path + "/payroll_line_entry/datanp",
      // name: "Add",
      // component: payslipImportExport
      component: payslipImportExportBeforeNp

    },
    {
      path: file_ten_path + "/payroll_line_entry/reoprt",
      // name: "Add",
      component: payrollreport

    },

    {
      path: file_ten_path + "/payroll_line_entry/allreports",
      // name: "Add",
      component: allPayrollreport

    },
    {
      path: file_ten_path + "/payroll_line_entry/listingtest",
      // name: "Add",
      component: payrollLineEntryListTest

    },









    {
      path: file_ten_path + "/payslip/Payslip_pdf/:id",
      // name: "Add",
      component: payslippdf

    },
    {
      path: file_ten_path + "/payslip/View_payslip/:id/:id2",
      // name: "Add",
      component: viewPayslip

    }
    ,
    {
      path: file_ten_path + "/payslip/Payrolltable",
      // name: "Add",
      component: Payrolltable

    }
  ]
}


let superadminStaffRoutes;




if (admin_id == 1 && login_id == 1 || login_type == 'super_admin' && admin_id == 1 && AdminStaffSuper == '1') {



  superadminStaffRoutes = {
    path: file_ten_path + "/superadmin_staff/listing",
    name: 'Super User',
    icon: faUserFriends,

    component: superadminStaffListing,
    //component:  adminStaffListing,
    children: null,
  };

} else {

  superadminStaffRoutes = {
    path: "x",
  };
}





















let adminStaffRoutes;

if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0 && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin') {



  adminStaffRoutes = {
    path: file_ten_path + "/admin_staff",
    name: TF("{0}", T("Staff")),
    icon: faUserFriends,
    //component:  adminStaffListing,
    children: [
      {
        path: file_ten_path + "/admin_staff/listing",
        //  path:  file_ten_path+"/admin_staff/listing",
        name: TF("{0}", T("Sub Admin")),
        icon: faUserFriends,
        component: adminStaffListing,
        children: null
      },
      {
        path: file_ten_path + "/admin_staff/listingsecurity",
        name: TF("{0}", T("Security")),
        icon: faUserFriends,
        component: securityStaffListing,
        children: null
      },
    ]
  };

}


else if (login_type == 'sub_admin' && esubadmin == '1' && esec == '1') {



  adminStaffRoutes = {
    path: file_ten_path + "/admin_staff",
    name: TF("{0}", T("Staff")),
    icon: faUserFriends,
    //component:  adminStaffListing,
    children: [
      {
        path: file_ten_path + "/admin_staff/listing",
        //  path:  file_ten_path+"/admin_staff/listing",
        name: TF("{0}", T("Sub Admin")),
        icon: faUserFriends,
        component: adminStaffListing,
        children: null
      },
      {
        path: file_ten_path + "/admin_staff/listingsecurity",
        name: TF("{0}", T("Security")),
        icon: faUserFriends,
        component: securityStaffListing,
        children: null
      },
    ]
  };

}
else if (login_type == 'sub_admin' && esubadmin == '1') {



  adminStaffRoutes = {
    path: file_ten_path + "/admin_staff",
    name: TF("{0}", T("Staff")),
    icon: faUserFriends,
    //component:  adminStaffListing,
    children: [
      {
        path: file_ten_path + "/admin_staff/listing",
        //  path:  file_ten_path+"/admin_staff/listing",
        name: TF("{0}", T("Sub Admin")),
        icon: faUserFriends,
        component: adminStaffListing,
        children: null
      },

    ]
  };

}
else if (login_type == 'sub_admin' && esec == '1') {



  adminStaffRoutes = {
    path: file_ten_path + "/admin_staff",
    name: TF("{0}", T("Staff")),
    icon: faUserFriends,
    //component:  adminStaffListing,
    children: [

      {
        path: file_ten_path + "/admin_staff/listingsecurity",
        name: TF("{0}", T("Security")),
        icon: faUserFriends,
        component: securityStaffListing,
        children: null
      },
    ]
  };

}

else {

  adminStaffRoutes = {
    path: "x",
  };
}
















paycodeRoutes1 = {
  path: file_ten_path + "/paycode/listing",
  name: 'Pay Codes',
  icon: faUserCircle,
  children: [
    {
      path: file_ten_path + "/paycode/Add",
      // name: "Add",
      component: PAAdd
    },
  ]
}
payrollLineEntryRoutes1 = {
  path: file_ten_path + "/payroll_line_entry/listing",
  name: 'Payroll Line Entry',
  icon: faUserCircle,
  children: [
    {
      path: file_ten_path + "/payroll_line_entry/add",
      // name: "Add",
      component: payrollLineEnrtyAdd
    },
    {
      path: file_ten_path + "/payroll_line_entry/import_payslip_data",
      // name: "Add",
      component: payslipImport

    },
    {
      path: file_ten_path + "/payroll_line_entry/data",
      // name: "Add",
      component: payslipImportExportBefore

    },
    {
      path: file_ten_path + "/payroll_line_entry/report",
      // name: "Add",
      component: payrollreport

    },
    {
      path: file_ten_path + "/payroll_line_entry/listingtest",
      // name: "Add",
      component: payrollLineEntryListTest

    },

    {
      path: file_ten_path + "/payslip/Payslip_pdf/:id",
      // name: "Add",
      component: payslippdf

    },
    {
      path: file_ten_path + "/payslip/View_payslip/:id/:id2",
      // name: "Add",
      component: viewPayslip

    },
    {
      path: file_ten_path + "/payroll_line_entry/allreports",
      // name: "Add",
      component: allPayrollreport

    },

  ]
}

let adminStaffRoutes1;
adminStaffRoutes1 = {
  path: file_ten_path + "/admin_staff/listing",
  name: TF("{0} {1}", T("Admin"), T("Staff")),
  icon: faUserFriends,
  component: adminStaffListing,
  children: [
    {
      path: file_ten_path + "/admin_staff/Add",
      // name: "Add",
      component: adminStaffAdd
    },
    {
      path: file_ten_path + "/admin_staff/AddSecurity",
      // name: "Add",
      component: securityStaffAdd
    },
  ]
};


let superadminStaffRoutes1;
superadminStaffRoutes1 = {
  path: file_ten_path + "/superadmin_staff/listing",
  name: 'Super User',
  icon: faUserFriends,
  component: superadminStaffListing,
  children: [
    {
      path: file_ten_path + "/superadmin_staff/Add",
      // name: "Add",
      component: superadminStaffAdd
    },

  ]
};
// const shiftsRoutes = {
//   path: "/shifts/listing",
//   name:'Shifts',
//   icon: faTable,
//   component:  shiftListing,
//   children: null
//   };

//shift add
const shiftsRoutes1 = {
  path: file_ten_path + "/shifts/listing",
  name: 'Shifts',
  icon: faTable,
  component: shiftListing,
  children: [
    {
      path: file_ten_path + "/shifts/add",
      // name: "Add",
      component: shiftAdd
    },
    {
      path: file_ten_path + "/shift_schedular/add",
      // name: "Add",
      component: shiftSchedularAdd
    },
  ]
};
const shiftsRoutesSub1 = {
  path: file_ten_path + "/shifts/listing",
  name: 'Shifts',
  icon: faTable,
  component: shiftListing,
  children: [
    {
      path: file_ten_path + "/shifts/add",
      // name: "Add",
      component: shiftAdd
    },
  ]
};
//shift listing
// const taxBracketRoutes = {
//   path: "/tax_brackets/listing",
//   name:'Manage Tax Brackets',
//   icon: faList,
//   component:  taxBracketListing,
//   children: null
//   };



let taxBracketRoutes;
if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0) {
  taxBracketRoutes = {
    path: file_ten_path + "/tax_brackets/listing",
    name: 'Manage Tax Brackets',
    icon: faTable,
    component: taxBracketListing,
    children: null
  };

} else {
  taxBracketRoutes = {
    path: "x",
  };

}


//shift add
const taxBracketRoutes1 = {
  path: file_ten_path + "/tax_brackets/listing",
  name: 'Manage Tax Brackets',
  icon: faList,
  component: taxBracketListing,
  children: [
    {
      path: file_ten_path + "/tax_brackets/add",
      component: taxBracketAdd
    },
  ]
};


//for employee shops
let employeshopRoutes;



if (login_type == 'sub_admin' && eprocat == '1' && epro == '1' && eorder == '1' && ebanner == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/productcategory/listing",
        name: 'Product Category',
        icon: faUserFriends,
        component: ProductcatgoryListing,
        children: null
      },
      {
        path: file_ten_path + "/product/listing",
        name: 'Products',
        icon: faVolumeUp,
        component: ProductListing,
        children: null
      },
      {
        path: file_ten_path + "/order/listing",
        name: 'Orders',
        icon: faTruck,
        component: OrderListing,
        children: null
      },
      bannerRoutes = {
        path: file_ten_path + "/banner/listing",
        name: 'Banners',
        icon: faImages,
        component: BAListing,
        children: null
      },
    ]
  };
}


else if (login_type == 'sub_admin' && eprocat == '1' && epro == '1' && eorder == '1' && hr_emp_shop == 'true' || login_type == 'hr_admin' && hr_emp_shop == 'true' && pro_package == '0') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/productcategory/listing",
        name: 'Product Category',
        icon: faUserFriends,
        component: ProductcatgoryListing,
        children: null
      },
      {
        path: file_ten_path + "/product/listing",
        name: 'Products',
        icon: faVolumeUp,
        component: ProductListing,
        children: null
      },
      {
        path: file_ten_path + "/order/listing",
        name: 'Orders',
        icon: faTruck,
        component: OrderListing,
        children: null
      },

    ]
  };
}

else if (login_type == 'sub_admin' && eprocat == '1' && epro == '1' && ebanner == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/productcategory/listing",
        name: 'Product Category',
        icon: faUserFriends,
        component: ProductcatgoryListing,
        children: null
      },
      {
        path: file_ten_path + "/product/listing",
        name: 'Products',
        icon: faVolumeUp,
        component: ProductListing,
        children: null
      },
      bannerRoutes = {
        path: file_ten_path + "/banner/listing",
        name: 'Banners',
        icon: faImages,
        component: BAListing,
        children: null
      },
    ]
  };
}


else if (login_type == 'sub_admin' && eprocat == '1' && eorder == '1' && ebanner == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/productcategory/listing",
        name: 'Product Category',
        icon: faUserFriends,
        component: ProductcatgoryListing,
        children: null
      },
      {
        path: file_ten_path + "/order/listing",
        name: 'Orders',
        icon: faTruck,
        component: OrderListing,
        children: null
      },
      bannerRoutes = {
        path: file_ten_path + "/banner/listing",
        name: 'Banners',
        icon: faImages,
        component: BAListing,
        children: null
      },
    ]
  };
}


else if (login_type == 'sub_admin' && epro == '1' && eorder == '1' && ebanner == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/product/listing",
        name: 'Products',
        icon: faVolumeUp,
        component: ProductListing,
        children: null
      },
      {
        path: file_ten_path + "/order/listing",
        name: 'Orders',
        icon: faTruck,
        component: OrderListing,
        children: null
      },
      bannerRoutes = {
        path: file_ten_path + "/banner/listing",
        name: 'Banners',
        icon: faImages,
        component: BAListing,
        children: null
      },
    ]
  };
}



else if (login_type == 'sub_admin' && eprocat == '1' && epro == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/productcategory/listing",
        name: 'Product Category',
        icon: faUserFriends,
        component: ProductcatgoryListing,
        children: null
      },
      {
        path: file_ten_path + "/product/listing",
        name: 'Products',
        icon: faVolumeUp,
        component: ProductListing,
        children: null
      },

    ]
  };
}



else if (login_type == 'sub_admin' && eprocat == '1' && eorder == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/productcategory/listing",
        name: 'Product Category',
        icon: faUserFriends,
        component: ProductcatgoryListing,
        children: null
      },

      {
        path: file_ten_path + "/order/listing",
        name: 'Orders',
        icon: faTruck,
        component: OrderListing,
        children: null
      },

    ]
  };
}


else if (login_type == 'sub_admin' && eprocat == '1' && ebanner == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/productcategory/listing",
        name: 'Product Category',
        icon: faUserFriends,
        component: ProductcatgoryListing,
        children: null
      },

      bannerRoutes = {
        path: file_ten_path + "/banner/listing",
        name: 'Banners',
        icon: faImages,
        component: BAListing,
        children: null
      },
    ]
  };
}



else if (login_type == 'sub_admin' && epro == '1' && eorder == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [

      {
        path: file_ten_path + "/product/listing",
        name: 'Products',
        icon: faVolumeUp,
        component: ProductListing,
        children: null
      },
      {
        path: file_ten_path + "/order/listing",
        name: 'Orders',
        icon: faTruck,
        component: OrderListing,
        children: null
      },

    ]
  };
}




else if (login_type == 'sub_admin' && epro == '1' && ebanner == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [

      {
        path: file_ten_path + "/product/listing",
        name: 'Products',
        icon: faVolumeUp,
        component: ProductListing,
        children: null
      },

      bannerRoutes = {
        path: file_ten_path + "/banner/listing",
        name: 'Banners',
        icon: faImages,
        component: BAListing,
        children: null
      },
    ]
  };
}

else if (login_type == 'sub_admin' && eorder == '1' && ebanner == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/order/listing",
        name: 'Orders',
        icon: faTruck,
        component: OrderListing,
        children: null
      },
      bannerRoutes = {
        path: file_ten_path + "/banner/listing",
        name: 'Banners',
        icon: faImages,
        component: BAListing,
        children: null
      },
    ]
  };
}

else if (login_type == 'sub_admin' && eprocat == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/productcategory/listing",
        name: 'Product Category',
        icon: faUserFriends,
        component: ProductcatgoryListing,
        children: null
      },

    ]
  };
}

else if (login_type == 'sub_admin' && epro == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [

      {
        path: file_ten_path + "/product/listing",
        name: 'Products',
        icon: faVolumeUp,
        component: ProductListing,
        children: null
      },

    ]
  };
}


else if (login_type == 'sub_admin' && eorder == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/order/listing",
        name: 'Orders',
        icon: faTruck,
        component: OrderListing,
        children: null
      },

    ]
  };
} else if (login_type == 'sub_admin' && ebanner == '1' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [

      bannerRoutes = {
        path: file_ten_path + "/banner/listing",
        name: 'Banners',
        icon: faImages,
        component: BAListing,
        children: null
      },
    ]
  };
}

// else {
//   employeshopRoutes = {
//     path: "x",
//  };
// }























else if (admin_id != 1 && login_type != 'super_admin' && pro_package != 0 && login_type != 'emp' && login_type != 'security' && login_type != 'sub_admin' && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/shops",
    name: 'Employee Shop',
    icon: faTable,
    children: [
      {
        path: file_ten_path + "/productcategory/listing",
        name: 'Product Category',
        icon: faUserFriends,
        component: ProductcatgoryListing,
        children: null
      },
      {
        path: file_ten_path + "/product/listing",
        name: 'Products',
        icon: faVolumeUp,
        component: ProductListing,
        children: null
      },
      {
        path: file_ten_path + "/order/listing",
        name: 'Orders',
        icon: faTruck,
        component: OrderListing,
        children: null
      },
      bannerRoutes = {
        path: file_ten_path + "/banner/listing",
        name: 'Banners',
        icon: faImages,
        component: BAListing,
        children: null
      },
    ]
  };
}


else if (login_type == 'emp' && pro_package != 0 && hr_emp_shop == 'true') {
  employeshopRoutes = {
    path: file_ten_path + "/order/listing",
    name: 'My Orders',
    icon: faTruck,
    component: OrderListing,
    children: null
  };

}







else {
  employeshopRoutes = {
    path: "x",
  };

}
const employeshopRoutes1 = {
  path: file_ten_path + "/productcategory/listing",
  name: TF("{0} {1} {2}", T("All"), T("Product"), ("Category")),
  icon: faTable,
  component: ProductcatgoryListing,
  children: [
    {
      path: file_ten_path + "/productcategory/add",
      // name: "Add",
      component: ProductcatgoryAdd
    },
    {
      path: file_ten_path + "/product/add",
      // name: "Add",
      component: ProductAdd
    },
    {
      path: file_ten_path + "/order/add",
      // name: "Add",
      component: OrderAdd
    },
    {
      path: file_ten_path + "/product/shopproducts",
      // name: "Add",
      component: CardAdd
    },
    {
      path: file_ten_path + "/product/shoptopproducts",
      // name: "Add",
      component: TopproductAdd
    },
    {
      path: file_ten_path + "/product/cartinfo",
      // name: "Add",
      component: CartAdd
    },
    {
      path: file_ten_path + "/product/moreinfo",
      // name: "Add",
      component: ShopAdd
    },
    {
      path: file_ten_path + "/banner/add/:id/:id1",
      // name: "Add",
      component: BAAdd
    },
  ]
};


//for points declaration
const pointsRoutes = {
  path: file_ten_path + "/points/listing",
  name: 'Point Declaration',
  icon: faTrophy,
  component: PublicPointListing,
  children: null
};






const pointsRoutes1 = {
  path: "/points/listing",
  name: 'Point Declaration',
  icon: faVolumeUp,
  component: PublicPointListing,
  children: [
    {
      path: file_ten_path + "/points/add",
      // name: "Add",
      component: PublicPointAdd
    },
  ]
};


//for job description


const JDRoutes = {
  path: file_ten_path + "/job_descripation/listing",
  name: localStorage.getItem("47"),
  icon: faBusinessTime,
  component: JobdescListing,
  children: null
};
const JDRoutes1 = {
  path: file_ten_path + "/job_descripation/listing",
  name: localStorage.getItem("47"),
  icon: faBusinessTime,
  component: JobdescListing,
  children: [
    {
      path: file_ten_path + "/job_descripation/add",
      // name: "Add",
      component: JobdescAdd
    },
    {
      path: file_ten_path + "/job_descripation/info",
      // name: "Add",
      component: JobdescCard
    },
  ]
};

const employecareerRoutes1 = {
  path: file_ten_path + "/jobpost/listing",
  name: 'All Job Post',
  icon: faTable,
  component: JobpostListing,
  children: [
    {
      path: file_ten_path + "/jobpost/add",
      // name: "Add",
      component: JobpostAdd
    },
    {
      path: file_ten_path + "/jobpost/JobpostAppListing",
      name: 'Application List',
      icon: faUserFriends,
      component: JApplist,
      children: null
    },
  ]
};







// const documentationRoutes = {              
//   path: "/documentation",
//   name: "Getting Started",
//   header: "Extras",
//   icon: faBook,
//   component: Documentation,
//   children: null
// };

// This route is not visisble in the sidebar


// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  // documentationRoutes,
  ///  privateRoutes,
  SettingRoutes,employeeEvalutionReport,
  MYprofile1, jobdisRoutes,
  signupRoutes, viewRoutes1, hradminRoutes1, hradminRoutes, empRoutes, empRoutes1, orgRoutes, orgRoutes1, leaveRoutes, leaveRoutes1, announcementRoutes, announcementRoutes1,
  attendanceRoutes, attendanceRoutes1, promotionRoutes, promotionRoutes1, logs, holidayRoutes, holidayRoutes1, empGroupRoutes, empGroupRoutes1, complaintsRoutes, complaintsRoutes1,
  morepromotionRoutes, morepromotionRoutes1,
  allowncetypeRoutes, coursetypeRoutes, coursetypeRoutes1, payrollroutes1, payrollroutes, taxRoutes1,
  categorytypeRoutes, categorytypeRoutes1, jobidRoutes, jobidRoutes1,
  complainttypeRoutes, complainttypeRoutes1, blogtypeRoutes, blogtypeRoutes1, jobtypeRoutes, jobtypeRoutes1, jobtitleRoutes, jobtitleRoutes1, annoucementRoutes, annoucementRoutes1,
  allowncetypeRoutes1, noteRoutes, noteRoutes1, PayrollPrivilageRoutes, PayrollPrivilageRoutes1, empPayrollNoteBulkRoutes,
  , empBulkRoutes, absentRoutes, absentRoutes1, , shiftsRoutes, shiftsRoutes1, taxBracketRoutes, taxBracketRoutes1, schedularRoutesAdd, empSettlementBulkRoutes,
  payrollHeadersRoutes, payrollHeadersRoutes1, NoticePayCheckRoutes, NoticePayCheckRoutes1,
  payrollLineEntryRoutes1, payrollRoutes,
  , DepartmentRoutes, DepartmentRoutes1, adminStaffRoutes, adminStaffRoutes1, superadminStaffRoutes, superadminStaffRoutes1,
  employeshopRoutes, employeshopRoutes1, pointsRoutes1, pointsRoutes, AccessRoutes, PromotionsEmpRoutes, joninfoRoutes,constantsRoutes1,
  JDRoutes, JDRoutes1, employecareerRoutes, employecareerRoutes1, empSettlementBulkRoutesAll,
  //,shiftsRoutesSub,shiftsRoutesSub1,
  , incidentRoutes1, holidayBulkRoutes, allowncecodeRoutes, allowncecodeRoutes1,
  incidentRoutes, assetsRoutes, assetsRoutes1,
  empcheckinoutRoutes, sarolesRoutes1, sarolesRoutes,
  MsgRoutes, paycodeRoutes1, profile, ComplaintRoutes,
  employetrainingRoutes, employetrainingRoutes1, blogRoutes, blogRoutes1, documenttypeRoutes, documenttypeRoutes1, HrblogRoutes,
  adminappRoutes,
  adminappRoutes1, categoryRoutes, categoryRoutes1, subCategoryRoutes, subCategoryRoutes1, orgGroupsRoutes, orgGroupsRoutes1,
  reportRoutes,

  bannerRoutes, bannerRoutes1
  , DocsRoutes, PointsRoutes, AssetsRoutes, ProductShopRoutes, TotalworkRoutes, rolesRoutes, rolesRoutes1, guestRoutes, guestRoutes1, landingRoutes, workpjectroutes1, workplanroutes,
  workreport1,
  workpjectroutestasks1
];

// Landing specific routes
export const landing = [landingRoutesNew, landingRoutesNewCompany];

// Auth specific routes
export const page = [landingRoutesNew, landingRoutesNewCompany];
// export const measure = [measureRoutes];

// All routes
export default [
  //landingRoutesNewCompany,
  dashboardRoutes,
  TotalworkRoutes,
  empRoutes,
  shiftsRoutes,
  payrollRoutes,
  reportRoutes,
  //shiftsRoutesSub,
  workplanroutes,
  employeeEvalutionReport,
  employecareerRoutes,
  employetrainingRoutes,
  MsgRoutes,
  employeshopRoutes,
  ProductShopRoutes,
  promotionRoutes,
  PromotionsEmpRoutes,
  announcementRoutes,
  blogRoutes,
  HrblogRoutes,
  guestRoutes,
  SettingRoutes,

  

  attendanceRoutes,

  //  absentRoutes,
  leaveRoutes,

  //   adminStaffRoutes,

  // constantsRoutes,


  AccessRoutes,

  signupRoutes,
  hradminRoutes,

  // adminappRoutes,
  ComplaintRoutes,



  PointsRoutes,
  SettingRoutesEmp,








  , DocsRoutes, AssetsRoutes,








  //pageRoutes,
  //authRoutes,
  //uiRoutes,
  //chartRoutes,
  //formRoutes,
  //mRoutes,





  empcheckinoutRoutes,





  //landingRoutes,
  incidentRoutes,


  //  taxBracketRoutes,
  //  orgRoutes,   
  //  holidayRoutes,
  //  empGroupRoutes,
  //  complaintsRoutes,
  // pointsRoutes,

  // bannerRoutes,

  // empBulkRoutes,
  //   rolesRoutes,
  superadminStaffRoutes,
  //sarolesRoutes,
  landingRoutesNewCompanyEmp,

  profile,
  logs,
  // assetsRoutes,
  // allowncetypeRoutes,
  // allowncecodeRoutes,
  // noteRoutes,
  //  empRoutes1,/////////
  //  morepromotionRoutes,
  // iconRoutes,
  //calendarRoutes,
  //mapRoutes,
  //documentationRoutes,
  //layoutRoutes
];

