import React, { PureComponent } from "react";
import $ from 'jquery';
import './auth.scss';
import * as myConstClass from '../../constant.js';
import {
  Button,
  Card,
  CardBody,
  Container,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback
} from "availity-reactstrap-validation";
import { toastr } from "react-redux-toastr";
// const logo = `https://alpha.workzoneplus.com/backend/img/clock_new.png`;
// const zone_logo = `https://alpha.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;

const logo = `https://workzonepro.workzoneplus.com/backend/img/clock_new.png`;
const zone_logo = `https://workzonepro.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;
const image3 = `https://workzonepro.workzoneplus.com/img/logo2.png`;

class SignUp extends PureComponent {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
    // this.googleTranslateElementInit = this.googleTranslateElementInit.bind(this);
    this.state = {
      user_id: '0',
      units: '',
      formData: {
        title: '',
        quantity: '',
        weight: '',
        unit: '',
        sequence: '',
      },
      submitted: false,
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    }

    this.options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  onChangeHandler = event => {
    console.log(event.target.files[0]);
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })
  }


  componentDidMount() {
    $('.main').css('background-image', 'none');
        $('.main').css('background-size', 'none');
  
    localStorage.setItem('signup', '0');

  }
  // googleTranslateElementInit () {
  //   // / eslint-disable no-new /
  //   const google = window.google;
  //     new google.translate.TranslateElement({pageLanguage: 'en' }, 'google_translate_element_sign');
  //   new window.google.translate.TranslateElement({pageLanguage: 'pt', layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT}, 'google_translate_element_sign')
  // }

  routeChange() {
    // this.props.history.push('https://www.workzoneplus.com/');
    // window.location.href = 'https://www.workzoneplus.com/';
    window.location.href = '/';
    /// this.props.history.push('/');
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };

    this.setState({ submitted: true }, () => {
      const data = new FormData()
      data.append('file', this.state.selectedFile);
      data.append('formdata', JSON.stringify($('#pform').serializeArray()));
      setTimeout(() => this.setState({ submitted: false }), 5000);
      fetch(myConstClass.LOGIN_URL + `user/add_request/`, {
        method: 'POST',
        body: data,
      }).then(response => response.json()).then((response) => {

        if (response == true) {
          toastr.success('Success', "Request Added Successfully..!",
            options
          );

          this.routeChange();
          // toast('User Added Successfully..!', {containerId: 'B',type: toast.TYPE.SUCCESS})
          // this.props.history.push('/pages/signup/message');

        } else if (response == 2) {
          toastr.error(
            'Error',
            "Email ID Already Exists..!",
            this.options
          )
        } else if (response == 3) {
          toastr.error(
            'Error',
            "Please Upload Personnal Document..!",
            this.options
          )
        } else if (response == 9) {
          toastr.error(
            'Error',
            "Account name should not be graeter than 10 characters",
            this.options
          )
        } else if (response == 10) {
          toastr.error(
            'Error',
            "Account name already exists..!",
            this.options
          )
        } else {
          // toast('User Insertion Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
        }
      })
        .catch();
    });

  }

  onFormCancel = () => {
    localStorage.setItem('signup', '0');
    window.location.href = '/';
  }

  render() {
    const { formData, submitted, branch, codes } = this.state;
    return (

      // <div class = "wrapper">
      // {/* <div class = "wrapper" style={{width: '1886px'}}> */}
      // <Container>
      //   <div className="text-center mt-4">
      //   <h1 className="h2">Sign Up Form</h1>
      //   <p className="lead">
      //   </p>
      // </div>

      <div class="wrapper" style={{ width: '1189px' }}>
        {/* <Collapse navbar>
    <Nav className= "mr-auto" navbar>
      </Nav>
      </Collapse> */}
        <br />
        <div className="text-center mt-4">
          <h1 className="h2">Sign Up Form</h1>
          <p className="lead">
          </p>

        </div>
        <div className="text-center mt-4">
          <h1 className="h2">Sign Up Form</h1>
          <p className="lead">
          </p>

        </div><div className="text-center mt-4">
          <h1 className="h2">Sign Up Form</h1>
          <p className="lead">
          </p>

        </div>
        <Container fluid>

          <div className="text-center mt-4">
            <h1 className="h2">Sign Up Form</h1>
            <p className="lead">
            </p>

          </div>

          <div class="row">
            <div class="col- 6">
              <div className="center">


                <Card>
                  {/* <center> <div id="google_translate_element_sign"></div></center> */}
                  <div className="text-center mt-4">
                    <img class="dashboar-plus-logo" src={logo} style={{ width: '40px', height: '39px' }} />  <img class="dashboar-plus-logo" src={zone_logo} style={{ width: '197px', height: '30px' }} />
                  </div>


                  <CardBody>
                    <AvForm id="pform" onSubmit={this.handleSubmit}>

                      <AvGroup>
                        <Label for="example">Name</Label>
                        <AvInput name="name" id="example" placeholder="Enter Name" required value={formData ? formData.name : ''} />
                        <AvFeedback>Enter Name!</AvFeedback>
                      </AvGroup>

                      <AvGroup>
                        <Label for="example">Organization</Label>
                        <AvInput name="organization" id="example" placeholder="Enter Organization" required value={formData ? formData.organization : ''} />
                        <AvFeedback>Enter Organization!</AvFeedback>
                      </AvGroup>
                      <AvGroup>
                        <Label for="example">Mobile No</Label>
                        <AvInput name="mobile_no" id="example" placeholder="Enter Mobile No" required value={formData ? formData.mobile_no : ''} />
                        <AvFeedback>Enter Mobile No !</AvFeedback>
                      </AvGroup>

                      <AvGroup>
                        <Label for="example">Email address</Label>
                        <AvInput type="email" name="email" id="example" placeholder="Enter Email" required value={formData ? formData.email : ''} />
                        <AvFeedback>Enter Email !</AvFeedback>
                      </AvGroup>

                      <AvGroup>
                        <Label for="example">Position</Label>
                        <AvInput name="position" id="example" placeholder="Enter Position" required value={formData ? formData.position : ''} />
                        <AvFeedback>Enter Position !</AvFeedback>
                      </AvGroup>
                      <AvGroup>
                        <Label>Website</Label>
                        <AvInput name="website" id="example" placeholder="Enter your Website" required value={formData ? formData.website : ''} />
                        <AvFeedback>Enter Website !</AvFeedback>
                      </AvGroup>
                      <AvGroup>
                        <Label>Reference</Label>
                        <AvInput name="reference" id="example" placeholder="Enter Reference" required value={formData ? formData.reference : ''} />
                        <AvFeedback>Enter Reference !</AvFeedback>
                      </AvGroup>
                      <AvGroup>
                        <Label>City</Label>
                        <AvInput name="city" id="example" placeholder="Enter City" required value={formData ? formData.city : ''} />
                        <AvFeedback>Enter City !</AvFeedback>
                      </AvGroup>
                      <AvGroup>
                        <Label>Country</Label>
                        <AvInput name="country" id="example" placeholder="Enter Country" required value={formData ? formData.country : ''} />
                        <AvFeedback>Enter Country !</AvFeedback>
                      </AvGroup>
                      <AvGroup>
                        <Label for="example">Account Name</Label><br />
                        <Label className="account_name_label" for="example">saastest.workzoneplus.com/</Label>
                        <AvInput name="account_name" className="account_name_field" required value={formData ? formData.account_name : ''} />
                        <AvFeedback>Enter Your Account Name!</AvFeedback>
                      </AvGroup>
                      {/* <AvGroup>
              <Label>Assign To whom</Label>
              <AvInput name="assign_to_whom" id="example" placeholder="Assigned" required value={formData ? formData.assign_to_whom:''}/>
              <AvFeedback>Enter Assign To whom !</AvFeedback>
          </AvGroup>
          <AvGroup>
                    <Label for="example">Remark</Label>
                    <AvInput type="textarea" name="remark" id="example" required value={formData ? formData.remark:''}/>
                  <AvFeedback>Enter Remark..!</AvFeedback>
                  </AvGroup> */}
                      <FormGroup>
                        <Label>Upload Personnal ID Proof</Label>
                        <Input type="file" name="personal_doc" onChange={this.onChangeHandler} />
                        <FormText color="muted"></FormText>
                      </FormGroup>
                      <FormGroup>
                        <h4><b>Please Note *</b></h4><br />
                        <h5>1) Organisation must be a registered Identity in the country of issue.</h5>
                        <h5>2) Personal ID Document is need to verify system operator.</h5>
                        <h5>3) Subject to terms and conditions.</h5>
                      </FormGroup>
                      <div class="row">
                        <div class="col-sm-6">
                          <Button
                            style={{ margin: "0rem 1.50rem 0rem 0rem" }}
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={submitted}
                          >
                            {
                              (submitted && 'Your form is submitted!')
                              || (!submitted && 'Submit')
                            }

                          </Button>
                          <Button color="primary"  onClick={this.onFormCancel}>
                    Cancel
                  </Button>
                        </div>
                        <div class="col-sm-6">
                          <right><img src={image3} style={{ 'width': '55%' }} ></img><br />
                            <p><a style={{ 'width': '55%' }} href="https://www.epenh.com" target="_blank">Developed by Epenh Co.Ltd © 2019</a></p></right>
                        </div></div>

                    </AvForm>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>

        </Container>
      </div>

    )
  }
}
export default SignUp;
