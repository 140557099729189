import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  landing as landingRoutes,
  dashboard as dashboardRoutes,
  page as pageRoutes
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import Page405 from "../pages/auth/Page405";
import Page406 from "../pages/auth/Page406";
import SignIn from "../pages/auth/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";
import SignUp from "../pages/auth/SignUp";
import ScrollToTop from "../components/ScrollToTop";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={props => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        {childRoutes(LandingLayout, landingRoutes)}
        {childRoutes(DashboardLayout, dashboardRoutes)}
        {childRoutes(AuthLayout, pageRoutes)}
        <Route
          render={() => (
            <AuthLayout>



              {



                localStorage.getItem('uname') != null && localStorage.getItem('login_type') == 'hr_admin' && localStorage.getItem('joburlcomp') == null || localStorage.getItem('login_type') == 'sub_admin' && localStorage.getItem('joburlcomp') == null ? 
                <Page404 />


                  : localStorage.getItem('uname') != null && localStorage.getItem('login_type') == 'emp' && localStorage.getItem('joburlcomp') == null ? <Page405 />


                    : localStorage.getItem('uname') != null && localStorage.getItem('login_type') == 'security' && localStorage.getItem('joburlcomp') == null ? <Page406 />



                      :
                      // <SignUp /> || <SignIn />
                      localStorage.getItem('signup') == '1' ? <SignUp />
                        :
                        localStorage.getItem('forget') == '1' ? <ResetPassword />

                          :
                          <SignIn />

              }



            </AuthLayout>
          )}
        />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default Routes;
